import { JointCouplingAxisRange } from '../../../model/reportV2.model';

/**
 * Calculates the range of the x-axis for joint coupling data.
 * @param data_Left - The data for the left joint.
 * @param data_Right - The data for the right joint.
 * @returns An object containing the minimum value, maximum value, and tick amount for the x-axis.
 */
export function calculateXaxisJointCouplingRange(data_Left: any, data_Right: any): JointCouplingAxisRange {
  function minMax() {
    const minMax = [];
    const numCycles = Object.keys(data_Left).length;
    for (let i = 0; i < numCycles; i++) {
      const leftData = data_Left[i].data;
      const rightData = data_Right[i].data;

      // Extract x-values from both left and right data arrays
      const leftXValues = leftData.map((point: { x: number; y: number }) => point.x);
      const rightXValues = rightData.map((point: { x: number; y: number }) => point.x);

      // Calculate the minimum and maximum x-values
      const min = Math.min(...leftXValues, ...rightXValues);
      const max = Math.max(...leftXValues, ...rightXValues);
      minMax.push({ min, max });
    }
    return minMax;
  }

  const minMaxArray = minMax();

  const minValueObject = minMaxArray.reduce(function (prev: any, curr: any) {
    return prev.min < curr.min ? prev : curr;
  });

  const maxValueObject = minMaxArray.reduce(function (prev: any, curr: any) {
    return prev.max > curr.max ? prev : curr;
  });

  const xMinValue = Math.floor(minValueObject.min / 10) * 10 - 10;
  const xMaxValue = Math.floor(maxValueObject.max / 10) * 10 + 10;
  const xTickAmount = (xMaxValue - xMinValue) / 10;
  return { xMinValue, xMaxValue, xTickAmount };
}
