/** The Run Kinematics report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonCard,
  IonToggle,
  IonCardContent,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import '../components/Graphs/graphCard.css';
import { KinematicsGraph } from '../components/Graphs';
import { useMemo, useState } from 'react';
import { ORYXCardHeader, ORYXSmallCardHeader } from '../../../components/componentHeaders';
import { WalkKinematicData } from '../../../model/reportV2.model';

type KinematicsReportProps = RouteComponentProps & {
  tabData: WalkKinematicData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const WalkKinematicsReportTab = ({ tabData }: KinematicsReportProps) => {
  const [showAllStrides, setShowAllStrides] = useState<boolean>(false);
  const romData = tabData.romData;
  const tableData = tabData.tableData;

  const toggleShowAllStrides = () => {
    setShowAllStrides(!showAllStrides);
    localStorage.setItem('showAllStridesKinematics', !showAllStrides ? 'true' : 'false');
  };

  const seriesPelvis = useMemo(() => {
    return showAllStrides ? tabData.pelvisKinematicData.allStrides : tabData.pelvisKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesHip = useMemo(() => {
    return showAllStrides ? tabData.hipKinematicData.allStrides : tabData.hipKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesKnee = useMemo(() => {
    return showAllStrides ? tabData.kneeKinematicData.allStrides : tabData.kneeKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesAnkle = useMemo(() => {
    return showAllStrides ? tabData.ankleKinematicData.allStrides : tabData.ankleKinematicData.mean;
  }, [showAllStrides, tabData]);

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          <IonGrid fixed>
            {/* Pelvis */}
            <IonRow className=''>
              <IonCol size='12' className=''>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXCardHeader title={'Pelvis'} zoom={false} info={true}>
                    <IonRow className='ion-no-padding ion-justify-content-center'>
                      <IonText className='attractorCard_Title'>
                        <small>Show all Strides</small>
                      </IonText>
                    </IonRow>
                    <IonRow className='ion-no-padding ion-justify-content-center'>
                      <IonToggle
                        mode='ios'
                        className='icon-custom'
                        color='primary'
                        checked={showAllStrides}
                        onIonChange={(e) => {
                          toggleShowAllStrides();
                        }}
                      ></IonToggle>
                    </IonRow>
                  </ORYXCardHeader>
                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesPelvis.Sagittal.series}
                          title={'Sagittal'}
                          subtitle={'Anterior tilt (-) / Posterior tilt (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesPelvis.Sagittal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesPelvis.Coronal.series}
                          title={'Frontal'}
                          subtitle={'Drop (-) / Hike (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesPelvis.Coronal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesPelvis.Transversal.series}
                          title={'Transversal'}
                          subtitle={'Backward Rotation (-) / Forward Rotation (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesPelvis.Transversal.yAxis}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Hip */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXSmallCardHeader title={'Hip'} />

                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesHip.Sagittal.series}
                          title={'Sagittal'}
                          subtitle={'Extension (-) / Flexion (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesHip.Sagittal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesHip.Coronal.series}
                          title={'Frontal'}
                          subtitle={'Abduction (-) / Adduction (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesHip.Coronal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesHip.Transversal.series}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesHip.Transversal.yAxis}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Knee */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXSmallCardHeader title={'Knee'} />
                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesKnee.Sagittal.series}
                          title={'Sagittal'}
                          subtitle={'Extension (-) / Flexion (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesKnee.Sagittal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesKnee.Coronal.series}
                          title={'Frontal'}
                          subtitle={'Valgus (-) / Varus (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesKnee.Coronal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesKnee.Transversal.series}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesKnee.Transversal.yAxis}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Ankle */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXSmallCardHeader title={'Ankle'} />

                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesAnkle.Sagittal.series}
                          title={'Sagittal'}
                          subtitle={'Plantarflexion (-) / Dorsalflexion (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesAnkle.Sagittal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesAnkle.Coronal.series}
                          title={'Frontal'}
                          subtitle={'Eversion (-) / Inversion (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesAnkle.Coronal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesAnkle.Transversal.series}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesAnkle.Transversal.yAxis}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Tables */}
            <IonRow class='ion-no-padding'>
              <IonCol size='6'>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXSmallCardHeader title={'Range of motion'} />

                  <IonCardContent>
                    <IonRow className='ion-padding-vertical full_height ion-align-items-start'>
                      <IonCol size='12' className='ion-no-padding'>
                        <table className='ORYX_Table_noMargin'>
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>Left</th>
                              <th>Right</th>
                              <th>Symmetry</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className='ORYX_Table_Head'>
                              <td> Pelvis</td>
                              <td className={tabData.tableClassNames.Pelvis.Left}>{romData[0][0].toFixed(1) + '°'}</td>
                              <td className={tabData.tableClassNames.Pelvis.Right}>{romData[0][1].toFixed(1) + '°'}</td>
                              <td className={tabData.tableClassNames.Pelvis.Symmetry}>
                                {romData[0][2].toFixed(0) + '%'}
                              </td>
                            </tr>
                            <tr className='ORYX_Table_Head'>
                              <td> Hip</td>
                              <td className={tabData.tableClassNames.Hip.Left}>{romData[1][0].toFixed(1) + '°'}</td>
                              <td className={tabData.tableClassNames.Hip.Right}>{romData[1][1].toFixed(1) + '°'}</td>
                              <td className={tabData.tableClassNames.Hip.Symmetry}>{romData[1][2].toFixed(0) + '%'}</td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Flexion</td>
                              <td>{romData[2][0].toFixed(1) + '°'}</td>
                              <td>{romData[2][1].toFixed(1) + '°'}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Extension</td>
                              <td className={tabData.tableClassNames.Hip.ExtensionLeft}>
                                {romData[3][0].toFixed(1) + '°'}
                              </td>
                              <td className={tabData.tableClassNames.Hip.ExtensionRight}>
                                {romData[3][1].toFixed(1) + '°'}
                              </td>
                              <td></td>
                            </tr>
                            <tr className='ORYX_Table_Head'>
                              <td>Knee</td>
                              <td className={tabData.tableClassNames.Knee.Left}>{romData[4][0].toFixed(1) + '°'}</td>
                              <td className={tabData.tableClassNames.Knee.Right}>{romData[4][1].toFixed(1) + '°'}</td>
                              <td className={tabData.tableClassNames.Knee.Symmetry}>
                                {romData[4][2].toFixed(0) + '%'}
                              </td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'> Stance flexion</td>
                              <td className={tabData.tableClassNames.Knee.stanceFlexionLeft}>
                                {romData[5][0].toFixed(1) + '°'}
                              </td>
                              <td className={tabData.tableClassNames.Knee.stanceFlexionRight}>
                                {romData[5][1].toFixed(1) + '°'}
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Varus Valgus</td>
                              <td className={tabData.tableClassNames.Knee.varusValgusLeft}>
                                {romData[6][0].toFixed(1) + '°'}
                              </td>
                              <td className={tabData.tableClassNames.Knee.varusValgusRight}>
                                {romData[6][1].toFixed(1) + '°'}
                              </td>
                              <td></td>
                            </tr>
                            <tr className='ORYX_Table_Head'>
                              <td>Ankle</td>
                              <td className={tabData.tableClassNames.Ankle.Left}>{romData[7][0].toFixed(1) + '°'}</td>
                              <td className={tabData.tableClassNames.Ankle.Right}>{romData[7][1].toFixed(1) + '°'}</td>
                              <td className={tabData.tableClassNames.Ankle.Symmetry}>
                                {romData[7][2].toFixed(0) + '%'}
                              </td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Dorsal flexion</td>
                              <td>{romData[8][0].toFixed(1) + '°'}</td>
                              <td>{romData[8][1].toFixed(1) + '°'}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Plantar flexion</td>
                              <td>{romData[9][0].toFixed(1) + '°'}</td>
                              <td>{romData[9][1].toFixed(1) + '°'}</td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>

              <IonCol size='6'>
                <IonCard className='' style={{ height: '88%', margin: 0 }}>
                  <ORYXSmallCardHeader title={'Spatio temporal information'} />

                  <IonCardContent>
                    <IonRow className='ion-padding-vertical full_height ion-align-items-start'>
                      <IonCol size='12' className='ion-no-padding'>
                        <table className='ORYX_Table_noMargin'>
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>Left</th>
                              <th>Right</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className='ORYX_Table_Head'>
                              <td>Stance phase (Percentage of stride time)</td>
                              <td>{tableData[3][1].toFixed(0) + ' %'}</td>
                              <td>{tableData[4][1].toFixed(0) + ' %'}</td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Stance phase (seconds)</td>
                              <td>{tableData[0][1].toFixed(2) + ' s'}</td>
                              <td>{tableData[1][1].toFixed(2) + ' s'}</td>
                            </tr>
                            <tr className='ORYX_Table_Head'>
                              <td>Swing phase (Percentage of stride time)</td>
                              <td>{tableData[3][2].toFixed(0) + ' %'}</td>
                              <td>{tableData[4][2].toFixed(0) + ' %'}</td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Swing phase (seconds)</td>
                              <td>{tableData[0][2].toFixed(2) + ' s'}</td>
                              <td>{tableData[1][2].toFixed(2) + ' s'}</td>
                            </tr>
                          </tbody>
                        </table>
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
          {/* )} */}
        </IonContent>
      </IonPage>
    );
  }
};
