/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * ORYX Test page.
 */

import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonInput,
  IonItem,
  IonIcon,
  IonButton,
  IonProgressBar,
  IonTitle,
  IonText,
  IonModal,
  IonContent,
  IonHeader,
  IonListHeader,
  IonAlert,
  IonToast,
  IonSpinner,
  useIonAlert,
  IonicSafeString,
} from '@ionic/react';
import { arrowBack, person, pricetagOutline, trashBinOutline } from 'ionicons/icons';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Preferences } from '@capacitor/preferences';
import {
  SocketContext,
  OrientationData,
  OrientationMap,
  SensorObject,
  AssignObject as AssignedSensorObject,
  PageDataObject,
  RecordingCsvFile,
  RecordingStoppedMessage,
} from '../Context/socket';
import { CalibrationAvatar } from './CalibrationAvatar';
import './gatewayStates.css';
import './GatewayCard.css';
import { ScannedSensor, Sensor } from './Sensor';
import { useAuthContext } from '../../../../components/authContext';
import { useFirebaseUpload } from '../../../../hooks/useFirebaseUpload';

// Countdowntimer
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ErrorToast } from '../../../../components/ErrorToast';
import { getCache, updateArrayCache } from '../../../../utilities/CacheController';
import { SavedRecordingRecord } from './GatewayCard';
import { ORYXModalHeader } from '../../../../components/componentHeaders';
import { connect } from 'http2';

const degrees = 180.0 / Math.PI;

// Helper function, temporarily here, should probably be in it's own file.
function getRotation(q: { w: number; x: number; y: number; z: number }): number {
  return -Math.atan2(2.0 * (q.x * q.y + q.z * q.w), q.x * q.x - q.y * q.y - q.z * q.z + q.w * q.w) * degrees;
}

/**
 * Returns a time label in format HH:MM:SS, ex. 05:36:05, calculated from secondsPassed
 *
 * @param timeInSeconds
 * @returns
 */
function secondsToTimelabel(timeInSeconds: number): string {
  const hours = Math.floor(timeInSeconds / 3600)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((timeInSeconds - parseInt(hours) * 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (timeInSeconds % 60).toString().padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
}

export const StartContent = () => {
  const socket = useContext(SocketContext);
  const sessionNameInput = useRef<string>('');

  useEffect(() => {
    Preferences.remove({ key: 'lastRecordingFileName' });
    Preferences.remove({ key: 'postRecordingChoice' });
  }, []);

  return (
    <IonGrid>
      <IonRow className='content_Row'>
        <IonCol className='sensorColumn_Left' size='2'></IonCol>
        <IonCol className='mainColumn ion-align-self-center' size='8'>
          <IonRow className='ion-justify-content-center ion-margin mainColumn_Row'>
            <IonList className='gatewayCard-content-List'>
              <IonItem className='ion-no-margin gatewayCard-content-listItem' lines='none'>
                <IonIcon icon={person} slot='start' />
                <IonInput
                  placeholder='Session Name'
                  clearInput={true}
                  autocomplete='name'
                  type='text'
                  onIonChange={(e) => {
                    if (e.detail.value) {
                      sessionNameInput.current = e.detail.value;
                    }
                  }}
                ></IonInput>
              </IonItem>
            </IonList>
          </IonRow>
          <IonRow className='ion-justify-content-center ion-margin mainColumn_Row'>
            <IonButton
              className='primary'
              onClick={() => {
                socket?.emit('guiEvent', 'startSession', { sessionName: sessionNameInput.current });
              }}
            >
              Scan &amp; Connect Sensors
            </IonButton>
          </IonRow>
        </IonCol>
        <IonCol className='sensorColumn_Right' size='2'></IonCol>
      </IonRow>
    </IonGrid>
  );
};

interface ScanningContentProps {
  pageData: PageDataObject;
}

export const ScanningAndConnectingContent = ({ pageData }: ScanningContentProps) => {
  const socket = useContext(SocketContext);
  const [sensorMap, setSensorMap] = useState<OrientationMap>({});
  const [sensorsEnabled, setSensorsEnabled] = useState<boolean>(false);
  const [allSensorsConnected, setAllSensorsConnected] = useState<boolean>(false);
  const [headingResetDone, setHeadingResetDone] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const [present] = useIonAlert();
  const [dataCheck, setDataCheck] = useState(false);
  const discoveredSensorsCount = useRef<number>(0);
  const dataPointCount = useRef<number>(0);
  const [disfunctionSensorsDetected, setDisfunctionSensorsDetected] = useState(false);
  const [tempConnectedSensorsList, setTempConnectedSensorsList] = useState<SensorObject[]>([]);
  const [sessionStopped, setSessionStopped] = useState(false);

  // The reason we have to use some sort of react ref is because state won't work within useEffect because the socket handlers are pre-defined.
  const _connOrientationMap = useRef<OrientationMap>({});

  useEffect(() => {
    if (pageData.connectedSensors && pageData.discoveredSensors) {
      if (pageData.discoveredSensors.length === 0) {
        setSensorsEnabled(true);
        setAllSensorsConnected(true);
      }
    }
  }, []);
  const disfunctioningSensors: string[] = [];

  useEffect(() => {
    if (!dataCheck) {
      return;
    }

    if (dataCheck) {
      Object.entries(sensorMap).map(([key, value]) => {
        if (value.orientation.x == 0) {
          setDisableButtons(true);
          disfunctioningSensors.push(value.mappedSegment);
          setDisfunctionSensorsDetected(true);
        } else if (value.dataRate < 60) {
          setDisableButtons(true);
          disfunctioningSensors.push(value.mappedSegment);
          setDisfunctionSensorsDetected(true);
        }
      });

      if (disfunctioningSensors.length > 0) {
        const capitalizedSensors = disfunctioningSensors.map((sensor) => {
          return sensor.replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase());
        });
        const formattedList = capitalizedSensors.join(', ');

        present({
          header: 'One or more sensors are not functioning correct',
          subHeader: 'Please reset the following sensors:',
          message: `${formattedList}.`,
          cssClass: 'destructiveAlert',
          backdropDismiss: false,
          buttons: [
            {
              text: 'Ok',
              handler: () => {
                socket?.emit('guiEvent', 'stopSession', {});
              },
            },
          ],
        });
      }
    }
  }, [dataCheck]);

  useEffect(() => {
    if (import.meta.env.MODE) console.log('disfunctioning sensors: ', disfunctioningSensors);
  }, [disfunctioningSensors.length]);

  useEffect(() => {
    socket?.on('guiEvent', (eventName, parameters) => {
      switch (eventName) {
        case 'orientations': {
          dataPointCount.current++;

          if (dataPointCount.current == 20) {
            setDataCheck(true);
          }
          // if (import.meta.env.MODE !== 'production') console.log('orientations');
          // if (import.meta.env.MODE !== 'production') console.log(parameters as OrientationData);
          setSensorMap((parameters as OrientationData).sensorList);
          break;
        }

        case 'sensorDiscovered': {
          if (discoveredSensorsCount.current > pageData.numberOfSensorsAllowed) return;

          const discoveredSensor = parameters as SensorObject;

          // found here:
          // using previous state
          // https://stackoverflow.com/a/43639228
          setSensorMap((prevState) => ({
            ...prevState,
            [discoveredSensor.address]: {
              mappedSegment: discoveredSensor.mappedSegment,
              orientation: { w: 0, x: 0, y: 0, z: 0 },
              batteryLevel: 0,
              charging: false,
              dataRate: 0,
              connected: false,
            },
          }));

          discoveredSensorsCount.current += 1;

          if (discoveredSensorsCount.current == pageData.numberOfSensorsAllowed) {
            if (import.meta.env.MODE !== 'production') console.log(discoveredSensorsCount.current);
            socket?.emit('guiEvent', 'connectSensors', null);
          }

          break;
        }

        case 'sensorConnected': {
          if (import.meta.env.MODE !== 'production') console.log('Sensor Connected', parameters);
          const connectedSensor = parameters as SensorObject;

          // Using previous state, and append a newly created key value pair
          // with the sensor' mac address as key. The object is constructed with default values
          // except for the mapped segment wich is given by the gateway 'sensorConnected' guiEvent.
          setSensorMap((prevState) => ({
            ...prevState,
            [connectedSensor.address]: {
              mappedSegment: connectedSensor.mappedSegment,
              orientation: { w: 0, x: 0, y: 0, z: 0 },
              batteryLevel: 0,
              charging: false,
              dataRate: 0,
              connected: true,
            },
          }));
          tempConnectedSensorsList.push(connectedSensor);
          break;
        }

        case 'allSensorsConnected':
          if (import.meta.env.MODE !== 'production') console.log('All Sensors Connected');
          setAllSensorsConnected(true);
          socket.emit('guiEvent', 'getOrientations', null);
          setSensorsEnabled(true);
          break;

        case 'sensorListCleared':
          if (import.meta.env.MODE !== 'production') console.log('clearing sensor list');
          discoveredSensorsCount.current = 0;
          setSensorMap({});

          break;

        case 'scanningStarted':
          if (import.meta.env.MODE !== 'production') console.log('scanning started');
          discoveredSensorsCount.current = 0;
          setSensorMap({});
          break;

        case 'orientationsReset':
          setHeadingResetDone(true);
          break;

        case 'sensorDisconnected': {
          // from the parameter.address find the corresponding mappedSegment from the tempConnectedSensorsList
          // and remove it from the list
          const disconnectedSensor = parameters as SensorObject;
          const mappedSegment = tempConnectedSensorsList
            .find((sensor) => sensor.address === disconnectedSensor.address)
            ?.mappedSegment.replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase());

          const sensor: any = {
            mappedSegment: mappedSegment,
            address: disconnectedSensor.address,
          };
          if (sessionStopped) {
            present({
              header: 'Sensor disconnected',
              subHeader: 'Please reconnect the sensor',
              message: `${sensor.mappedSegment} has disconnected.`,
              cssClass: 'destructiveAlert',
              backdropDismiss: false,
              buttons: [
                {
                  text: 'Ok',
                  handler: () => {
                    socket?.emit('guiEvent', 'stopSession', {});
                  },
                },
              ],
            });
          }
          break;
        }

        default:
          console.warn(
            `Received unexpected event: ${eventName} in component 'ScanningContent' with parameters: `,
            parameters,
          );
          break;
      }
    });

    return () => {
      if (import.meta.env.MODE !== 'production') console.log('cleaned up');
      socket?.removeListener('guiEvent');
      discoveredSensorsCount.current = 0;
    };
  }, []);

  return (
    <>
      {/* <IonModal isOpen={disfunctionSensorsDetected}>
        <ORYXModalHeader title='One or more sensors are not functioning correctly' buttons={false} />
        hi! I have the following sensors that are not working properly:
        {disfunctioningSensors.length}
      </IonModal> */}

      {!allSensorsConnected && <IonProgressBar type='indeterminate' />}
      <IonGrid className='content_Row'>
        <IonRow className='scannedSensor_Row ion-align-items-center ion-justify-content-center'>
          {Object.entries(sensorMap).map(([_, v]) => {
            if (v.mappedSegment.includes('left')) {
              if (v.connected === false) {
                return (
                  <ScannedSensor
                    mappedSegment={v.mappedSegment === 'left pelvis' ? 'left thigh' : 'left calf'}
                    key={v.mappedSegment}
                  />
                );
              }
              return (
                <Sensor
                  mappedSegment={v.mappedSegment === 'left pelvis' ? 'left thigh' : 'left calf'}
                  sensorOrientation={getRotation(v.orientation)}
                  batteryLevel={v.batteryLevel}
                  charging={v.charging}
                  key={v.mappedSegment}
                  dataRate={v.dataRate}
                  enabled={sensorsEnabled}
                />
              );
            }
          })}
        </IonRow>
        <IonRow className='scannedSensor_Row ion-align-items-center  ion-justify-content-center'>
          {Object.entries(sensorMap).map(([_, v]) => {
            if (v.mappedSegment.includes('right')) {
              if (v.connected === false) {
                return (
                  <ScannedSensor
                    mappedSegment={v.mappedSegment === 'right pelvis' ? 'right thigh' : 'right calf'}
                    key={v.mappedSegment}
                  />
                );
              }
              return (
                <Sensor
                  mappedSegment={v.mappedSegment === 'right pelvis' ? 'right thigh' : 'right calf'}
                  sensorOrientation={getRotation(v.orientation)}
                  batteryLevel={v.batteryLevel}
                  charging={v.charging}
                  key={v.mappedSegment}
                  dataRate={v.dataRate}
                  enabled={sensorsEnabled}
                />
              );
            }
          })}
        </IonRow>
        <IonRow className='last_row ion-justify-content-between ion-align-items-end'>
          <IonButton
            mode='md'
            fill='solid'
            shape='round'
            color='light'
            onClick={() => {
              setSessionStopped(true);

              socket?.emit('guiEvent', 'stopSession', {});
            }}
          >
            <IonIcon icon={arrowBack} slot='icon-only' />
          </IonButton>
          {allSensorsConnected && (
            <>
              <IonButton
                mode='md'
                className='primary'
                disabled={disableButtons}
                onClick={() => {
                  socket?.emit('guiEvent', 'resetOrientation', null);
                }}
              >
                Heading Reset
              </IonButton>
              <IonButton
                mode='md'
                id='calibrationButton'
                className='primary'
                onClick={() => {
                  socket?.emit('guiEvent', 'stopConnectingSensors', null);
                }}
                disabled={!headingResetDone || disableButtons}
              >
                Calibration
              </IonButton>
            </>
          )}
        </IonRow>
      </IonGrid>
    </>
  );
};

interface CalibrationContentProps {
  assignedSensors: AssignedSensorObject[];
  pageData: PageDataObject;
}

export const CalibrationContent = ({ assignedSensors, pageData }: CalibrationContentProps) => {
  const PROGRESS_UPDATE_MS = 100;
  const CALIBRATION_DURATION = 5100; // Little over the actual calibration duration because to account for transmission delays etc.
  const socket = useContext(SocketContext);
  const [progressBarValue, setProgressBarValue] = useState<number>(0.0);
  const [isCalibrating, setIsCalibrating] = useState<boolean>(false);
  const [pendingCalibrationResult, setPendingCalibrationResult] = useState(false);
  const [canProceeed, setCanProceed] = useState<boolean>(false);
  const [fillColor, setFillColor] = useState<string>('#2066fa'); // Default blue
  const progressValue = useRef<number>(0);
  const [present] = useIonAlert();

  const progressInterval = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  useEffect(() => {
    // Allow user to proceed if they have already calibrated and have returned from the session/recording state.
    if (pageData.calibrationInfo) {
      if (pageData.calibrationInfo.state) {
        setCanProceed(true);
        setFillColor('#2DC798');
      }
    }

    socket?.on('guiEvent', (eventName, parameters) => {
      switch (eventName) {
        case 'calibrationStarted':
          setCanProceed(false);
          setIsCalibrating(true);
          setFillColor('#2066fa'); // Back to default blue
          progressValue.current = 0;
          progressInterval.current = setInterval(() => {
            progressValue.current += PROGRESS_UPDATE_MS;
            setProgressBarValue(progressValue.current / CALIBRATION_DURATION);

            if (progressValue.current >= CALIBRATION_DURATION) {
              if (progressInterval.current) {
                clearInterval(progressInterval.current);
                setPendingCalibrationResult(true);
              }
            }
          }, PROGRESS_UPDATE_MS);
          break;

        case 'calibrationFinished':
          // Manual override to clear interval and set progress bar to full when the client is lacking behind
          if (progressInterval.current) {
            clearInterval(progressInterval.current);
          }

          setProgressBarValue(1);
          setFillColor('#22C55E'); // Success green

          setCanProceed(true);
          setIsCalibrating(false);
          setPendingCalibrationResult(false);

          break;

        case 'sensorDisconnected': {
          // from the parameter.address find the corresponding mappedSegment from the tempConnectedSensorsList
          // and remove it from the list
          const disconnectedSensor = parameters as SensorObject;
          const mappedSegment = assignedSensors
            .find((sensor) => sensor.address === disconnectedSensor.address)
            ?.segment.replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase());

          const sensor: any = {
            mappedSegment: mappedSegment,
            address: disconnectedSensor.address,
          };

          present({
            header: 'Sensor disconnected',
            subHeader: 'Please reconnect the sensor',
            message: `${sensor.mappedSegment} has disconnected.`,
            cssClass: 'destructiveAlert',
            backdropDismiss: false,
            buttons: [
              {
                text: 'Ok',
                handler: () => {
                  socket?.emit('guiEvent', 'stopSession', {});
                },
              },
            ],
          });
          break;
        }

        default:
          console.warn(
            `Received unexpected event: ${eventName} in component 'CalibrationContent' with paramters: `,
            parameters,
          );
          break;
      }
    });

    return () => {
      if (import.meta.env.MODE !== 'production') console.log('cleaned up');
      socket?.removeListener('guiEvent');
    };
  }, []);

  return (
    <>
      <IonGrid className='content_Row'>
        <IonRow className='calibration_Row ion-align-items-center'>
          <IonCol size='4' className='left'>
            <CalibrationAvatar fillMap={assignedSensors} fillColor={fillColor} />
          </IonCol>

          <IonCol size='8' className='right'>
            <IonRow className='three_Rows ion-align-items-center'>
              <IonTitle className='state_Title ion-text-center'>Calibration</IonTitle>
              <IonText>
                Calibration will take a few seconds to complete. After completion you can continue or start the
                calibration again if something went wrong.
              </IonText>
            </IonRow>

            <IonRow className='three_Rows ion-align-items-center'>
              <IonProgressBar className='oryx_progressbar' value={progressBarValue}></IonProgressBar>
            </IonRow>

            <IonRow>
              {pendingCalibrationResult && (
                <IonCol size={'12'}>
                  <IonSpinner name='crescent' color={'primary'}></IonSpinner>
                </IonCol>
              )}
            </IonRow>

            <IonRow className='three_Rows ion-justify-content-center ion-align-items-center'>
              <IonCol size='6'>
                <IonButton
                  mode='md'
                  className='primary_medium'
                  disabled={isCalibrating}
                  onClick={() => {
                    socket?.emit('guiEvent', 'calibrate', null);
                  }}
                >
                  Start Calibration
                </IonButton>
              </IonCol>
              <IonCol size='6'>
                <IonButton
                  mode='md'
                  className='primary_medium'
                  disabled={!canProceeed}
                  onClick={() => {
                    socket?.emit('stateChange', { state: 'session' });
                  }}
                >
                  Continue
                </IonButton>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        <IonRow className='ion-justify-content-between last_row'>
          <IonButton
            mode='md'
            fill='solid'
            shape='round'
            color='light'
            onClick={() => {
              socket?.emit('guiEvent', 'stopSession', {});
            }}
          >
            <IonIcon icon={arrowBack} slot='icon-only' />
          </IonButton>
        </IonRow>
      </IonGrid>
    </>
  );
};

interface RecordingContentProps {
  assignedSensors: AssignedSensorObject[];
  pageData: PageDataObject;
}

// Type definiton for the specific state component
type RecordingChoice = 'delete' | 'save' | 'undecided' | 'not_pending';

// Used functions for this state:

export const RecordingContent = ({ assignedSensors, pageData }: RecordingContentProps) => {
  const socket = useContext(SocketContext);
  const [present] = useIonAlert();
  const [fillColor, _setFillColor] = useState<string>('#22C55E'); // Default green on recording page.
  const [showPostRecordingModal, setShowPostRecordingModal] = useState(false);
  const [showPostRecordingChoiceAlert, setShowPostRecordingChoiceAlert] = useState(false);
  const [postRecordingChoice, setPostRecordingChoice] = useState<RecordingChoice>('not_pending');
  const [countdownValue, setCountdownValue] = useState<number>(5);
  const [countDownText, setCountdownText] = useState<string>('5');
  const [recordingState, setRecordingState] = useState<'Idle' | 'Preparing' | 'Recording'>('Idle');
  const [recordingStopwatch, setRecordingStopwatch] = useState<string>('00:00:00');

  // The following variables and states are used in the download file process from the gateway over sockets
  const recordingCsvFile = useRef<RecordingCsvFile>({
    name: 'UNDEFINED',
    csv: '',
    data: new Uint8Array(0),
    received: 0,
  });

  // All used for post-recording modal
  const recordingTags = useRef<string>('');
  const [lastRecordingName, setLastRecordingName] = useState<string>('');
  const [lastRecordingTime, setLastRecordingTime] = useState<string>('');

  useEffect(() => {
    Preferences.get({ key: 'postRecordingChoice' }).then((localPreferences) => {
      if (localPreferences.value) {
        if (localPreferences.value != 'not_pending') {
          Preferences.get({ key: 'lastRecordingFileName' }).then((localPreferences) => {
            if (localPreferences.value) {
              setLastRecordingName(localPreferences.value);
            }
          });
          Preferences.get({ key: 'lastRecordingTime' }).then((localPreferences) => {
            if (localPreferences.value) {
              setLastRecordingTime(localPreferences.value);
            }
          });
          setShowPostRecordingModal(true);
          setPostRecordingChoice('undecided');
        }
      }
    });
  }, []);

  useEffect(() => {
    if (pageData.recordingInfo) {
      switch (pageData.recordingInfo.state) {
        case 'Idle':
          break;

        case 'Preparing':
          break;

        case 'Recording':
          setCountdownText('GO');
          setRecordingState(pageData.recordingInfo.state);
          setRecordingStopwatch(secondsToTimelabel(pageData.recordingInfo.time));
          break;
      }
      setRecordingState(pageData.recordingInfo.state);
    }

    socket?.on('guiEvent', (eventName, parameters) => {
      switch (eventName) {
        case 'recordingTimerTick':
          setRecordingStopwatch(secondsToTimelabel((parameters as { seconds: number }).seconds));

          break;

        case 'preparingRecording':
          setRecordingState('Preparing');
          break;

        case 'recordingStarted':
          setRecordingState('Recording');
          setCountdownText('GO');
          setCountdownValue(5); // Reset to maximum value
          break;

        case 'recordingStopped':
          Preferences.set({
            key: 'postRecordingChoice',
            value: 'undecided',
          });
          Preferences.set({
            key: 'lastRecordingFileName',
            value: (parameters as RecordingStoppedMessage).filename,
          });
          Preferences.set({
            key: 'lastRecordingTime',
            value: secondsToTimelabel((parameters as RecordingStoppedMessage).recordingTime),
          });

          setLastRecordingName((parameters as RecordingStoppedMessage).filename);
          setLastRecordingTime(secondsToTimelabel((parameters as RecordingStoppedMessage).recordingTime));
          setPostRecordingChoice('undecided');
          setRecordingState('Idle');
          setCountdownText('5');
          setRecordingStopwatch('00:00:00');
          setShowPostRecordingModal(true);
          break;

        case 'recordingAborted':
          present({
            header: 'Recording aborted',
            message: 'One or more sensors disconnected!',
            buttons: [
              {
                text: 'Ok',
              },
            ],
          });
          setRecordingState('Idle');
          setCountdownText('5');
          setRecordingStopwatch('00:00:00');
          break;

        default:
          console.warn(
            `Received unexpected event: ${eventName} in component 'RecordingContent' with parameters: `,
            parameters,
          );
          break;
      }
    });

    return () => {
      if (import.meta.env.MODE !== 'production') console.log('cleaned up');
      socket?.removeListener('guiEvent');
      // socket?.removeListener('downloadStart');
      // socket?.removeListener('downloadDataBlock');
      // socket?.removeListener('downloadReady');
    };
  }, []);

  return (
    <>
      {/* Alert that shows when users presses save and continue after the recording */}
      <IonAlert
        isOpen={showPostRecordingChoiceAlert}
        onDidDismiss={() => {
          setShowPostRecordingChoiceAlert(false);
          setPostRecordingChoice('undecided');
        }}
        cssClass='my-custom-class'
        header={'Continue or end session?'}
        message={'Do you want to continue in this session or end this session?'}
        buttons={[
          {
            text: 'End Session',
            cssClass: 'secondary',
            handler: () => {
              setShowPostRecordingModal(false);
              setPostRecordingChoice('not_pending');

              Preferences.get({ key: 'lastRecordingFileName' })
                .then((filename) => {
                  if (filename.value) {
                    switch (postRecordingChoice) {
                      case 'delete':
                        socket?.emit('discardLastRecording', filename.value);
                        break;

                      case 'save':
                        updateArrayCache<SavedRecordingRecord>(
                          { filename: filename.value, tags: recordingTags.current },
                          'saved_recordings',
                        );
                        break;

                      default:
                        break;
                    }
                  }
                })
                .finally(() => {
                  setShowPostRecordingModal(false);
                  setPostRecordingChoice('not_pending');

                  // I believe this can be substituted for Preferences.clear();
                  Preferences.remove({ key: 'lastRecordingFileName' });
                  Preferences.remove({ key: 'lastRecordingTime' });
                  Preferences.remove({ key: 'postRecordingChoice' });

                  // This needs to be moved to donwload/delete done handler and set a state variable
                  // something that the cloud returns to us preferably. I believe the firebase useFirebase funciton already has exposed this for us.
                  // to quitAfter = true or quitAfter = false, or something similar.
                  setTimeout(() => {
                    socket?.emit('guiEvent', 'stopSession', {});
                    if (import.meta.env.MODE !== 'production') console.log('Confirm Cancel');
                  }, 1000);
                });
            },
          },
          {
            text: 'Continue',
            handler: () => {
              Preferences.get({ key: 'lastRecordingFileName' })
                .then((filename) => {
                  if (filename.value) {
                    switch (postRecordingChoice) {
                      case 'delete':
                        socket?.emit('discardLastRecording', filename.value);
                        break;

                      case 'save':
                        updateArrayCache<SavedRecordingRecord>(
                          { filename: filename.value, tags: recordingTags.current },
                          'saved_recordings',
                        );
                        break;

                      default:
                        break;
                    }
                  }
                })
                .finally(() => {
                  setShowPostRecordingModal(false);
                  setPostRecordingChoice('not_pending');

                  Preferences.remove({ key: 'lastRecordingFileName' });
                  Preferences.remove({ key: 'lastRecordingTime' });
                  Preferences.remove({ key: 'postRecordingChoice' });
                });
            },
          },
          {
            text: 'Cancel',
            cssClass: 'secondary',
            handler: () => {
              setPostRecordingChoice('undecided');
            },
          },
        ]}
      />

      {/* Ready recording modal to show information after rercording */}
      <IonModal
        isOpen={showPostRecordingModal}
        className='gatewayCard-modal'
        onDidDismiss={() => setShowPostRecordingModal(false)}
        backdropDismiss={false}
      >
        <ORYXModalHeader title='Recording information' buttons={false} />
        <IonContent className='gatewayCard-modal-content' scrollEvents={false} scrollY={false}>
          <IonGrid>
            <IonList lines='none' mode='ios'>
              <IonListHeader mode='ios'>Document name: </IonListHeader>
              <IonItem className='gatewayCard-modal-listItem'>{lastRecordingName}</IonItem>
              <IonListHeader mode='ios'>Recording time: </IonListHeader>
              <IonItem className='gatewayCard-modal-listItem'>{lastRecordingTime}</IonItem>
              <IonListHeader mode='ios'>Tags</IonListHeader>
              <IonItem className='gatewayCard-modal-listItem'>
                <IonIcon icon={pricetagOutline} slot='start' />
                <IonInput
                  type='text'
                  onIonChange={(e) => {
                    if (e.detail.value) {
                      recordingTags.current = e.detail.value;
                    }
                  }}
                  placeholder='Seperate multiple tags by a comma'
                ></IonInput>
              </IonItem>
            </IonList>
          </IonGrid>
        </IonContent>
        <IonRow>
          <IonCol className='content_center_col ion-justify-content-center'>
            {/* This button triggers an alert to ask for continue or end session, but should also delete the recording appropriately */}
            <IonButton
              mode='md'
              onClick={() => {
                setShowPostRecordingChoiceAlert(true);
                setPostRecordingChoice('delete');
              }}
              className='primary'
            >
              Delete
              <IonIcon icon={trashBinOutline} slot='start' />
            </IonButton>
          </IonCol>
          <IonCol className='content_center_col ion-justify-content-center'>
            {/* This button triggers an alert to ask for continue or end session, but should also save the recording appropriately */}
            <IonButton
              mode='md'
              onClick={() => {
                setShowPostRecordingChoiceAlert(true);
                setPostRecordingChoice('save');
              }}
              className='primary'
            >
              Save and close
            </IonButton>
          </IonCol>
        </IonRow>
      </IonModal>

      <IonGrid className='content_Row'>
        <IonRow className='recording_Row ion-align-items-center'>
          <IonCol size='4' className='left'>
            <CalibrationAvatar fillMap={assignedSensors} fillColor={fillColor} />
          </IonCol>
          <IonCol size='8' className='right'>
            <IonRow className='title_Row'>
              <IonTitle className='state_Title ion-text-center'>Recording</IonTitle>
            </IonRow>
            <IonRow>
              <IonCol size='6' className='content_center_col ion-justify-content-center'>
                <IonButton
                  mode='md'
                  className='primary_medium'
                  onClick={() => {
                    socket?.emit('guiEvent', 'startRecording', { recordingName: 'Walk' });

                    let countDownProgress = 5;
                    const timer = setInterval(() => {
                      countDownProgress--;
                      setCountdownText(countDownProgress.toString());
                      setCountdownValue(countDownProgress);

                      if (countDownProgress === 0) {
                        clearInterval(timer);
                      }
                    }, 1000);
                  }}
                  disabled={recordingState == 'Idle' ? false : true}
                >
                  Walk
                </IonButton>
              </IonCol>
              <IonCol size='6' className='content_center_col ion-justify-content-center'>
                <IonButton
                  mode='md'
                  disabled={recordingState == 'Idle' ? false : true}
                  className='primary_medium'
                  onClick={() => {
                    socket?.emit('guiEvent', 'startRecording', { recordingName: 'Run' });

                    setRecordingState('Preparing');

                    let countDownProgress = 5;
                    const timer = setInterval(() => {
                      countDownProgress--;
                      setCountdownText(countDownProgress.toString());
                      setCountdownValue(countDownProgress);

                      if (countDownProgress === 0) {
                        clearInterval(timer);
                      }
                    }, 1000);
                  }}
                >
                  Run
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='6' className='content_center_col ion-justify-content-center'>
                <IonButton mode='md' disabled={recordingState == 'Idle' ? false : true} className='primary_medium'>
                  Squat
                </IonButton>
              </IonCol>
              <IonCol size='6' className='content_center_col ion-justify-content-center'>
                <IonButton mode='md' disabled={recordingState == 'Idle' ? false : true} className='primary_medium'>
                  Single Leg Squat
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow className='three_Rows ion-justify-content-around ion-align-items-center'>
              <IonButton
                mode='md'
                className='primary'
                onClick={() => {
                  socket?.emit('guiEvent', 'stopRecording', null);
                }}
                disabled={recordingState != 'Recording' ? true : false}
              >
                Stop
              </IonButton>
            </IonRow>
            {recordingState == 'Preparing' || recordingState == 'Recording' ? (
              <IonRow className='three_Rows ion-justify-content-around ion-align-items-center'>
                <IonCol className='content_center_col ion-justify-content-center'>
                  <div style={{ width: 100, height: 100 }}>
                    <CircularProgressbar
                      maxValue={5}
                      strokeWidth={10}
                      value={countdownValue}
                      text={countDownText}
                      styles={buildStyles({
                        textColor: '#2066FA',
                        pathColor: '#2066FA',
                        trailColor: '#E2E2E2',
                      })}
                    />
                  </div>
                </IonCol>
                <IonCol className='content_center_col ion-justify-content-center'>
                  <div id='recordingTimeLabel' className='timertekst'>
                    {recordingStopwatch}
                  </div>
                </IonCol>
              </IonRow>
            ) : null}
          </IonCol>
        </IonRow>
        <IonRow className='ion-justify-content-between last_row'>
          <IonButton
            mode='md'
            fill='solid'
            shape='round'
            color='light'
            onClick={() => {
              socket?.emit('stateChange', { state: 'calibration' });
            }}
            disabled={recordingState == 'Idle' ? false : true}
          >
            <IonIcon icon={arrowBack} slot='icon-only' />
          </IonButton>
        </IonRow>
      </IonGrid>
    </>
  );
};
