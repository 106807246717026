// Ionic related imports
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonModal,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToast,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import {
  closeCircleOutline,
  downloadOutline,
  folderOutline,
  informationCircleOutline,
  powerOutline,
  trashOutline,
} from 'ionicons/icons';
// React imports
import { useEffect, useRef, useState } from 'react';

// Third party libraries/frameworks
import { Socket } from 'socket.io-client';

// Custom imports
import './GatewayCard.css';
import './gatewayStates.css';
import {
  ServerToClientEvents,
  ClientToServerEvents,
  SocketContext,
  AssignObject,
  PageDataObject,
  RecordingCsvFile,
  MappableSegment,
} from '../Context/socket';
import { StartContent, ScanningAndConnectingContent, CalibrationContent, RecordingContent } from './gatewayStates';
import { ORYXGOType, ORYXKSType } from '../../../../model';
import { useFirebaseUpload } from '../../../../hooks/useFirebaseUpload';
import { ErrorToast } from '../../../../components/ErrorToast';
import { useAuthContext } from '../../../../components/authContext';
import { getCache, setCache } from '../../../../utilities/CacheController';
import { useHardwareContext } from '../../../../components/hardwareContext';
import { ORYXCardHeader } from '../../../../components/componentHeaders';

interface GatewayCardProps {
  socket: Socket<ServerToClientEvents, ClientToServerEvents>;
  gatewayInfo: ORYXKSType;
  gatewayUUID: string;
  connectedGateway: any;
}

export interface SavedRecordingRecord {
  filename: string;
  tags: string;
}

export interface csvFile {
  id: number;
  filename: string;
}
// Used functions for this state:
const PRECISION = 1000;

function convertToCsv(info: RecordingCsvFile): RecordingCsvFile {
  const dataView = new DataView(info.data.buffer);
  let bytesRead = 0;

  info.csv = getPoseCaption();

  while (bytesRead < info.data.byteLength) {
    info.csv += getBodyPartCsv(dataView, bytesRead);
    const bufferedNameSize = dataView.getUint8(bytesRead + 4) + 1;
    bytesRead += 70 + bufferedNameSize;
  }

  return info;
}

function getPoseCaption() {
  return 'timestamp,name,w_q,x_q,y_q,z_q,reliableOrientation,xz,xz_v,xz_a,yz,yz_v,yz_a,xy,xy_v,xy_a,x_p,y_p,z_p,reliablePosition\n';
}

// modified function found on the gateway, used here to receive the binary file and converting it on site.
function getBodyPartCsv(bufferView: DataView, bytesRead: number) {
  const localOffset = bytesRead;
  const bufferedNameSize = bufferView.getUint8(localOffset + 4) + 1; // Name + size

  bytesRead += 70 + bufferedNameSize;

  let x = '';
  new Uint16Array(bufferView.buffer.slice(localOffset + 5, localOffset + 5 + bufferedNameSize - 1)).forEach(function (
    byte: number,
  ) {
    x += String.fromCharCode(byte);
  });

  return (
    bufferView.getUint32(localOffset + 0, true) / 1000 +
    ',' +
    x +
    ',' +
    bufferView.getInt32(localOffset + 4 + bufferedNameSize, true) / PRECISION +
    ',' +
    bufferView.getInt32(localOffset + 8 + bufferedNameSize, true) / PRECISION +
    ',' +
    bufferView.getInt32(localOffset + 12 + bufferedNameSize, true) / PRECISION +
    ',' +
    bufferView.getInt32(localOffset + 16 + bufferedNameSize, true) / PRECISION +
    ',' +
    bufferView.getUint8(localOffset + 20 + bufferedNameSize) +
    ',' +
    bufferView.getInt32(localOffset + 21 + bufferedNameSize, true) / PRECISION +
    ',' +
    bufferView.getInt32(localOffset + 25 + bufferedNameSize, true) / PRECISION +
    ',' +
    bufferView.getInt32(localOffset + 29 + bufferedNameSize, true) / PRECISION +
    ',' +
    bufferView.getInt32(localOffset + 33 + bufferedNameSize, true) / PRECISION +
    ',' +
    bufferView.getInt32(localOffset + 37 + bufferedNameSize, true) / PRECISION +
    ',' +
    bufferView.getInt32(localOffset + 41 + bufferedNameSize, true) / PRECISION +
    ',' +
    bufferView.getInt32(localOffset + 45 + bufferedNameSize, true) / PRECISION +
    ',' +
    bufferView.getInt32(localOffset + 49 + bufferedNameSize, true) / PRECISION +
    ',' +
    bufferView.getInt32(localOffset + 53 + bufferedNameSize, true) / PRECISION +
    ',' +
    bufferView.getInt32(localOffset + 57 + bufferedNameSize, true) / PRECISION +
    ',' +
    bufferView.getInt32(localOffset + 61 + bufferedNameSize, true) / PRECISION +
    ',' +
    bufferView.getInt32(localOffset + 65 + bufferedNameSize, true) / PRECISION +
    ',' +
    bufferView.getUint8(localOffset + 69 + bufferedNameSize) +
    '\n'
  );
}

export const GatewayCard = ({ socket, gatewayInfo, gatewayUUID, connectedGateway }: GatewayCardProps) => {
  const { dataResponse, isError, errorMessage, setUploadData, clearError } = useFirebaseUpload();
  const user = useAuthContext().state.userState?.user;

  const [showGatewayInfoModal, setShowGatewayInfoModal] = useState(false);
  const [showFileListModal, setShowFileListModal] = useState(false);
  const [fileListReady, setFileListReady] = useState<boolean>(false);
  const [_connected, setConnection] = useState(false);
  const [firmwareVersion, setFirmwareVersion] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [gatewayCardTitle, setGatewayCardTitle] = useState(String);
  const [GUIState, setGUIState] = useState('notconnected');
  const [showConnectionError, setShowConnectionError] = useState(false);
  const [showClientAlreadyConnected, setShowClientAlreadyConnected] = useState(false);
  const [checked, setChecked] = useState(false);
  const [uploadStarted, setUploadStarted] = useState<boolean>(false);
  const [downloadStarted, setDownloadStarted] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState<boolean>(false);
  const [downloading, setDownloading] = useState('idle');
  const [downloadReady, setDownloadReady] = useState<boolean>(false);
  const [previousState, setPreviousState] = useState('notconnected');

  const [pageData, setPageData] = useState<PageDataObject>({
    version: '',
    language: '',
    UID: '',
    currentState: 'start',
    numberOfSensorsAllowed: gatewayInfo.measurements.numberOfSensors,
  });

  const [assignedSensors, setAssignedSensors] = useState<AssignObject[]>([]);

  const recordingCsvFile = useRef<RecordingCsvFile>({
    name: 'UNDEFINED',
    csv: '',
    data: new Uint8Array(0),
    received: 0,
  });

  const savedRecordings = useRef<SavedRecordingRecord[]>([]);
  const recordingTags = useRef<string>('');

  // const fileList: csvFile[] = [];
  const [fileList, setFileList] = useState<csvFile[]>([]);

  useEffect(() => {
    const fileInfo = convertToCsv(recordingCsvFile.current);
    const fileBlob = new Blob([fileInfo.csv], { type: 'octet/stream' });
    const file = new File([fileBlob], fileInfo.name);
    if (downloadStarted && downloadReady) {
      if (import.meta.env.MODE !== 'production') console.log('downloadStarted and Ready!');

      // Create an object URL for the blob object
      const url = URL.createObjectURL(file);

      // Create a new anchor element
      const a = document.createElement('a');

      // Set the href and download attributes for the anchor element
      // You can optionally set other attributes like `title`, etc
      // Especially, if the anchor element will be attached to the DOM
      a.href = url;
      a.download = fileInfo.name || 'download';

      // Click handler that releases the object URL after the element has been clicked
      // This is required for one-off downloads of the blob content
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
          removeEventListener('click', clickHandler);
        }, 150);
      };

      // Add the click event listener on the anchor element
      // Comment out this line if you don't want a one-off download of the blob content
      a.addEventListener('click', clickHandler, false);

      // Programmatically trigger a click on the anchor element
      // Useful if you want the download to happen automatically
      // Without attaching the anchor element to the DOM
      // Comment out this line if you don't want an automatic download of the blob content
      a.click();
      setDownloadStarted(false);
    } else if (downloadReady && uploadStarted) {
      if (!!user && !!file) {
        const user_id = user.uid;
        const tags = recordingTags.current;
        const option = 'undefined';
        // Perform the acutal file upload using the hook
        setUploadData({ user_id: user_id, tags: tags, option: option, file: file });
      }
    }
  }, [downloadStarted, downloadReady, uploadStarted]);

  useEffect(() => {
    if (dataResponse && dataResponse != undefined) {
      setUploadCompleted(true);

      if (savedRecordings.current.length !== 0) {
        // we can force unwrap here knowing that the length of the array will be greater than 0
        const nextRecordingRecord = savedRecordings.current.shift()!;

        recordingTags.current = nextRecordingRecord.tags;

        setTimeout(() => {
          socket?.emit('downloadFile', nextRecordingRecord.filename);
        }, 1000);
      } else {
        setCache([], 'saved_recordings');
        setUploadStarted(false);
        if (import.meta.env.MODE !== 'production') console.log('setUploadStarted to false');
      }
    }
  }, [dataResponse]);

  // We're using react' useEffect() hook here in order to only set these event handlers once, on mounting the component.
  // Otherwise, the event handlers would be set multiple times. (This will be a common theme in other components that use the socket.)
  useEffect(() => {
    setGatewayCardTitle(gatewayInfo.alias);

    socket.on('connect', () => {
      connectedGateway(gatewayInfo.gateway.hostName);
      if (import.meta.env.MODE !== 'production') console.log('Socket connected!');
      setConnection(true);

      // Initial page data request on
      socket.emit('pageDataRequest');
      setChecked(true);

      const allowedSensors: string[] = [];
      Object.entries(gatewayInfo.sensors).map(([sensorSerialNumber, sensor]) => {
        allowedSensors.push(sensor.macAddress);
      });
      socket.emit('setAllowedSensors', JSON.stringify(allowedSensors));

      // get the assignments info from the gatewayDatabase and create the storedAssignments object (assignment.address & assignment.segmentName)
      const storedAssignments: any[] = [];
      Object.entries(gatewayInfo.assignments).map(([sensorMacAddress, segment]) => {
        const assignment: any = {
          address: sensorMacAddress,
          segment: segment,
        };
        storedAssignments.push(assignment);
      });
      socket.emit('setSensorAssignments', storedAssignments);
    });

    socket.on('disconnect', (err) => {
      setGUIState('notconnected');
      if (import.meta.env.MODE !== 'production') console.log('Socket disconnected!');
      setConnection(false);
      connectedGateway('');
      setChecked(false);

      if (err === 'io server disconnect') {
        setShowClientAlreadyConnected(true);
      }
    });

    socket.on('connect_error', (err) => {
      if (import.meta.env.MODE !== 'production') console.log('Connection error!', err);
      setShowConnectionError(true);
      setConnection(false);
      setChecked(false);
    });

    socket.on('stateChange', (changedState) => {
      if (import.meta.env.MODE !== 'production') console.log(`State has changed to ${changedState}`);
      setGUIState(changedState);

      if (previousState === 'recording' && changedState == 'start') {
        setUploadStarted(true);
        if (import.meta.env.MODE !== 'production') console.log('setUploadStarted to true');
        getCache<SavedRecordingRecord[]>('saved_recordings').then((value) => {
          if (import.meta.env.MODE !== 'production') console.log('saved recordings: ', value);
          if (!value || value.length === 0) {
            setUploadStarted(false);
            if (import.meta.env.MODE !== 'production') console.log('No files to handle');
            return;
          }
          savedRecordings.current = value;

          if (import.meta.env.MODE !== 'production') console.log(value);
          // we can force unwrap here knowing that the length of the array will be greater than 0
          const initialRecordingRecord = savedRecordings.current.shift()!;
          if (import.meta.env.MODE !== 'production') console.log('after shift');
          recordingTags.current = initialRecordingRecord.tags;

          setTimeout(() => {
            socket?.emit('downloadFile', initialRecordingRecord.filename);
          }, 1000);
        });
      }

      setPreviousState(changedState);
      socket.emit('pageDataRequest');
    });

    socket.on('pageData', (pageData) => {
      const pageDataObject = {
        ...pageData,
        numberOfSensorsAllowed: gatewayInfo.measurements.numberOfSensors,
      };
      setPageData(pageDataObject);
      setGUIState(pageData.currentState);

      if (import.meta.env.MODE !== 'production') console.log('PAGEDATA', pageDataObject);

      if (firmwareVersion === '' || serialNumber === '') {
        setFirmwareVersion(pageData.version);
        setSerialNumber(pageData.UID);
      }

      if (pageData.sessionInfo) {
        setGatewayCardTitle(pageData.sessionInfo.sessionName);
      } else {
        setGatewayCardTitle(gatewayInfo.alias);
      }

      if (pageData.connectedSensors && pageData.currentState == 'assigning') {
        // Automatically apply assigning of sensors to gateway. We do this because the gateway still requires manual assigning
        // even though we have ommitted the entire 'assigning' state. This basically simulates assigning the sensors manually.
        for (const s of pageData.connectedSensors) {
          socket.emit('guiEvent', 'assignSensor', { sensorAddress: s.address, segmentName: s.mappedSegment });
        }

        // Immedietely change state to calibration after we're done assigning, and stop RTS of orientations because
        // we will not need RTS from this point forward.
        socket.emit('stateChange', { state: 'calibration' });
        socket.emit('guiEvent', 'stopOrientations', null);
      }

      if (pageData.assignedSensors && (pageData.currentState == 'calibration' || pageData.currentState == 'session')) {
        // convert the pageData.assignedSensors for the frontend to use
        const assignedSensorsFrontend: AssignObject[] = [];
        pageData.assignedSensors.map((sensor) => {
          if (sensor.segment.includes('left')) {
            const map = sensor.segment === 'left pelvis' ? 'left thigh' : 'left calf';
            const assignedSensorFrontend: AssignObject = {
              address: sensor.address,
              segment: map,
            };
            assignedSensorsFrontend.push(assignedSensorFrontend);
          } else {
            const map = sensor.segment === 'right pelvis' ? 'right thigh' : 'right calf';
            const assignedSensorFrontend: AssignObject = {
              address: sensor.address,
              segment: map,
            };
            assignedSensorsFrontend.push(assignedSensorFrontend);
          }
        });
        setAssignedSensors(assignedSensorsFrontend);
      }
    });

    socket?.on('downloadStart', (filename, totalSize) => {
      recordingCsvFile.current = {
        name: filename,
        csv: '',
        data: new Uint8Array(totalSize),
        received: 0,
      };

      // setUploadStarted(true);
      // setDownloadStarted(true);
    });

    socket?.on('downloadDataBlock', (block) => {
      const bytes = new Uint8Array(block);

      recordingCsvFile.current.data.set(bytes, recordingCsvFile.current.received);
      recordingCsvFile.current.received += block.byteLength;
    });

    socket?.on('downloadReady', () => {
      setDownloadReady(true);
      const fileInfo = convertToCsv(recordingCsvFile.current);
      const fileBlob = new Blob([fileInfo.csv], { type: 'octet/stream' });
      const file = new File([fileBlob], fileInfo.name);

      // if (!!user && !!file) {
      //   const user_id = user.uid;
      //   const tags = recordingTags.current;

      //   // Perform the acutal file upload using the hook
      //   setUploadData({ user_id: user_id, tags: tags, file: file });
      // }
    });

    socket.on('fileList', (csvFiles) => {
      csvFiles.map((filename, i) => {
        const newFileId = Math.floor(Math.random() * 10) + 1;
        const file = {
          id: i,
          filename: filename,
        };
        // fileList.push(file);
        setFileList((fileList) => [file, ...fileList]);
      });

      // if (import.meta.env.MODE !== 'production') console.log(fileList);
      // return fileList;
    });

    return () => {
      socket.removeAllListeners();
      socket.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {/* IonToast to show connection errors with the gateway */}
      <IonToast
        isOpen={showConnectionError}
        message='Failed to connect to the Gateway - Please check if it is turned on'
        position='bottom'
        color='danger'
        onDidDismiss={() => setShowConnectionError(false)}
        duration={2500}
      />
      <IonToast
        isOpen={showClientAlreadyConnected}
        message='Failed to connect to Gateway - Another client is already connected!'
        position='bottom'
        color='warning'
        onDidDismiss={() => setShowClientAlreadyConnected(false)}
        duration={2500}
      />

      <IonToast
        isOpen={uploadCompleted}
        message={dataResponse && 'Files uploaded successfully!'}
        position='bottom'
        color='success'
        onDidDismiss={() => setUploadCompleted(false)}
        duration={2500}
      />

      <IonLoading
        isOpen={uploadStarted}
        cssClass='my-custom-class'
        message={'Processing file(s), please wait...'}
        spinner='bubbles'
        duration={25000}
      />
      <IonLoading
        isOpen={downloadStarted}
        cssClass='my-custom-class'
        message={'Processing file, please wait...'}
        spinner='bubbles'
        duration={25000}
      />

      {/* <IonAlert
        isOpen={uploadStarted}
        onDidDismiss={() => setUploadStarted(false)}
        cssClass='my-custom-class'
        header={'Alert'}
        message={'Processing Measurements....'}
      /> */}

      <ErrorToast message={isError ? errorMessage : ''} clearError={() => clearError()} />
      {/* Ion Modal to show Gateway Information */}
      <IonModal
        isOpen={showGatewayInfoModal}
        className='gatewayCard-modal'
        onDidDismiss={() => setShowGatewayInfoModal(false)}
      >
        <IonHeader class='' mode='md'>
          <IonToolbar>
            <IonTitle className='gatewayCard-modal-header'>Gateway Information</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className='gatewayCard-modal-content' scrollEvents={false} scrollY={true}>
          <IonGrid>
            <IonRow>
              <IonCol size='12'>
                <IonList lines='none' mode='ios'>
                  <IonListHeader mode='ios'>Hostname:</IonListHeader>
                  <IonItem className='gatewayCard-modal-listItem'>{gatewayInfo.gateway.hostName}</IonItem>
                  <IonListHeader mode='ios'>Firmware version:</IonListHeader>
                  <IonItem className='gatewayCard-modal-listItem'>{gatewayInfo.gateway.firmwareVersion}</IonItem>
                  <IonListHeader mode='ios'>UID:</IonListHeader>
                  <IonItem className='gatewayCard-modal-listItem'>{gatewayUUID}</IonItem>
                </IonList>
              </IonCol>
            </IonRow>

            <IonRow className='ion-justify-content-center'>
              <IonCol className='content_center_col ion-justify-content-center' size='6'>
                <IonButton
                  className='primary'
                  onClick={() => {
                    socket.emit('getFileList');
                    setShowFileListModal(true);
                  }}
                >
                  Show filelist
                  <IonIcon icon={folderOutline} slot='start' />
                </IonButton>
              </IonCol>
              {GUIState !== 'start' && (
                <IonCol className='content_center_col ion-justify-content-center' size='6'>
                  <IonButton
                    className='primary'
                    onClick={() => {
                      socket.emit('guiEvent', 'shutdownAllSensors', null);
                      setShowGatewayInfoModal(false);
                    }}
                  >
                    Shutdown sensors
                    <IonIcon icon={powerOutline} slot='start' />
                  </IonButton>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        </IonContent>

        <IonButton
          slot='icon-only'
          onClick={() => setShowGatewayInfoModal(false)}
          fill='clear'
          className='gatewayCard-Modal-close'
        >
          <IonIcon icon={closeCircleOutline} />
        </IonButton>
      </IonModal>

      {/* Ion Modal to show File List */}
      <IonModal
        isOpen={showFileListModal}
        className='gatewayCard-modal'
        onDidDismiss={() => setShowFileListModal(false)}
      >
        <IonHeader class='' mode='md'>
          <IonToolbar>
            <IonTitle className='gatewayCard-modal-header'>Gateway Files</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className='gatewayCard-modal-List' scrollEvents={false} scrollY={true}>
          <IonList inset={true} className=''>
            {fileList === undefined ? (
              <IonSpinner color='primary' name='bubbles' />
            ) : (
              fileList.map((csvFile) => {
                return (
                  <>
                    <IonItem key={csvFile.id}>
                      <IonLabel>{csvFile.filename}</IonLabel>
                      <IonIcon
                        icon={downloadOutline}
                        slot='end'
                        onClick={() => {
                          setDownloadStarted(true);
                          socket.emit('downloadFile', csvFile.filename);
                          if (import.meta.env.MODE !== 'production') console.log(csvFile.filename);
                        }}
                      />
                      <IonIcon
                        icon={trashOutline}
                        slot='end'
                        onClick={() => {
                          if (import.meta.env.MODE !== 'production') console.log('deleting file: ', csvFile.filename);
                          socket.emit('deleteFiles', [csvFile.filename]);
                        }}
                      />
                    </IonItem>
                  </>
                );
              })
            )}
          </IonList>
        </IonContent>

        <IonButton
          slot='icon-only'
          onClick={() => setShowFileListModal(false)}
          fill='clear'
          className='gatewayCard-Modal-close'
        >
          <IonIcon icon={closeCircleOutline} />
        </IonButton>
      </IonModal>

      {/* Card that is shown when there is no gateway connected */}
      {GUIState == 'notconnected' ? (
        <IonCard className='gatewayCard-root'>
          <IonCardHeader className='gatewayCard_Header_NotConnected ' mode='md'>
            <IonGrid fixed className='ion-no-padding'>
              <IonRow className='ion-no-padding ion-align-items-center attractorCard_Header_Row'>
                <IonCol size='11' className=''>
                  <IonCardTitle className='attractorCard_Title'>{gatewayCardTitle}</IonCardTitle>
                </IonCol>
                <IonCol size='1' className='ion-justify-content-center ion-text-center'>
                  <IonToggle
                    mode='ios'
                    className='icon-custom'
                    color='primary'
                    checked={checked}
                    onIonChange={(e) => {
                      // Check if connected or not in case something happened to GUI state
                      if (e.detail.checked && !socket.connected) {
                        socket.connect();
                      } else {
                        socket.emit('pageDataRequest');
                      }
                    }}
                  ></IonToggle>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      ) : (
        <IonCard className='gatewayCard-root'>
          <IonCardHeader className='attractorCard_Header'>
            <IonGrid fixed className='ion-no-padding'>
              <IonRow className='ion-no-padding ion-align-items-center attractorCard_Header_Row'>
                <IonCol size='10' className=''>
                  <IonCardTitle className='attractorCard_Title'>{gatewayCardTitle}</IonCardTitle>
                </IonCol>
                <IonCol size='1' className='ion-justify-content-center ion-text-center'>
                  <IonToggle
                    mode='ios'
                    className='icon-custom'
                    color='primary'
                    checked={checked}
                    onIonChange={(e) => {
                      socket.emit('shutdown'); // Possible add a confirm button before allowing shutdown
                      setShowGatewayInfoModal(false);
                      connectedGateway('');
                      setChecked(false);
                      setGUIState('notconnected');
                    }}
                  ></IonToggle>
                </IonCol>
                <IonCol size='1' className='ion-justify-content-center'>
                  <IonButtons className='ion-justify-content-center'>
                    <IonButton
                      slot='icon-only'
                      fill='clear'
                      className='attractorCard_Header_Buttons'
                      onClick={() => setShowGatewayInfoModal(true)}
                    >
                      <IonIcon icon={informationCircleOutline} />
                    </IonButton>
                  </IonButtons>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>

          <IonCardContent>
            {GUIState == 'start' && <StartContent />}
            {(GUIState == 'scanning' || GUIState == 'connecting' || GUIState == 'assigning') && (
              <ScanningAndConnectingContent pageData={pageData} />
            )}
            {/* {(GUIState == 'connecting' || GUIState == 'assigning') && <ConnectingContent />} */}
            {GUIState == 'calibration' && <CalibrationContent assignedSensors={assignedSensors} pageData={pageData} />}
            {(GUIState == 'session' || GUIState == 'recording') && (
              <RecordingContent assignedSensors={assignedSensors} pageData={pageData} />
            )}
          </IonCardContent>
        </IonCard>
      )}
    </SocketContext.Provider>
  );
};
