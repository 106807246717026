import React from 'react';

import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';
import { RunAnalysisCards } from './Components/PDFRunAnalysis';
import { PDFKinematicGraphSection } from './Components/PDFKinematicGraphSection';
import { PDFPage } from './Components/PDFPage';
import { PDFRunInfoTable } from './Components/PDFRunInfoTable';
import { PDFFormatting, PDFTextStyles } from './styles';
import { PDFVersion } from './PDFDocument';

const styles = StyleSheet.create({
  FootplantChartImage: {
    height: '100%',
    width: 'auto',
  },
});

export interface DocumentProps {
  reportInfo: any;
  data: any;
  version: PDFVersion;
}

export const PDFRunReport = ({ reportInfo, data, version }: DocumentProps) => {
  const reportName = reportInfo.reportName;
  const logoUrl = data.logoURL;
  return (
    <>
      {/* Run Analysis Cards */}
      <PDFPage reportName={reportName} logoUrl={logoUrl} PageNumber={1}>
        <RunAnalysisCards attractorLabels={data.attractorLabels} />
      </PDFPage>

      {/* Footplant & Table */}
      <PDFPage reportName={reportName} logoUrl={logoUrl} PageNumber={2}>
        <View style={PDFFormatting.contentContainer_45H}>
          <View style={PDFFormatting.contenContainer_50w}>
            <Text style={PDFTextStyles.containerTitle}>Projection Footplant - Left</Text>
            {/* Graphs */}
            <Image src={data.footplantAtInitialContact_LeftChartDataUri} style={styles.FootplantChartImage} />
            <Image src={data.footplantAtMidStance_LeftChartDataUri} style={styles.FootplantChartImage} />
            <Image src={data.footplantAtHeelOff_LeftChartDataUri} style={styles.FootplantChartImage} />
          </View>
          <View style={PDFFormatting.contenContainer_50w} debug={false}>
            <Text style={PDFTextStyles.containerTitle}>Projection Footplant - Right</Text>
            {/* Graphs */}
            <Image src={data.footplantAtInitialContact_RightChartDataUri} style={styles.FootplantChartImage} />
            <Image src={data.footplantAtMidStance_RightChartDataUri} style={styles.FootplantChartImage} />
            <Image src={data.footplantAtHeelOff_RightChartDataUri} style={styles.FootplantChartImage} />
          </View>
        </View>
        <View style={PDFFormatting.contentContainer_45H}>
          <View style={PDFFormatting.tableContainer}>
            <Text style={PDFTextStyles.containerTitle}>Info</Text>
            <PDFRunInfoTable runningStatusData={data.runningStatusData} romData={data.romData} />
          </View>
        </View>
      </PDFPage>

      {/* Kinematic Graphs */}
      {version == 'Specialist' && (
        <PDFPage reportName={reportName} logoUrl={logoUrl} PageNumber={3}>
          <PDFKinematicGraphSection
            title={'Pelvis'}
            sagittalGraph={data.pelvisKinematicSagittalChartDataUri}
            coronalGraph={data.pelvisKinematicCoronalChartDataUri}
            transversalGraph={data.pelvisKinematicTransversalChartDataUri}
          />
          <PDFKinematicGraphSection
            title={'Hip'}
            sagittalGraph={data.hipKinematicSagittalChartDataUri}
            coronalGraph={data.hipKinematicCoronalChartDataUri}
            transversalGraph={data.hipKinematicTransversalChartDataUri}
          />
          <PDFKinematicGraphSection
            title={'Knee'}
            sagittalGraph={data.kneeKinematicSagittalChartDataUri}
            coronalGraph={data.kneeKinematicCoronalChartDataUri}
            transversalGraph={data.kneeKinematicTransversalChartDataUri}
          />
          <PDFKinematicGraphSection
            title={'Ankle'}
            sagittalGraph={data.ankleKinematicSagittalChartDataUri}
            coronalGraph={data.ankleKinematicCoronalChartDataUri}
            transversalGraph={data.ankleKinematicTransversalChartDataUri}
          />
        </PDFPage>
      )}
    </>
  );
};
