/** The Run Kinematics report tab */
import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonText } from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { RunReportData } from '../../../model';
import {
  JointCouplingGraph,
  ScissorsColumnChart,
  ScissorsGraphCard,
  StanceCoordinativeVariabilityGraph,
  StanceLineGraph,
  ThighBarGraph,
} from '../components/Graphs';

import '../components/Graphs/graphCard.css';
import { DashboardCard } from '../components/Cards';
import { ScissorsReportData } from '../../../model/reportV2.model';

type AttractorsReportProps = RouteComponentProps & {
  tabData: ScissorsReportData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunScissorsReportTab = ({ tabData }: AttractorsReportProps) => {
  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          {tabData && (
            <IonGrid fixed className='full_height'>
              <IonRow>
                <IonCol size='4' className=''>
                  <ScissorsGraphCard
                    series={tabData.scissorsData_Left.graphData}
                    title={'Scissors Left'}
                    x={tabData.scissorsData_Left.firstMarker}
                    x2={tabData.scissorsData_Left.secondMarker}
                    colors={tabData.scissorsData_Left.colors}
                    zoom={false}
                    info={false}
                  />
                </IonCol>
                <IonCol size='4' className=''>
                  <ScissorsGraphCard
                    series={tabData.scissorsData_Right.graphData}
                    title={'Scissors Right'}
                    x={tabData.scissorsData_Right.firstMarker}
                    x2={tabData.scissorsData_Right.secondMarker}
                    colors={tabData.scissorsData_Right.colors}
                    zoom={false}
                    info={false}
                  />
                </IonCol>
                <IonCol size='4' className=''>
                  <ScissorsColumnChart
                    title={'Scissors velocity'}
                    subtitle={''}
                    data={tabData.scissorsBarGraphData}
                    yAxisLabel={tabData.hamstringData.yAxisLabelVelocity}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size='4' className=''>
                  <JointCouplingGraph
                    data={tabData.runJointCouplingData.seriesJointCoupling_Left}
                    title={'Hip / Knee coupling Left'}
                    side={'Left'}
                    zoom={true}
                    info={false}
                    zoomModalContent={tabData.runJointCouplingData.jointCouplingZoomModalContent}
                    xMin={tabData.runJointCouplingData.jointCouplingAxisRange.xMinValue}
                    xMax={tabData.runJointCouplingData.jointCouplingAxisRange.xMaxValue}
                    xRange={tabData.runJointCouplingData.jointCouplingAxisRange.xTickAmount}
                  />
                </IonCol>
                <IonCol size='4' className=''>
                  <JointCouplingGraph
                    data={tabData.runJointCouplingData.seriesJointCoupling_Right}
                    title={'Hip / Knee coupling Right'}
                    side={'Right'}
                    zoom={true}
                    info={false}
                    zoomModalContent={tabData.runJointCouplingData.jointCouplingZoomModalContent}
                    xMin={tabData.runJointCouplingData.jointCouplingAxisRange.xMinValue}
                    xMax={tabData.runJointCouplingData.jointCouplingAxisRange.xMaxValue}
                    xRange={tabData.runJointCouplingData.jointCouplingAxisRange.xTickAmount}
                  />
                </IonCol>
                <IonCol size='4' className=''>
                  <ThighBarGraph
                    data={tabData.hamstringData.thighVelocityBarData}
                    title={'Hamstring propulsion - Velocity'}
                    xAxisCategories={['Peak TSw', 'AV. TSw', 'Peak TSt', 'Av. Stance']}
                    stdData={tabData.hamstringData.thighVelocityBarData_std}
                    zoom={false}
                    info={false}
                    yAxisLabel={tabData.hamstringData.yAxisLabelVelocity}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size='4' className=''>
                  <StanceCoordinativeVariabilityGraph
                    data={tabData.coordinativeVariabilityData.coordinativeVariabilityData_Left.series}
                    title={'Coordinative Variability Hip Left'}
                    subtitle={'Pelvis / Thigh (s/s & c/t)'}
                    ICAnnotation={
                      tabData.coordinativeVariabilityData.coordinativeVariabilityData_Left.annotations.ICAnnotation_Left
                    }
                    MStAnnotation={
                      tabData.coordinativeVariabilityData.coordinativeVariabilityData_Left.annotations
                        .MStAnnotation_Left
                    }
                    HOAnnotation={
                      tabData.coordinativeVariabilityData.coordinativeVariabilityData_Left.annotations.HOAnnotation_Left
                    }
                    TOAnnotation={
                      tabData.coordinativeVariabilityData.coordinativeVariabilityData_Left.annotations.TOAnnotation_Left
                    }
                    zoom={false}
                    info={false}
                  />
                </IonCol>
                <IonCol size='4' className=''>
                  <StanceCoordinativeVariabilityGraph
                    data={tabData.coordinativeVariabilityData.coordinativeVariabilityData_Right.series}
                    title={'Coordinative Variability Hip Right'}
                    subtitle={'Pelvis / Thigh (s/s & c/t)'}
                    ICAnnotation={
                      tabData.coordinativeVariabilityData.coordinativeVariabilityData_Right.annotations
                        .ICAnnotation_Right
                    }
                    MStAnnotation={
                      tabData.coordinativeVariabilityData.coordinativeVariabilityData_Right.annotations
                        .MStAnnotation_Right
                    }
                    HOAnnotation={
                      tabData.coordinativeVariabilityData.coordinativeVariabilityData_Right.annotations
                        .HOAnnotation_Right
                    }
                    TOAnnotation={
                      tabData.coordinativeVariabilityData.coordinativeVariabilityData_Right.annotations
                        .TOAnnotation_Right
                    }
                    zoom={false}
                    info={false}
                  />
                </IonCol>
                <IonCol size='4' className=''>
                  <ThighBarGraph
                    data={tabData.hamstringData.thighAccelerationBarData}
                    title={'Hamstring propulsion - Acceleration'}
                    xAxisCategories={['TSw', 'Stance']}
                    stdData={tabData.hamstringData.thighAccelerationBarData_std}
                    zoom={false}
                    info={false}
                    yAxisLabel={tabData.hamstringData.yAxisLabelAcceleration}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size='4' className=''>
                  <StanceLineGraph
                    series={tabData.swingLegRetractionData.thighAccelerationSeriesData_Left}
                    title={'Swing Leg Retraction Left'}
                    subtitle={'Propulsion in stance - Acceleration'}
                    ICAnnotation={
                      tabData.coordinativeVariabilityData.coordinativeVariabilityData_Left.annotations.ICAnnotation_Left
                    }
                    MStAnnotation={
                      tabData.coordinativeVariabilityData.coordinativeVariabilityData_Left.annotations
                        .MStAnnotation_Left
                    }
                    HOAnnotation={
                      tabData.coordinativeVariabilityData.coordinativeVariabilityData_Left.annotations.HOAnnotation_Left
                    }
                    TOAnnotation={
                      tabData.coordinativeVariabilityData.coordinativeVariabilityData_Left.annotations.TOAnnotation_Left
                    }
                    yAxisMin={tabData.swingLegRetractionData.yMinValue}
                    yAxisMax={tabData.swingLegRetractionData.yMaxValue}
                    zoom={false}
                    info={false}
                    yAxisLabel={tabData.hamstringData.yAxisLabelAcceleration}
                  />
                </IonCol>
                <IonCol size='4' className=''>
                  <StanceLineGraph
                    series={tabData.swingLegRetractionData.thighAccelerationSeriesData_Right}
                    title={'Swing Leg Retraction Right'}
                    subtitle={'Propulsion in stance - Acceleration'}
                    ICAnnotation={
                      tabData.coordinativeVariabilityData.coordinativeVariabilityData_Right.annotations
                        .ICAnnotation_Right
                    }
                    MStAnnotation={
                      tabData.coordinativeVariabilityData.coordinativeVariabilityData_Right.annotations
                        .MStAnnotation_Right
                    }
                    HOAnnotation={
                      tabData.coordinativeVariabilityData.coordinativeVariabilityData_Right.annotations
                        .HOAnnotation_Right
                    }
                    TOAnnotation={
                      tabData.coordinativeVariabilityData.coordinativeVariabilityData_Right.annotations
                        .TOAnnotation_Right
                    }
                    yAxisMin={tabData.swingLegRetractionData.yMinValue}
                    yAxisMax={tabData.swingLegRetractionData.yMaxValue}
                    zoom={false}
                    info={false}
                    yAxisLabel={tabData.hamstringData.yAxisLabelAcceleration}
                  />
                </IonCol>
                <IonCol size='4' className=''>
                  <DashboardCard>
                    <table className='ORYX_Table_Scroll'>
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th>Left</th>
                          <th>Right</th>
                          <th>Symmetry</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='ORYX_Table_Head'>
                          <td colSpan={4} className='ORYX_Table_Scroll_SectionHeader'>
                            Scissors
                          </td>
                        </tr>
                        <tr>
                          <td>Average velocity</td>
                          <td>
                            {tabData.scissorsTableData.scissors.averageVelocity.left +
                              ' ' +
                              tabData.scissorsTableData.scissors.averageVelocity.unit}
                          </td>
                          <td>
                            {tabData.scissorsTableData.scissors.averageVelocity.right +
                              ' ' +
                              tabData.scissorsTableData.scissors.averageVelocity.unit}
                          </td>
                          <td>{tabData.scissorsTableData.scissors.averageVelocity.symmetry + ' %'}</td>
                        </tr>
                        <tr className='ORYX_Table_Head'>
                          <td colSpan={4} className='ORYX_Table_Scroll_SectionHeader'>
                            Average Velocities
                          </td>
                        </tr>
                        <tr className=''>
                          <td>Terminal Swing</td>
                          <td>
                            {tabData.scissorsTableData.averageVelocities.terminalSwing.left +
                              ' ' +
                              tabData.scissorsTableData.averageVelocities.terminalSwing.unit}
                          </td>
                          <td>
                            {tabData.scissorsTableData.averageVelocities.terminalSwing.right +
                              ' ' +
                              tabData.scissorsTableData.averageVelocities.terminalSwing.unit}
                          </td>
                          <td>{tabData.scissorsTableData.averageVelocities.terminalSwing.symmetry + ' %'}</td>
                        </tr>
                        <tr className=''>
                          <td>Stance</td>
                          <td>
                            {tabData.scissorsTableData.averageVelocities.stance.left +
                              ' ' +
                              tabData.scissorsTableData.averageVelocities.stance.unit}
                          </td>
                          <td>
                            {tabData.scissorsTableData.averageVelocities.stance.right +
                              ' ' +
                              tabData.scissorsTableData.averageVelocities.stance.unit}
                          </td>
                          <td>{tabData.scissorsTableData.averageVelocities.stance.symmetry + ' %'}</td>
                        </tr>
                        <tr className='ORYX_Table_Head'>
                          <td colSpan={4} className='ORYX_Table_Scroll_SectionHeader'>
                            Peak velocities and accelerations
                          </td>
                        </tr>
                        <tr className=''>
                          <td>Velocity Terminal Swing</td>
                          <td>
                            {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing.left +
                              ' ' +
                              tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing.unit}
                          </td>
                          <td>
                            {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing.right +
                              ' ' +
                              tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing.unit}
                          </td>
                          <td>
                            {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing.symmetry +
                              ' %'}
                          </td>
                        </tr>
                        <tr className=''>
                          <td> Velocity Terminal Stance</td>
                          <td>
                            {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.left +
                              ' ' +
                              tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.unit}
                          </td>
                          <td>
                            {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.right +
                              ' ' +
                              tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.unit}
                          </td>
                          <td>
                            {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.symmetry + ' %'}
                          </td>
                        </tr>
                        <tr className=''>
                          <td>Acceleration Terminal Swing</td>
                          <td>
                            {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing.left +
                              ' ' +
                              tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing.unit}
                          </td>
                          <td>
                            {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing.right +
                              ' ' +
                              tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing.unit}
                          </td>
                          <td>
                            {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing
                              .symmetry + ' %'}
                          </td>
                        </tr>
                        <tr className=''>
                          <td>Acceleration Stance</td>
                          <td>
                            {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.left +
                              ' ' +
                              tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.unit}
                          </td>
                          <td>
                            {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.right +
                              ' ' +
                              tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.unit}
                          </td>
                          <td>
                            {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.symmetry +
                              ' %'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </DashboardCard>
                </IonCol>
              </IonRow>
              <IonRow>&nbsp;</IonRow>
            </IonGrid>
          )}
        </IonContent>
      </IonPage>
    );
  }
};
function calculateYaxisRange(
  data: RunReportData | undefined,
  isValueAbove: boolean,
): { yMinValue: number; yMaxValue: number } {
  function minMax() {
    const minMax = [];
    const numCycles = Object.keys(data?.reportId.thighAccelerationStance_Left).length;
    for (let i = 0; i < numCycles; i++) {
      const min = Math.min(
        ...data?.reportId.thighAccelerationStance_Left[`col${i}`],
        ...data?.reportId.thighAccelerationStance_Right[`col${i}`],
      );
      const max = Math.max(
        ...data?.reportId.thighAccelerationStance_Left[`col${i}`],
        ...data?.reportId.thighAccelerationStance_Right[`col${i}`],
      );
      minMax.push({ min, max });
    }
    return minMax;
  }

  const minMaxArray = minMax();

  const minValueObject = minMaxArray.reduce(function (prev: any, curr: any) {
    return prev.min < curr.min ? prev : curr;
  });

  const maxValueObject = minMaxArray.reduce(function (prev: any, curr: any) {
    return prev.max > curr.max ? prev : curr;
  });

  const valueToAdd = isValueAbove ? 50 : 1000;
  const yMinValue = minValueObject.min - valueToAdd;
  const yMaxValue = maxValueObject.max + valueToAdd;
  return { yMinValue, yMaxValue };
}
