import React from 'react';

import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';
import { PDFTextStyles } from '../styles';

const styles = StyleSheet.create({
  cardcontent: {
    flexDirection: 'row',
    // borderWidth: 1,
    // borderColor: '#E2E2E2',
    // borderRadius: 4,
    // borderStyle: 'solid',
    height: '100%',
  },
  KpiCardscontainer_Small: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '21%',
    paddingHorizontal: 5,
    paddingVertical: 8,
    marginHorizontal: 5,
    marginTop: 5,
    alignSelf: 'center',
    marginBottom: '5px',
    border: 1,
    borderColor: '#E2E2E2',
    borderRadius: 4,
    borderStyle: 'solid',
  },
  KinematicsGraphContainer: {
    display: 'flex',
    paddingHorizontal: 5,
    paddingVertical: 8,
    marginHorizontal: 5,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '33%',
    alignContent: 'center',
    height: '100%',
    // border: 1,
    // borderColor: '#E2E2E2',
    // borderRadius: 4,
    // borderStyle: 'solid',
  },
  GraphTitle: {
    fontSize: 8,
    fontFamily: 'Montserrat',
    color: '#2066FA',
    textAlign: 'center',
  },
  ChartImage: {
    width: '100%',
    height: '100%',
  },
  legendContainer: {
    flexDirection: 'row',
    width: '25%',
    padding: 5,
    marginBottom: 5,
    marginTop: 5,
    position: 'absolute',
    right: 0,
    top: 0,
    alignContent: 'center',
    alignItems: 'center',
  },
  legendItemLeft: {
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    backgroundColor: '#2066FA',
    margin: '5px',
  },
  legendItemRight: {
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    backgroundColor: '#22c55e', // --ion-color-success600
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '20px',
    marginRight: '5px',
  },
  legendTitleContainer: {
    alignContent: 'center',
    justifyContent: 'flex-start',
  },
  legendTitle: {
    fontSize: 8,
    fontFamily: 'Montserrat',
    color: '#767676',
    paddingLeft: '0px',
  },
});

type ReportSectionProps = {
  title: string;
  sagittalGraph: string;
  coronalGraph: string;
  transversalGraph: string;
};

export const PDFKinematicGraphSection = ({
  title,
  sagittalGraph,
  coronalGraph,
  transversalGraph,
}: ReportSectionProps) => {
  return (
    <>
      <View style={styles.KpiCardscontainer_Small}>
        <Text style={PDFTextStyles.containerTitle}>{title}</Text>
        {/* Legend */}
        {title === 'Pelvis' && (
          <View style={styles.legendContainer}>
            {/* Left */}
            <View style={styles.legendItemLeft} />
            <View style={styles.legendTitleContainer}>
              <Text style={styles.legendTitle}>Left</Text>
            </View>
            <View style={styles.legendItemRight} />
            <View style={styles.legendTitleContainer}>
              <Text style={styles.legendTitle}>Right</Text>
            </View>
          </View>
        )}
        {/* Graphs */}
        <View style={styles.cardcontent}>
          <View style={styles.KinematicsGraphContainer}>
            {/* Sagittal graph */}
            <Text style={styles.GraphTitle}>Sagittal</Text>
            <Image src={sagittalGraph} style={styles.ChartImage} />
          </View>
          <View style={styles.KinematicsGraphContainer} debug={false}>
            {/* Coronal graph */}
            <Text style={styles.GraphTitle}>Frontal</Text>
            <Image src={coronalGraph} style={styles.ChartImage} />
          </View>
          <View style={styles.KinematicsGraphContainer} debug={false}>
            {/* Transversal graph */}
            <Text style={styles.GraphTitle}>Transversal</Text>
            <Image src={transversalGraph} style={styles.ChartImage} />
          </View>
        </View>
      </View>
    </>
  );
};
