import { IonModal, IonCard, IonCardContent, IonGrid, IonRow, IonCol, IonImg } from '@ionic/react';
import { useState } from 'react';
import { NameField_v2, VarusValgusField } from '../Fields';

import './cards.css';
import {
  ModalHipExtensionTerminalStance,
  ModalHipExtensionTerminalSwing,
  ModalKneeExtensionTerminalStance,
  ModalKneeFlexionMidstance,
  ModalKneeFlexionMidswing,
  ModalKneeStabilityLoadingResponse,
  ModalPelvicObliquity,
  ModalVarusValgus,
} from '../ModalsWalk';
import { ORYXCardHeader } from '../../../../components/componentHeaders';
import {
  ModalAnkleStiffness,
  ModalFootPlant,
  ModalHipLock,
  ModalKneeExtensionPreswing,
  ModalKneeFlexionMidstanceRun,
  ModalPositiveRunningMidstance,
  ModalPositiveRunningToeoff,
  ModalProjectionFootplant,
  ModalScissors,
  ModalSwingLegRecovery,
  ModalSwingLegRetraction,
} from '../ModalsRun';

type AttractorCardProps = {
  title: string;
  subtitle?: string;
  info: React.ReactNode;
  img: string;
  labels?: string[];
  attractorRow: number[];
  kpiLabels?: string[];
  varusValgus?: string[];
  type?: string;
};

/** Card in the analysis page to show the avatar and attractor field
 * @param img - The avatar image
 * @param labels - Attractor labels for the status field, tuple of (Left, Right) values
 */
export const AttractorCard = ({
  title,
  subtitle,
  info,
  img,
  labels,
  kpiLabels,
  attractorRow,
  varusValgus,
  type,
}: AttractorCardProps) => {
  const error = attractorRow[0] >= 2 || attractorRow[1] >= 2 ? 'error' : '';
  const cardClassName = error ? 'attractorCard_Error' : 'attractorCard';
  const kpiLabel = (labels: string[], kpi: number) => {
    const label = Number.isInteger(kpi) ? labels[kpi - 1] : labels[Math.round(kpi) - 1];
    return label;
  };
  const [showModal, setShowModal] = useState({
    show: false,
  });

  return (
    <>
      <IonModal isOpen={showModal.show} className='ORYX_Modal_Large' onDidDismiss={() => setShowModal({ show: false })}>
        {type === 'Walk' ? (
          <>
            {info === 'Hip extension at terminal stance' && (
              <ModalHipExtensionTerminalStance setShowModal={setShowModal} buttons={true} />
            )}
            {info === 'Knee extension at terminal stance' && (
              <ModalKneeExtensionTerminalStance setShowModal={setShowModal} buttons={true} />
            )}
            {info === 'Knee flexion at midswing' && (
              <ModalKneeFlexionMidswing setShowModal={setShowModal} buttons={true} />
            )}
            {info === 'Hip extension at terminal swing' && (
              <ModalHipExtensionTerminalSwing setShowModal={setShowModal} buttons={true} />
            )}
            {info === 'Knee stability at loading response' && (
              <ModalKneeStabilityLoadingResponse setShowModal={setShowModal} buttons={true} />
            )}
            {info === 'Knee flexion at midstance' && (
              <ModalKneeFlexionMidstance setShowModal={setShowModal} buttons={true} />
            )}
            {info === 'Varus Valgus' && <ModalVarusValgus setShowModal={setShowModal} buttons={true} />}
            {info === 'Pelvic obliquity' && <ModalPelvicObliquity setShowModal={setShowModal} buttons={true} />}
          </>
        ) : (
          <>
            {info === 'Ankle Stiffness' && <ModalAnkleStiffness setShowModal={setShowModal} buttons={true} />}
            {info === 'Knee extension at Terminal stance' && (
              <ModalKneeExtensionPreswing setShowModal={setShowModal} buttons={true} />
            )}
            {info === 'Hip lock' && <ModalHipLock setShowModal={setShowModal} buttons={true} />}
            {info === 'Positive running at toe-off' && (
              <ModalPositiveRunningToeoff setShowModal={setShowModal} buttons={true} />
            )}
            {info === 'Swing leg recovery' && <ModalSwingLegRecovery setShowModal={setShowModal} buttons={true} />}
            {info === 'Knee flexion at midswing' && (
              <ModalKneeFlexionMidswing setShowModal={setShowModal} buttons={true} />
            )}
            {info === 'Swing leg retraction' && <ModalSwingLegRetraction setShowModal={setShowModal} buttons={true} />}
            {info === 'Scissors' && <ModalScissors setShowModal={setShowModal} buttons={true} />}
            {info === 'Foot plant from above' && <ModalFootPlant setShowModal={setShowModal} buttons={true} />}
            {info === 'Projection footplant' && <ModalProjectionFootplant setShowModal={setShowModal} buttons={true} />}
            {info === 'Knee flexion at midstance' && (
              <ModalKneeFlexionMidstanceRun setShowModal={setShowModal} buttons={true} />
            )}
            {info === 'Positive running at midstance' && (
              <ModalPositiveRunningMidstance setShowModal={setShowModal} buttons={true} />
            )}
          </>
        )}
      </IonModal>

      <IonCard className={cardClassName}>
        <ORYXCardHeader title={title} subtitle={subtitle} setShowInfo={setShowModal} zoom={false} info={true} />

        <IonCardContent className='full_height'>
          <IonGrid class='ion-no-padding' fixed>
            <IonRow className='full_width ion-justify-content-center'>
              <IonCol>
                <IonImg src={img} class='attractorCard_Avatar_Run' />
              </IonCol>
            </IonRow>
            {labels && (
              <IonRow className='full_width ion-justify-content-center'>
                <IonCol size='6' className='border_Right'>
                  <NameField_v2 name='Left' value={labels[0]} />
                </IonCol>
                <IonCol size='6'>
                  <NameField_v2 name='Right' value={labels[1]} />
                </IonCol>
              </IonRow>
            )}
            {kpiLabels && !varusValgus && (
              <IonRow className='full_width ion-justify-content-center'>
                <IonCol size='6' className='border_Right'>
                  <NameField_v2 name='Left' value={kpiLabel(kpiLabels, attractorRow[0])} />
                </IonCol>
                <IonCol size='6'>
                  <NameField_v2 name='Right' value={kpiLabel(kpiLabels, attractorRow[1])} />
                </IonCol>
              </IonRow>
            )}
            {varusValgus && kpiLabels && (
              <IonRow className='full_width ion-justify-content-center'>
                <IonCol size='6' className='border_Right'>
                  <VarusValgusField name='Left' value={kpiLabel(kpiLabels, attractorRow[0])} label={varusValgus[0]} />
                </IonCol>
                <IonCol size='6'>
                  <VarusValgusField name='Right' value={kpiLabel(kpiLabels, attractorRow[1])} label={varusValgus[1]} />
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  );
};
