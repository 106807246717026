// change the structure of the data from col[index] to {name: side, data: [{x:number, y:number}[]]}

import { GraphData, StridesData } from '../../../model/reportV2.model';

/**
 * Function to create data series where the index is used as the x value. after the end of each column
 * a null value is returned. returns:
 * {
      x: number;
      y: number | null;
    }[][]
 */
export function toGraphData(data: StridesData | number[]): GraphData {
  if (!data) {
    return [];
  }
  if (Array.isArray(data)) {
    return data.map((xValue: number, index: number) => {
      return { x: index, y: xValue };
    });
  }
  const numCycles = Object.keys(data).length;
  const graphData: { x: number; y: number | null }[][] = [];

  for (let i = 0; i < numCycles; i++) {
    const cycleData: { x: number; y: number | null }[] = [];

    data[`col${i}`].forEach((xValue: any, index: number) => {
      if (index === data[`col${i}`].length - 1) {
        cycleData.push({ x: index, y: null });
      } else {
        cycleData.push({ x: index, y: xValue });
      }
    });

    graphData.push(cycleData);
  }

  return graphData;
}
