/** Walk analysis report tab */
import { IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow, IonText } from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import hipExtensionAtTerminalStance from '../../../assets/avatars/walk/analysis/HipExtensionAtTerminalStance.png';
import hipExtensionAtTerminalSwing from '../../../assets/avatars/walk/analysis/HipExtensionAtTerminalSwing.png';

import kneeFlexionAtMidstance from '../../../assets/avatars/walk/analysis/KneeFlexionAtMidstance.png';
import kneeFlexionAtMidswing from '../../../assets/avatars/walk/analysis/KneeFlexionAtMidswing.png';
import kneeExtensionAtTerminalStance from '../../../assets/avatars/walk/analysis/KneeExtensionAtTerminalStance.png';
import kneeStabilityAtLoadingResponse from '../../../assets/avatars/walk/analysis/KneeStabilityAtLoadingResponse.png';
import pelvicObliquity from '../../../assets/avatars/walk/analysis/PelvicObliquity.png';

import varusValgus from '../../../assets/avatars/walk/analysis/VarusValgus.png';

import './WalkAnalysisReportTab.css';

import { AttractorCard } from '../../ReportV2/components/Cards';
import { AnalysisData } from '../../../model/reportV2.model';

type WalkReportProps = RouteComponentProps & {
  tabData: AnalysisData;
};

/** The Walk analysis report tab
 * @param report - The full walk report info and data
 */
export const WalkAnalysisReportTab = ({ tabData }: WalkReportProps) => {
  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          <IonGrid fixed>
            <IonRow>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Hip extension'
                  subtitle='Terminal stance'
                  img={hipExtensionAtTerminalStance}
                  kpiLabels={tabData.kpiLabels[0]}
                  attractorRow={tabData.kpiData[1]}
                  info={'Hip extension at terminal stance'}
                  type='Walk'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee extension'
                  subtitle='Terminal stance'
                  img={kneeExtensionAtTerminalStance}
                  kpiLabels={tabData.kpiLabels[2]}
                  attractorRow={tabData.kpiData[0]}
                  info={'Knee extension at terminal stance'}
                  type='Walk'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee flexion'
                  subtitle='Midswing'
                  img={kneeFlexionAtMidswing}
                  kpiLabels={tabData.kpiLabels[0]}
                  attractorRow={tabData.kpiData[6]}
                  info={'Knee flexion at midswing'}
                  type='Walk'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Hip extension'
                  subtitle='Terminal swing'
                  img={hipExtensionAtTerminalSwing}
                  kpiLabels={tabData.kpiLabels[0]}
                  attractorRow={tabData.kpiData[2]}
                  info={'Hip extension at terminal swing'}
                  type='Walk'
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee stability'
                  subtitle='Loading response'
                  img={kneeStabilityAtLoadingResponse}
                  kpiLabels={tabData.kpiLabels[0]}
                  attractorRow={tabData.kpiData[3]}
                  info={'Knee stability at loading response'}
                  type='Walk'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee flexion'
                  subtitle='Midstance'
                  img={kneeFlexionAtMidstance}
                  kpiLabels={tabData.kpiLabels[0]}
                  attractorRow={tabData.kpiData[5]}
                  info={'Knee flexion at midstance'}
                  type='Walk'
                />
              </IonCol>

              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Varus / Valgus'
                  subtitle='Midstance'
                  img={varusValgus}
                  kpiLabels={tabData.kpiLabels[0]}
                  attractorRow={tabData.kpiData[7]}
                  info={'Varus Valgus'}
                  varusValgus={[tabData.varusValgusLabel_Left, tabData.varusValgusLabel_Right]}
                  type='Walk'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Pelvic obliquity'
                  subtitle='Midstance'
                  img={pelvicObliquity}
                  kpiLabels={tabData.kpiLabels[1]}
                  attractorRow={tabData.kpiData[4]}
                  info={'Pelvic obliquity'}
                  type='Walk'
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
};
