/** Report detail page, holding tabs for analysis, kinematics, and overview */
import { IonIcon, IonLabel, IonLoading, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';

import * as React from 'react';

import { Redirect, Route } from 'react-router-dom';

import { useEffect, useState } from 'react';

// Custom Icons
import ORYX_VariabilityIcon from '../../../../assets/icons/ORYX_VariabilityIcon.svg';
import ORYX_ScissorsIcon from '../../../../assets/icons/ORYX_ScissorsIcon.svg';
import ORYX_AttractorsIcon from '../../../../assets/icons/ORYX_AttractorsIcon.svg';
import ORYX_ReportStatusIcon from '../../../../assets/icons/ORYX_ReportStatusIcon.svg';
import ORYX_KinematicsIcon from '../../../../assets/icons/ORYX_KinematicsIcon.svg';
import ORYX_InformationIcon from '../../../../assets/icons/ORYX_InformationIcon.svg';

//   Custom css
import './index.css';

import {
  SingleLegSquatAnalysisReportTab,
  SingleLegSquatsKinematicsReportTab,
  SingleLegSquatsVariabilityReportTab,
} from '../../SingleLegSquatsReportV2';
import { OverviewReportTab } from '../../OverviewReportTab';
import { SingleLegSquatReportData } from '../../../../model/reportV2.model';

interface ReportTab {
  url: string;
  title: string;
  status: string;
  icon: string;
}

const squatTabs: ReportTab[] = [
  {
    title: 'Analysis',
    url: `analysis`,
    status: 'active',
    icon: ORYX_ReportStatusIcon,
  },
  {
    title: 'Variability',
    url: 'variability',
    status: 'active',
    icon: ORYX_VariabilityIcon,
  },
  {
    title: 'Kinematics',
    url: 'kinematics',
    status: 'active',
    icon: ORYX_KinematicsIcon,
  },
  {
    title: 'Overview',
    url: 'overview',
    status: 'active',
    icon: ORYX_InformationIcon,
  },
];

interface SLSquatTabsProps {
  id: string;
  reportData: SingleLegSquatReportData;
}

export const SingleLegSquatTabBar = ({ id, reportData }: SLSquatTabsProps) => {
  const [activeTab, setActiveTab] = useState('Analysis');
  // Set custom actions on the tabs
  const handleTabWillChange = (e: any) => {
    setActiveTab(e.detail.tab);
  };

  return (
    <IonTabs onIonTabsWillChange={handleTabWillChange}>
      <IonRouterOutlet id='main'>
        <Route
          path='/reportsV2/SingleLegSquat/:id/analysis'
          render={(props) => {
            return <SingleLegSquatAnalysisReportTab {...props} tabData={reportData.squatAnalysisData} />;
          }}
          exact={true}
        />
        <Route
          path='/reportsV2/SingleLegSquat/:id/variability'
          render={(props) => {
            return <SingleLegSquatsVariabilityReportTab {...props} tabData={reportData.squatVariabilityData} />;
          }}
          exact={true}
        />
        <Route
          path='/reportsV2/SingleLegSquat/:id/kinematics'
          render={(props) => {
            return <SingleLegSquatsKinematicsReportTab {...props} tabData={reportData.squatKinematicData} />;
          }}
          exact={true}
        />
        <Route
          path='/reportsV2/SingleLegSquat/:id/overview'
          render={(props) => {
            return (
              <OverviewReportTab {...props} fileInfo={reportData.fileInfo} reportType={reportData.reportType} id={id} />
            );
          }}
          exact={true}
        />

        <Route
          path={`/reportsV2/SingleLegSquat/${id}`}
          render={() => <Redirect to={`/reportsV2/SingleLegSquat/${id}/analysis`} />}
          exact={true}
        />
      </IonRouterOutlet>

      <IonTabBar slot='bottom' mode='ios'>
        {squatTabs.map((tab, _) => {
          let tabClassNames = '';
          if (tab.status === 'disabled') {
            tabClassNames = 'disabled';
          } else if (location.pathname === tab.url) {
            tabClassNames = 'ion-tab-button_selected';
          }
          return (
            <IonTabButton
              mode='ios'
              tab={tab.title}
              href={`/reportsV2/SingleLegSquat/${id}/${tab.url}`}
              className={activeTab === tab.title ? 'ion-tab-button_selected' : ''}
              key={tab.title}
            >
              <IonIcon icon={tab.icon} />
              <IonLabel>{tab.title}</IonLabel>
            </IonTabButton>
          );
        })}
      </IonTabBar>
    </IonTabs>
  );
};
