import { DataSeries } from '../../../model/reportV2.model';

/**
 * Function to which creates tuples of x and y of 1 column for the areaplot.
 * the xValue is the index, and the yValue is the value. returns
 * {
      x: number;
      y: number;
    }[]
 */
export function toVariabilityData(data: number[]): DataSeries {
  if (!data) {
    return [];
  }
  const seriesData = data.map((value, index) => {
    return {
      x: index,
      y: value,
    };
  });
  return seriesData;
}
