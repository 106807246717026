// Load the data for the PDF based on the reportType
import firebase from 'firebase/compat/app';
// Required for side-effects
import 'firebase/compat/firestore';
import { database } from '../../../../config/firebaseConfig';
import { ReportInfo, UserInfo } from '../../../../model';
import { getCache, CACHE_KEYS } from '../../../../utilities';
import {
  defineAttractorLabels,
  transpose,
  calculateYAxisPDFGraphs,
  getPDFFootplantData,
  getMeanRunKinematicData,
  createKinematicGraphsAnnotations,
} from '../../../../hooks/useReports/dataFunctions';
import { attractorLabelsV2 } from '../../../../model/reportV2.model';

type YAxisPlaneData = {
  yMin: number;
  yMax: number;
  tickAmount: number;
};
type YAxisJointData = {
  Sagittal: YAxisPlaneData;
  Coronal: YAxisPlaneData;
  Transversal: YAxisPlaneData;
};

type YAxisData = {
  Pelvis: YAxisJointData;
  Hip: YAxisJointData;
  Knee: YAxisJointData;
  Ankle: YAxisJointData;
};

/**
 * Loads the RUN data for generating a PDF report.
 * Retrieves user data from cache, fetches PDF data based on report type, and creates necessary data structures.
 * @param reportInfo - Information about the report to be generated.
 * @param userId - The ID of the user for whom the report is being generated.
 * @returns An object containing all the data required for generating the PDF report.
 */
export const loadRUNData = async (reportInfo: ReportInfo, userId: string) => {
  // load the userdata from the cache to retrieve the right logo
  const [logoURL, userInfo, userInfoError] = await getCache<UserInfo>(CACHE_KEYS.USERINFO_CACHE_KEY)
    .then(async (data) => {
      return [`${data?.organization.logoURL}.png`, data as UserInfo, false];
    })
    .catch((error) => {
      // If no logo is found, return the default (ORYX) logo
      return [`c7be044f-5e4d-48ea-a1ef-d888decb5988.png`, null, true];
    });

  const userRef = database.collection('users').doc(userId);
  // load the data for the PDF based on the reportType, using only the data that is needed
  const pdfData = await getRunData(reportInfo.reportType, reportInfo, userRef)
    .then((results) => {
      return results;
    })
    .catch((error) => {
      console.error(error);
    });

  // Create the right data structures for the content of the pdf
  const attractorData: number[][] = transpose(pdfData.reportId.attractors);
  const attractorLabels = defineAttractorLabels(pdfData.reportId.attractors, attractorLabelsV2);
  const runningStatusData: number[][] = transpose(pdfData.reportId.runningStatusTableData);
  const annotations_Left = pdfData.reportId.pointsOfInterests.col0;
  const annotations_Right = pdfData.reportId.pointsOfInterests.col1;

  const [footplantAtInitialContactSeries_Left, footplantAtInitialContactSeries_Right] = getPDFFootplantData(
    pdfData.reportId.projectionsFootPlant_Left,
    pdfData.reportId.projectionsFootPlant_Right,
    0,
    'IC',
  );
  const [footplantAtMidStanceSeries_Left, footplantAtMidStanceSeries_Right] = getPDFFootplantData(
    pdfData.reportId.projectionsFootPlant_Left,
    pdfData.reportId.projectionsFootPlant_Right,
    1,
    'MSt',
  );
  const [footplantAtHeelOffSeries_Left, footplantAtHeelOffSeries_Right] = getPDFFootplantData(
    pdfData.reportId.projectionsFootPlant_Left,
    pdfData.reportId.projectionsFootPlant_Right,
    2,
    'HO',
  );

  const pelvisKinematicData = getMeanRunKinematicData(pdfData, 'Pelvis');
  const hipKinematicData = getMeanRunKinematicData(pdfData, 'Hip');
  const kneeKinematicData = getMeanRunKinematicData(pdfData, 'Knee');
  const ankleKinematicData = getMeanRunKinematicData(pdfData, 'Ankle');

  // Calculate the y-axis for the kinematic graphs

  const graphAnnotations = createKinematicGraphsAnnotations(annotations_Left, annotations_Right);

  const romData: number[][] = transpose(pdfData.reportId.rom);

  // Create data structure with the data for the PDF
  const data = {
    logoURL,
    attractorData,
    attractorLabels,
    runningStatusData,
    footplantAtInitialContactSeries_Left,
    footplantAtInitialContactSeries_Right,
    footplantAtMidStanceSeries_Left,
    footplantAtMidStanceSeries_Right,
    footplantAtHeelOffSeries_Left,
    footplantAtHeelOffSeries_Right,
    pelvisKinematicData,
    hipKinematicData,
    kneeKinematicData,
    ankleKinematicData,
    graphAnnotations,
    romData,
    userInfoError,
  };

  return data;
};

async function getRunData(
  reportType: number,
  reportInfo: firebase.firestore.DocumentData,
  userRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
) {
  const promises = [];
  const collectionMappings: any = {
    1: {
      reportId: 'reports',
      fileId: 'fileId',
    },
    2: {
      reportId: 'reports',
      fileId: 'fileId',
    },
    3: {
      reportId: 'reports',
      fileId: 'fileId',
    },
    4: {
      reportId: 'reports',
      fileId: 'fileId',
    },
  };
  const collectionKeys = Object.keys(collectionMappings[reportType]);

  for (const [key, value] of Object.entries(reportInfo)) {
    if (collectionKeys.includes(key)) {
      if (key === 'fileId') {
        const collectionRef = userRef.collection('kinematicsFiles');
        const snapshot = await collectionRef.doc(reportInfo[key]).get();
        promises.push({ [key]: snapshot.data() });
        continue;
      } else {
        const collectionName = collectionMappings[reportType][key];
        const collectionRef = userRef.collection(collectionName);
        const snapshot = await collectionRef.doc(reportInfo[key]).get();
        promises.push({ [key]: snapshot.data() });
      }
    }
  }

  const results = Object.assign({}, ...promises);
  return results;
}
