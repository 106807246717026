import { createKinematicYAxis, toVariabilityData } from '.';
import { Joint, GraphSeriesData, KinematicYAxis } from '../../../model/reportV2.model';
import { colorLeft, colorRight } from '../../../theme/colors';
/**
 * Retrieves kinematic data for a specific segment from the provided PDF data.
 * @param pdfData - The PDF data containing kinematic information.
 * @param segment - The segment for which kinematic data is requested.
 * @returns An object containing kinematic data for different planes.
 */
export function getMeanRunKinematicData(
  data: any,
  segment: Joint,
): { [key: string]: { series: GraphSeriesData[]; yAxis: KinematicYAxis } } {
  const planes = ['Sagittal', 'Coronal', 'Transversal'];

  // Mapping of segment indices for different planes
  const indicesMap: { [key: string]: { [key: string]: number[] } } = {
    Sagittal: {
      Pelvis: [0, 7],
      Hip: [1, 2],
      Knee: [3, 4],
      Ankle: [5, 6],
    },
    Coronal: {
      Pelvis: [0, 1],
      Hip: [2, 3],
      Knee: [4, 5],
      Ankle: [6, 7],
    },
    Transversal: {
      Pelvis: [0, 1],
      Hip: [2, 3],
      Knee: [4, 5],
      Ankle: [6, 7],
    },
  };

  // Result object to store kinematic data for each plane
  const result: { [key: string]: { series: GraphSeriesData[]; yAxis: KinematicYAxis } } = {};

  // Iterate over each plane to extract kinematic data
  planes.forEach((plane) => {
    const indices = indicesMap[plane][segment];
    const dataKey = plane === 'Sagittal' ? 'kinematicData' : `kinematicData_${plane}`;

    const seriesDataLeft = toVariabilityData(data.reportId[dataKey][`col${indices[0]}`]);
    const seriesDataRight = toVariabilityData(data.reportId[dataKey][`col${indices[1]}`]);

    const seriesData = [
      {
        type: 'line',
        name: 'Left',
        data: seriesDataLeft,
        color: colorLeft, // Define your colorLeft variable appropriately
      },
      {
        type: 'line',
        name: 'Right',
        data: seriesDataRight,
        color: colorRight, // Define your colorRight variable appropriately
      },
    ];

    const yAxisData = createKinematicYAxis(seriesData);

    // Construct data objects for left and right sides
    // result[plane] = [
    //   {
    //     type: 'line',
    //     name: 'Left',
    //     data: toVariabilityData(data.reportId[dataKey][`col${indices[0]}`]),
    //     color: colorLeft,
    //   },
    //   {
    //     type: 'line',
    //     name: 'Right',
    //     data: toVariabilityData(data.reportId[dataKey][`col${indices[1]}`]),
    //     color: colorRight,
    //   },
    // ];
    result[plane] = {
      series: seriesData,
      yAxis: yAxisData,
    };
  });

  return result;
}
