import { flattenData, toGraphData, toVariabilityData } from '.';
import { PelvisVariabilityData } from '../../../model/reportV2.model';
import { colorCS, colorCT, colorSS, colorST, variabilityAll_Left, variabilityAll_Right } from '../../../theme/colors';

/**
 * Retrieves the pelvis variability data based on the provided input data and side.
 *
 * @param data - The input data.
 * @param side - The side of the pelvis ('Left' or 'Right').
 * @returns The pelvis variability data.
 */
export function getPelvisVariabilityData(data: any, side: 'Left' | 'Right'): PelvisVariabilityData {
  const overviewColor = side === 'Left' ? variabilityAll_Left : variabilityAll_Right;

  const crpDataPelvisThigh = toGraphData(data[`crpHip${side}`][`crpPelvisThigh_${side}`]);
  const crpSeriesDataPelvisThigh = [
    { type: 'line', name: 'Pelvis (s) / Thigh (s)', data: flattenData(crpDataPelvisThigh), color: colorSS },
  ];

  const meanCrpDataPelvisThigh = toVariabilityData(data.crpMean.meanCrpPelvisThigh[`col${side === 'Left' ? 6 : 7}`]);
  const meanCrpSeriesDataPelvisThigh = [
    { type: 'line', name: 'Pelvis (s) / Thigh (s)', data: meanCrpDataPelvisThigh, color: colorSS },
  ];

  const crpDataPelvisThighT = toGraphData(data[`crpHip${side}`][`crpPelvisThighT_${side}`]);
  const crpSeriesDataPelvisThighT = [
    { type: 'line', name: 'Pelvis (s) / Thigh (t)', data: flattenData(crpDataPelvisThighT), color: colorST },
  ];

  const meanCrpDataPelvisThighT = toVariabilityData(data.crpMean.meanCrpPelvisThigh[`col${side === 'Left' ? 2 : 3}`]);
  const meanCrpSeriesDataPelvisThighT = [
    { type: 'line', name: 'Pelvis (s) / Thigh (t)', data: meanCrpDataPelvisThighT, color: colorST },
  ];

  const crpDataPelvisCThighT = toGraphData(data[`crpHip${side}`][`crpPelvisCThighT_${side}`]);
  const crpSeriesDataPelvisCThighT = [
    { type: 'line', name: 'Pelvis (c) / Thigh (t)', data: flattenData(crpDataPelvisCThighT), color: colorCT },
  ];

  const meanCrpDataPelvisCThighT = toVariabilityData(data.crpMean.meanCrpPelvisThigh[`col${side === 'Left' ? 4 : 5}`]);
  const meanCrpSeriesDataPelvisCThighT = [
    { type: 'line', name: 'Pelvis (c) / Thigh (t)', data: meanCrpDataPelvisCThighT, color: colorCT },
  ];

  const crpDataPelvisCThigh = toGraphData(data[`crpHip${side}`][`crpPelvisCThigh_${side}`]);
  const crpSeriesDataPelvisCThigh = [
    { type: 'line', name: 'Pelvis (c) / Thigh (s)', data: flattenData(crpDataPelvisCThigh), color: colorCS },
  ];

  const meanCrpDataPelvisCThigh = toVariabilityData(data.crpMean.meanCrpPelvisThigh[`col${side === 'Left' ? 0 : 1}`]);
  const meanCrpSeriesDataPelvisCThigh = [
    { type: 'line', name: 'Pelvis (c) / Thigh (s)', data: meanCrpDataPelvisCThigh, color: colorCS },
  ];

  const coordinativeVariabilityDataAllHip = toVariabilityData(
    data.coordinativeVariability[`coordinativeVariabilityDataHip_${side}`][`col0`],
  );
  const coordinativeVariabilitySeriesAllHip = [
    { type: 'area', name: 'All planes', data: coordinativeVariabilityDataAllHip, color: overviewColor },
  ];

  const coordinativeVariabilityDataMeanHip = toVariabilityData(
    data.coordinativeVariability[`coordinativeVariabilityDataHip_${side}`][`col2`],
  );
  const coordinativeVariabilitySeriesMeanHip = [
    { type: 'area', name: 'All planes', data: coordinativeVariabilityDataMeanHip, color: overviewColor },
  ];

  const coordinativeVariabilityDataPelvisThigh = toVariabilityData(
    data.coordinativeVariabilityPlanes[`coordinativeVariabilityPelvisThigh_${side}`][`col0`],
  );
  const coordinativeVariabilitySeriesPelvisThigh = [
    {
      type: 'area',
      name: 'Pelvis (s) / Thigh (s)',
      data: coordinativeVariabilityDataPelvisThigh,
      color: colorSS,
    },
  ];

  const coordinativeVariabilityDataPelvisThighT = toVariabilityData(
    data.coordinativeVariabilityPlanes[`coordinativeVariabilityPelvisThigh_${side}`][`col2`],
  );
  const coordinativeVariabilitySeriesPelvisThighT = [
    {
      type: 'area',
      name: 'Pelvis (s) / Thigh (t)',
      data: coordinativeVariabilityDataPelvisThighT,
      color: colorST,
    },
  ];

  const coordinativeVariabilityDataPelvisCThighT = toVariabilityData(
    data.coordinativeVariabilityPlanes[`coordinativeVariabilityPelvisThigh_${side}`][`col3`],
  );
  const coordinativeVariabilitySeriesPelvisCThighT = [
    {
      type: 'area',
      name: 'Pelvis (c) / Thigh (t)',
      data: coordinativeVariabilityDataPelvisCThighT,
      color: colorCT,
    },
  ];

  const coordinativeVariabilityDataPelvisCThigh = toVariabilityData(
    data.coordinativeVariabilityPlanes[`coordinativeVariabilityPelvisThigh_${side}`][`col1`],
  );
  const coordinativeVariabilitySeriesPelvisCThigh = [
    {
      type: 'area',
      name: 'Pelvis (c) / Thigh (s)',
      data: coordinativeVariabilityDataPelvisCThigh,
      color: colorCS,
    },
  ];

  return {
    crpSeriesDataPelvisThighT,
    meanCrpSeriesDataPelvisThigh,
    crpSeriesDataPelvisCThighT,
    meanCrpSeriesDataPelvisThighT,
    crpSeriesDataPelvisCThigh,
    meanCrpSeriesDataPelvisCThighT,
    crpSeriesDataPelvisThigh,
    meanCrpSeriesDataPelvisCThigh,
    coordinativeVariabilitySeriesAllHip,
    coordinativeVariabilitySeriesMeanHip,
    coordinativeVariabilitySeriesPelvisThigh,
    coordinativeVariabilitySeriesPelvisThighT,
    coordinativeVariabilitySeriesPelvisCThighT,
    coordinativeVariabilitySeriesPelvisCThigh,
  };
}
