import { RearPendulumPointsOfInterests_Left } from '../../../model/reportV2.model';

/**
 * Combines the points of interest into a scatter plot data format.
 *
 * @param points - The points of interest for the scatter plot.
 * @param xIndex - The index of the x-axis value in each point.
 * @param yIndex - The index of the y-axis value in each point.
 * @returns The scatter plot data in the format of an array of arrays, where each inner array represents a point with its x and y values.
 */
export function combinePointsOfInterestToScatter(
  points: RearPendulumPointsOfInterests_Left,
  xIndex: number,
  yIndex: number,
): number[][] {
  const numCycles = Object.keys(points).length;
  const scatterData = [];

  for (let i = 0; i < numCycles; i++) {
    scatterData.push([points[`col${i}`][xIndex], points[`col${i}`][yIndex]]);
  }

  return scatterData;
}
