/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * ORYX Test page.
 */

import {
  IonPage,
  IonContent,
  IonGrid,
  IonTitle,
  IonCol,
  IonRow,
  IonSpinner,
  IonIcon,
  IonText,
  IonLoading,
} from '@ionic/react';
import { ORYXHeader } from '../../../components/menuItems/Header';
import { GatewayCard } from './GatewayCard';
import './ORYX.css';
import { useEffect, useState } from 'react';
import { GatewayInfo, GatewaySocket, SOCKET_OPTIONS } from './context/socket';
import { useHardwareContext } from '../../../components/hardwareContext';
import { io } from 'socket.io-client';
import { RouteComponentProps, useParams } from 'react-router';
import { useAuthContext } from '../../../components/authContext';
import { ErrorToast } from '../../../components/ErrorToast';

interface ORYXTestParams {
  hardwareId: string;
}

export const ORYXTest = () => {
  const [availableGateways, setAvailableGateways] = useState<GatewayInfo[] | undefined>(undefined);
  const userId = useAuthContext().state.userState?.user.uid;
  const { ORYXGOCollection, isLoading, isError, errorMessage, clearError, getAvailableORYXGOSystems } =
    useHardwareContext();
  const params = useParams<ORYXTestParams>();
  const hardwareId = params.hardwareId;

  useEffect(() => {
    getAvailableORYXGOSystems(userId!);
  }, [params.hardwareId]);

  const hardwareContext = useHardwareContext();

  const [loading, setLoading] = useState(true);

  // const network = hardwareContext.hardwareData[params.hardwareId];
  const backTarget = history.state.state?.from;
  const [connectedGateway, setConnectedGateway] = useState('');

  // log the information from the ORYXGOCollection object based on the params
  useEffect(() => {
    Object.entries(ORYXGOCollection).map(([key, value]) => {
      // const newSocket: GatewaySocket = io(`http://${value!.gateway.socketURL}`, SOCKET_OPTIONS);
      const newSocket: GatewaySocket = io(`http://${value.gateway.hostName}.local:8080`, SOCKET_OPTIONS);

      setAvailableGateways([
        {
          gatewaySocket: newSocket,
          gatewayInfo: value,
          gatewayUUID: key,
        },
      ]);
    });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [ORYXGOCollection]);

  useEffect(() => {
    if (import.meta.env.MODE !== 'production') console.log('connectedGateway: ', connectedGateway);
  }, [connectedGateway]);

  return (
    <IonPage id='main'>
      <ORYXHeader backTarget={backTarget} />
      <IonContent className='ion-padding' id='main' color='light'>
        <IonGrid fixed>
          <IonLoading cssClass='my-custom-class' isOpen={isLoading} message={'Retrieving information...'} />
          {!loading && (
            <>
              {availableGateways!.length < 1
                ? 'No gateways found.'
                : availableGateways!.map((thisSocket) => {
                    const className = () => {
                      if (connectedGateway !== '') {
                        return connectedGateway === thisSocket.gatewayInfo.gateway.hostName ? '' : 'ion-hide';
                      } else {
                        return '';
                      }
                    };

                    return (
                      <IonRow key={thisSocket.gatewayUUID} className={className()}>
                        <GatewayCard
                          socket={thisSocket.gatewaySocket}
                          gatewayUUID={thisSocket.gatewayUUID}
                          gatewayInfo={thisSocket.gatewayInfo}
                          connectedGateway={setConnectedGateway}
                        />
                      </IonRow>
                    );
                  })}
            </>
          )}
        </IonGrid>
      </IonContent>
      <ErrorToast message={isError ? isError.message : ''} clearError={() => clearError()} />
    </IonPage>
  );
};
