/** Component for line graphs with reference lines */
import * as React from 'react';
import { useEffect, useState } from 'react';

import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { colorCS } from '../../../../theme/colors';

type RespectTheSagittalPlaneGraphProps = {
  data: any;
  annotations: any;
};

export const RespectTheSagittalPlaneGraph = ({ data, annotations }: RespectTheSagittalPlaneGraphProps) => {
  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = useState('small');

  useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setWindowSize('large');
      } else {
        setWindowSize('small');
      }
      if (import.meta.env.MODE !== 'production') console.log('width', height);
    }
  }, [width]);

  const options: ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      offsetX: -10,
    },
    xaxis: {
      type: 'numeric',
      labels: {
        style: {
          fontSize: '8px',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value: number) {
          return value.toFixed(2);
        },
        style: {
          fontSize: '8px',
        },
      },
      forceNiceScale: true,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
      },
    },
    grid: {
      show: true,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    stroke: {
      width: [1],
      dashArray: [0],
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '9px',
      markers: {
        width: 8,
        height: 8,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    markers: {
      size: [0],
      strokeWidth: 0,
      showNullDataPoints: false,
    },
    tooltip: {
      enabled: false,
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          borderColor: colorCS,
        },
      ],
      xaxis: [
        {
          x: annotations[1],
          borderColor: '#c2c2c2',
          label: {
            text: 'TO',
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: annotations[2],
          borderColor: '#c2c2c2',
          label: {
            text: 'MSw',
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: annotations[5],
          borderColor: '#c2c2c2',
          label: {
            text: 'TSw',
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: annotations[3],
          borderColor: '#c2c2c2',
          label: {
            text: 'IC',
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: annotations[4],
          borderColor: '#c2c2c2',
          label: {
            text: 'MSt',
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: annotations[0],
          borderColor: '#c2c2c2',
          label: {
            text: 'HO',
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
      ],
    },
  };

  return (
    <>
      <div className='' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <Chart options={options} series={data} type='line' width='100%' height={windowSize === 'small' ? 140 : 225} />
      </div>
    </>
  );
};
