import React from 'react';

import { View } from '@react-pdf/renderer';
import { Table, TableCell, TableRow } from './PDFTableComponents';
import pelvisSagittalMovement from '../../../../assets/icons/kinematicsIcons/pelvisSagittalMovement.png';
import hipSagittalMovement from '../../../../assets/icons/kinematicsIcons/hipSagittalMovement.png';
import hipTransversalMovement from '../../../../assets/icons/kinematicsIcons/hipTransversalMovement.png';
import kneeSagittalMovement from '../../../../assets/icons/kinematicsIcons/kneeSagittalMovement.png';
import kneeCoronalMovement from '../../../../assets/icons/kinematicsIcons/kneeCoronalMovement.png';
import ankleSagittalMovement from '../../../../assets/icons/kinematicsIcons/ankleSagittalMovement.png';

interface PDFRunInfoTableProps {
  keyPerformanceIndicatorData: any;
  romData: any;
  labels: any;
  repetitionTimeData: any;
}

export const PDFSquatInfoTable = ({
  labels,
  keyPerformanceIndicatorData,
  romData,
  repetitionTimeData,
}: PDFRunInfoTableProps) => {
  function Label(labels: string[], kpi: number) {
    return labels[kpi - 1];
  }

  return (
    <View>
      <Table>
        <TableRow>
          <TableCell borderRight rowHeader>
            Repetition time
          </TableCell>
          <TableCell>
            {repetitionTimeData.col0[0].toFixed(2)} (± {repetitionTimeData.col1[0].toFixed(2)}) sec.
          </TableCell>
          <TableCell />

          <TableCell />
        </TableRow>
      </Table>
      <Table>
        <TableRow borderBottom>
          <TableCell borderRight />
          <TableCell borderRight columnHeader>
            Left
          </TableCell>
          <TableCell borderRight columnHeader>
            Right
          </TableCell>
          <TableCell columnHeader>Symmetry</TableCell>
        </TableRow>
        <TableRow backgroundColor={'#f2f2f2'} borderBottom>
          <TableCell borderRight stretch rowHeader>
            Pelvis
          </TableCell>
          <TableCell borderRight>
            {`${romData[0][0].toFixed(1) + '°'}
            ± ${romData[0][1].toFixed(1) + '°'}`}
          </TableCell>
          <TableCell borderRight>
            {`${romData[0][2].toFixed(1) + '°'}
              ± ${romData[0][3].toFixed(1) + '°'}`}
          </TableCell>
          <TableCell>{romData[0][4].toFixed(0) + '%'}</TableCell>
        </TableRow>
        <TableRow backgroundColor={'#f2f2f2'} borderBottom>
          <TableCell borderRight stretch rowHeader>
            Hip
          </TableCell>
          <TableCell borderRight>
            {`${romData[1][0].toFixed(1) + '°'}
              ± ${romData[1][1].toFixed(1) + '°'}`}
          </TableCell>
          <TableCell borderRight>
            {`${romData[1][2].toFixed(1) + '°'}
              ± ${romData[1][3].toFixed(1) + '°'}`}
          </TableCell>
          <TableCell>{romData[1][4].toFixed(0) + '%'}</TableCell>
        </TableRow>
        <TableRow borderBottom>
          <TableCell borderRight indent>
            External rotation
          </TableCell>
          <TableCell borderRight>{Label(labels[2], keyPerformanceIndicatorData.col0[2])}</TableCell>
          <TableCell borderRight>{Label(labels[2], keyPerformanceIndicatorData.col1[2])}</TableCell>
          <TableCell />
        </TableRow>
        <TableRow borderBottom>
          <TableCell borderRight indent>
            Internal rotation
          </TableCell>
          <TableCell borderRight>{Label(labels[3], keyPerformanceIndicatorData.col0[3])}</TableCell>
          <TableCell borderRight>{Label(labels[3], keyPerformanceIndicatorData.col1[3])}</TableCell>
          <TableCell />
        </TableRow>
        <TableRow backgroundColor={'#f2f2f2'} borderBottom>
          <TableCell borderRight stretch rowHeader>
            Knee
          </TableCell>
          <TableCell borderRight>
            {`${romData[2][0].toFixed(1) + '°'}
              ± ${romData[2][1].toFixed(1) + '°'}`}
          </TableCell>
          <TableCell borderRight>
            {`${romData[2][2].toFixed(1) + '°'}
              ± ${romData[2][3].toFixed(1) + '°'}`}
          </TableCell>
          <TableCell>{romData[2][4].toFixed(0) + '%'}</TableCell>
        </TableRow>
        <TableRow borderBottom>
          <TableCell borderRight indent>
            Varus / Valgus
          </TableCell>
          <TableCell borderRight>{Label(labels[4], keyPerformanceIndicatorData.col0[4])}</TableCell>
          <TableCell borderRight>{Label(labels[4], keyPerformanceIndicatorData.col1[4])}</TableCell>
          <TableCell />
        </TableRow>
        <TableRow backgroundColor={'#f2f2f2'} borderBottom>
          <TableCell borderRight stretch rowHeader>
            Ankle
          </TableCell>
          <TableCell borderRight>
            {`${romData[3][0].toFixed(1) + '°'}
              ± ${romData[3][1].toFixed(1) + '°'}`}
          </TableCell>
          <TableCell borderRight>
            {`${romData[3][2].toFixed(1) + '°'}
              ± ${romData[3][3].toFixed(1) + '°'}`}
          </TableCell>
          <TableCell>{romData[3][4].toFixed(0) + '%'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell borderRight indent>
            Early heel raise
          </TableCell>
          <TableCell borderRight>{Label(labels[0], keyPerformanceIndicatorData.col0[0])}</TableCell>
          <TableCell borderRight>{Label(labels[0], keyPerformanceIndicatorData.col1[0])}</TableCell>
          <TableCell />
        </TableRow>
      </Table>

      <Table>
        <TableRow borderBottom>
          <TableCell borderRight columnHeader>
            Pelvis movement
          </TableCell>
          <TableCell borderRight columnHeader>
            Hip Movement
          </TableCell>
          <TableCell borderRight columnHeader>
            Knee Movement
          </TableCell>
          <TableCell columnHeader>Ankle Movement</TableCell>
        </TableRow>
        <TableRow borderBottom>
          <TableCell borderRight image src={pelvisSagittalMovement} />

          <TableCell borderRight image src={hipSagittalMovement} />

          <TableCell borderRight image src={kneeSagittalMovement} />

          <TableCell image src={ankleSagittalMovement} />
        </TableRow>
        <TableRow>
          <TableCell borderRight stretch />

          <TableCell borderRight image src={hipTransversalMovement} />

          <TableCell borderRight image src={kneeCoronalMovement} />

          <TableCell />
        </TableRow>
      </Table>
    </View>
  );
};
