/** Models for capturing the logged in user and role */
import firebase from 'firebase/compat/app';

export type UserId = string;

export type User = firebase.User;

export enum UserRole {
  ADMIN = 'admin',
  CLIENT = 'client',
  NONE = '',
  ORYXDEV = 'ORYX_Dev',
  ORYXADMIN = 'ORYX_Admin',
}

export type UserState = {
  user: User;
  role: UserRole;
};
