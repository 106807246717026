/** Bar at the bottom of a page to show errors */
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonPage, IonRow } from '@ionic/react';
import React from 'react';
import errorComponent from '../assets/icons/errorComponentIcon.svg';
import { ORYXHeader } from './menuItems/Header';
import { arrowBack } from 'ionicons/icons';
import { useHistory } from 'react-router';

export const NotFoundPage = () => {
  const history = useHistory();
  return (
    <>
      <IonPage id='main'>
        <ORYXHeader backTarget='/dashboard' />
        <IonContent className='ion-padding' id='main' fullscreen color='light'>
          <IonGrid className='fullHeight'>
            <IonRow className='ion-align-items-stretch fullHeight ion-padding' style={{ padding: '40px' }}>
              <IonRow className='fullWidth ion-align-items-end '>
                <IonCol className='' size='6'>
                  <h5>
                    Looks like you&apos;ve found the doorway to the great nothingness. <br />
                  </h5>
                  <p>Sorry about that!</p>
                  <IonRow className='ion-justify-content-center ion-margin-vertical' style={{ marginTop: '50px' }}>
                    <IonButton
                      className='primary-red'
                      onClick={() => {
                        history.push('/dashboard');
                      }}
                    >
                      <IonIcon icon={arrowBack} slot='start' />
                      Return to dashboard
                    </IonButton>
                  </IonRow>
                </IonCol>
              </IonRow>
              <IonRow className='fullWidth ion-justify-content-end' style={{ marginTop: '-150px' }}>
                <IonCol size='6' className=''>
                  <IonImg src={errorComponent} />
                </IonCol>
              </IonRow>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export const ErrorComponent = () => {
  const history = useHistory();
  return (
    <>
      <IonGrid className=''>
        <IonRow className='ion-align-items-stretch  ion-padding' style={{ padding: '40px' }}>
          <IonRow className='fullWidth ion-align-items-end '>
            <IonCol className='' size='6'>
              <h5>
                Looks like you&apos;ve found the doorway to the great nothingness. <br />
              </h5>
              <p>Sorry about that!</p>
              <IonRow className='ion-justify-content-center ion-margin-vertical' style={{ marginTop: '50px' }}>
                <IonButton
                  className='primary-red'
                  onClick={() => {
                    history.push('/dashboard');
                  }}
                >
                  <IonIcon icon={arrowBack} slot='start' />
                  Return to dashboard
                </IonButton>
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow className='fullWidth ion-justify-content-end' style={{ marginTop: '-150px' }}>
            <IonCol size='6' className=''>
              <IonImg src={errorComponent} />
            </IonCol>
          </IonRow>
        </IonRow>
      </IonGrid>
    </>
  );
};
