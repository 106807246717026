import React from 'react';

import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  table: {
    display: 'flex',
    width: 'auto',
    border: 1,
    borderColor: '#E2E2E2',
    borderRadius: 4,
    borderStyle: 'solid',
    marginVertical: 10,
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  borderBottom: {
    flexDirection: 'row',
    borderBottom: 1,
    borderBottomColor: '#E2E2E2',
    borderBottomStyle: 'solid',
    alignItems: 'center',
  },
  tableCol: {
    flex: 1,
    // paddingVertical: '4px',
  },
  rowHeader: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    color: '#767676',
    marginLeft: 10,
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  rowHeader_Indent: {
    fontSize: 10,
    fontStyle: 'italic',
    fontFamily: 'Montserrat',
    color: '#767676',
    marginLeft: 20,
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  columnHeader: {
    fontSize: 10,
    fontFamily: 'Montserrat',
    color: '#2066FA',
    marginLeft: 10,
    textAlign: 'center',
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  dataCell: {
    fontSize: 10,
    fontFamily: 'Montserrat',
    color: '#767676',
    textAlign: 'center',
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  borderRight: {
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: '#E2E2E2',
    borderRightStyle: 'solid',
  },
  tableImage: {
    width: '80px',
    height: 'auto',
    alignSelf: 'center',
  },
});

export const Table = ({ children }: { children: React.ReactNode }) => <View style={styles.table}>{children}</View>;

interface TableHeaderProps {
  children: React.ReactNode;
  backgroundColor?: string;
  borderBottom?: boolean;
}

export const TableRow = ({ children, backgroundColor = '', borderBottom = false }: TableHeaderProps) => (
  <View style={[{ backgroundColor }, borderBottom ? styles.borderBottom : styles.tableRow]}>{children}</View>
);

interface TableCellProps {
  children?: React.ReactNode;
  borderRight?: boolean;
  stretch?: boolean;
  columnHeader?: boolean;
  rowHeader?: boolean;
  indent?: boolean;
  image?: boolean;
  src?: string;
  custom?: boolean;
}

/**
 * Represents a table cell component.
 *
 * @param {TableCellProps} props - The props for the TableCell component.
 * @param {ReactNode} props.children - The content of the table cell.
 * @param {boolean} [props.borderRight=false] - Whether to display a right border for the cell.
 * @param {boolean} [props.stretch=false] - Whether the cell should stretch to fill the available space.
 * @param {boolean} [props.columnHeader=false] - Whether the cell is a column header.
 * @param {boolean} [props.rowHeader=false] - Whether the cell is a row header.
 * @param {boolean} [props.indent=false] - Whether the cell should be indented.
 * @param {boolean} [props.image=false] - Whether the cell contains an image.
 * @param {string} [props.src=''] - The source URL of the image.
 * @returns {JSX.Element} The rendered TableCell component.
 */
export const TableCell = ({
  children,
  borderRight = false,
  stretch = false,
  columnHeader = false,
  rowHeader = false,
  indent = false,
  image = false,
  src = '',
  custom = false,
}: TableCellProps): JSX.Element => {
  const isHeader = columnHeader || rowHeader || indent;
  return (
    <View
      style={[
        borderRight ? styles.borderRight : styles.tableCol,
        stretch ? { alignSelf: 'stretch' } : { alignSelf: 'auto' },
      ]}
    >
      {!custom && image && src && <Image style={styles.tableImage} src={src} />}
      {!custom && columnHeader && <Text style={styles.columnHeader}>{children}</Text>}
      {!custom && rowHeader && <Text style={styles.rowHeader}>{children}</Text>}
      {!custom && indent && <Text style={styles.rowHeader_Indent}>{children}</Text>}
      {!custom && !isHeader && !image && <Text style={styles.dataCell}>{children}</Text>}
      {custom ? children : null}
    </View>
  );
};
