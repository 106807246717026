import { GraphSeriesData, KinematicYAxis } from '../../../model/reportV2.model';

/**
 * Calculates the minimum and maximum values of the y-axis based on the provided data.
 * @param data - The data used to calculate the y-axis values.
 * @returns An object containing the minimum value (yMin), maximum value (yMax),
 *          range (yRange), and tick amount (tickAmount) of the y-axis.
 */
export function createKinematicYAxis(data: GraphSeriesData[]): KinematicYAxis {
  let yMin = 0;
  let yMax = 0;
  data.forEach((series: any) => {
    series.data.forEach((point: any) => {
      if (point.y < yMin) {
        yMin = Math.round(point.y);
      }
      if (point.y > yMax) {
        yMax = Math.round(point.y);
      }
    });
  });
  yMin = yMin - 10;
  yMax = yMax + 10;

  const yRange = yMax - yMin;
  const number = Math.round(yRange / 10 + 2);
  const tickAmount = number > 5 ? 5 : number;

  return { yMin, yMax, yRange, tickAmount };
}
