import './SensorBatteryInd.css';

interface SensorBatteryIndProps {
  batteryLevel: number;
  charging: boolean;
  enabled: boolean;
}

export const SensorBatteryInd = ({ batteryLevel, charging, enabled }: SensorBatteryIndProps) => {
  if (charging) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className={enabled ? 'ionicon batteryIndicator' : 'ionicon batteryIndicator_notenabled'}
        viewBox='-150 100 768 450'
      >
        <path d='M48 322.3V189.7A29.74 29.74 0 0177.7 160h137.44l24.4-32H77.7A61.77 61.77 0 0016 189.7v132.6A61.77 61.77 0 0077.7 384h96.85a22.57 22.57 0 01.26-7.32l.15-.75.21-.73 6.5-23.2H77.7A29.74 29.74 0 0148 322.3zM386.3 128h-98.64a22.69 22.69 0 01-.27 7.2l-.15.74-.21.73-6.54 23.33H386.3a29.74 29.74 0 0129.7 29.7v132.6a29.74 29.74 0 01-29.7 29.7H247l-24.42 32H386.3a61.77 61.77 0 0061.7-61.7V189.7a61.77 61.77 0 00-61.7-61.7z' />
        <path d='M162.65 294.16a24.37 24.37 0 01-21.56-13 25 25 0 011.42-25.83l.31-.46.33-.44L197.62 183H89.69a20 20 0 00-20 20v106a20 20 0 0020 20h98.42l9.78-34.86z' />
        <path d='M276.07 280.89l27.07-35.49a5.2 5.2 0 00.77-1.91 5 5 0 00.08-.66 5 5 0 00-.08-1.29 5.11 5.11 0 00-.68-1.75 4.76 4.76 0 00-.78-.95 3.48 3.48 0 00-.48-.38 4 4 0 00-1.11-.55 4.28 4.28 0 00-1.31-.2h-61.62l12.12-43.21 3.23-11.5 6.21-22.16.51-1.84 7.79-27.76a3.51 3.51 0 00.05-.55v-.16c0-.05 0-.26-.05-.38s0-.09 0-.14a2.2 2.2 0 00-.17-.45 3.77 3.77 0 00-.26-.39l-.09-.1a2.73 2.73 0 00-.25-.23l-.1-.08a3.14 3.14 0 00-.39-.24 2 2 0 00-.41-.14H265.53a2.3 2.3 0 00-.45 0 1.9 1.9 0 00-.42.15l-.13.07-.3.21-.11.1a2.4 2.4 0 00-.36.41l-18 23.63-13.14 17.22-9.85 12.83-63.71 83.55a5.72 5.72 0 00-.44.8 4.78 4.78 0 00-.35 1.09 4.7 4.7 0 00-.08 1.29 4.86 4.86 0 002 3.71 4.74 4.74 0 00.54.31 4.31 4.31 0 001.89.43h61.62L194.42 380.6a3.64 3.64 0 000 .56v.15a2.32 2.32 0 00.06.38.58.58 0 000 .14 2.2 2.2 0 00.17.45 3.62 3.62 0 00.26.38l.09.1.25.24a.39.39 0 01.1.08 2.22 2.22 0 00.39.23 2.83 2.83 0 00.41.14h.13a1.86 1.86 0 00.33 0h.13a2.32 2.32 0 00.45-.06 2.05 2.05 0 00.41-.16l.13-.07.3-.21.11-.09a2.4 2.4 0 00.36-.41L221.82 352l17.53-23z' />
        <path d='M319.5 256.93l-.46.6L264.51 329h109.8a20 20 0 0020-20V203a20 20 0 00-20-20H274.05l-9.74 34.73h35.24A24.35 24.35 0 01321 230.5a25.21 25.21 0 01-1 25.79zM480 202.67a16 16 0 00-16 16v74.66a16 16 0 0032 0v-74.66a16 16 0 00-16-16z' />
      </svg>
    );
  } else {
    return (
      <svg
        className={enabled ? 'ionicon batteryIndicator' : 'ionicon batteryIndicator_notenabled'}
        viewBox='-150 100 768 450'
      >
        <rect
          x='32'
          y='144'
          width='400'
          height='224'
          rx='45.7'
          ry='45.7'
          fill='none'
          stroke='black'
          strokeLinecap='square'
          strokeMiterlimit='10'
          strokeWidth='24'
        />
        <rect
          x='85.69'
          y='198.93'
          width={(292.63 / 100) * batteryLevel}
          height='114.14'
          rx='4'
          ry='4'
          stroke='black'
          strokeLinecap='square'
          strokeMiterlimit='10'
          strokeWidth='24'
        />
        <path
          fill='none'
          stroke='black'
          strokeLinecap='round'
          strokeMiterlimit='10'
          strokeWidth='24'
          d='M480 218.67v74.66'
        />
      </svg>
    );
  }
};
