import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonItem,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
} from '@ionic/react';
import * as React from 'react';
import { ORYXHeader } from '../components/menuItems/Header';
import './Dashboard.css';
import Walking from '../assets/icons/Walking.png';
import Running from '../assets/icons/Running.png';
import Cycling from '../assets/icons/Cycling.png';
import squatter from '../assets/icons/Squat.png';
import slsquatter from '../assets/icons/SingleLegSquat.png';
import { useReportContext } from '../components/reportContext';
import { useEffect, useState } from 'react';
import { ReportInfo, ReportInfoDict, ReportType, UserInfo } from '../model';
import { useHistory } from 'react-router';
import { useAuthContext } from '../components/authContext';

interface smallDashboardItem {
  id: number;
  icon: string;
  metric: number;
  title: string;
  key: string;
  reportNumber: number;
  isLoading?: boolean;
}

const smallDashboardItems: smallDashboardItem[] = [
  {
    id: 1,
    icon: Walking,
    metric: 0,
    title: 'Walking tests performed',
    key: 'Walk',
    reportNumber: 1,
  },
  {
    id: 2,
    icon: Running,
    metric: 0,
    title: 'Running tests performed',
    key: 'Run',
    reportNumber: 2,
  },
  {
    id: 3,
    icon: Cycling,
    metric: 0,
    title: 'Cycling tests performed',
    key: 'Cycling',
    reportNumber: 999,
  },
  {
    id: 4,
    icon: squatter,
    metric: 0,
    title: 'Squats performed',
    key: 'Squat',
    reportNumber: 3,
  },
  {
    id: 5,
    icon: slsquatter,
    metric: 0,
    title: 'Single leg squats performed',
    key: 'SLSquat',
    reportNumber: 4,
  },
];

/** Map over reports sorted by uploadTimestamp */
const sortReportInfos = (reportInfoDict: ReportInfoDict): ReportInfo[] => {
  // Create array of tuples (id, reportInfo)
  const items: [string, ReportInfo][] = Object.keys(reportInfoDict).map((id: string) => {
    return [id, reportInfoDict[id]];
  });

  // Sort the array based on the second element (reportInfo.uploadTimestamp)
  const reportsSorted = items.sort((first, second) => {
    const a = second[1].uploadTimestamp.getTime();
    const b = first[1].uploadTimestamp.getTime();
    return a - b;
  });

  return reportsSorted.map(([_id, reportInfo]) => reportInfo);
};

const DashboardCard_Small = ({ icon, metric, title, reportNumber: testName, isLoading }: smallDashboardItem) => {
  const history = useHistory();

  return (
    <IonCard
      className='full_height ion-no-margin dashboard_Card_Small'
      onClick={() => {
        history.push('/reports', { filterOption: testName });
      }}
    >
      <IonCardContent>
        <IonRow>
          <IonCol size='5' className='ion-align-items-center'>
            <IonImg src={icon} alt='' className='dashboard_Card_Icon' />
          </IonCol>
          <IonCol size='7' className='dashboard_Card_Metric ion-align-items-center ion-align-self-center'>
            {isLoading ? <IonSpinner name='bubbles' color='primary' /> : <>{metric}</>}
          </IonCol>
        </IonRow>

        <IonCardSubtitle className='dashboard_Card_Title'>{title}</IonCardSubtitle>
      </IonCardContent>
    </IonCard>
  );
};
export const Dashboard: React.FC = () => {
  const user = useAuthContext().state.userState?.user;
  const role = useAuthContext().state.userState?.role;
  const { reportInfoDict, isLoading, isError } = useReportContext();
  const [numberOfWalkReports, setNumberOfWalkReports] = useState<number>(0); // reportType 1
  const [numberOfRunReports, setNumberOfRunReports] = useState<number>(0); // reportType 2
  const [numberOfSquatsReports, setNumberOfSquatsReports] = useState<number>(0); // reportType 3
  const [numberOfSLSquatsReports, setNumberOfSLSquatsReports] = useState<number>(0); // reportType 4

  useEffect(() => {
    if (!isError) {
      console.log('counting reports!');
      Object.entries(reportInfoDict).map(([key, val]) => {
        switch (val.reportType) {
          case ReportType.WALK:
            setNumberOfWalkReports((previousValue) => {
              return previousValue + 1;
            });
            break;
          case ReportType.RUN:
            setNumberOfRunReports((previousValue) => {
              return previousValue + 1;
            });
            break;
          case ReportType.SQUAT:
            setNumberOfSquatsReports((previousValue) => {
              return previousValue + 1;
            });
            break;
          case ReportType.SLSQUAT:
            setNumberOfSLSquatsReports((previousValue) => {
              return previousValue + 1;
            });
            break;
          default:
            break;
        }
      });
      return () => {
        setNumberOfWalkReports(0);
        setNumberOfRunReports(0);
        setNumberOfSquatsReports(0);
        setNumberOfSLSquatsReports(0);
      };
    }
  }, [isLoading, isError, reportInfoDict]);

  return (
    <IonPage id='main'>
      <ORYXHeader />
      <IonContent className='ion-padding' id='main' color='light'>
        <IonGrid fixed>
          <IonRow>
            <IonCol size='12' class='ion-justify-content-center' className='dashboard_Title'>
              <IonTitle>Welcome back!</IonTitle>
            </IonCol>
          </IonRow>
          <IonRow className='ion-align-items-center ion-justify-content-center'>
            {smallDashboardItems.map((smallDashboardItem) => {
              if (smallDashboardItem.key == 'Walk') {
                return (
                  <IonCol size='4' className='' key={smallDashboardItem.key}>
                    <DashboardCard_Small
                      key={smallDashboardItem.key}
                      id={smallDashboardItem.id}
                      icon={smallDashboardItem.icon}
                      metric={numberOfWalkReports}
                      // metric={reportCounts[smallDashboardItem.key.toLowerCase()]}
                      title={smallDashboardItem.title}
                      reportNumber={smallDashboardItem.reportNumber}
                      isLoading={isLoading === 'reportInfo' ? true : false}
                    />
                  </IonCol>
                );
              } else if (smallDashboardItem.key == 'Run') {
                return (
                  <IonCol size='4' className='' key={smallDashboardItem.key}>
                    <DashboardCard_Small
                      key={smallDashboardItem.key}
                      id={smallDashboardItem.id}
                      icon={smallDashboardItem.icon}
                      metric={numberOfRunReports}
                      // metric={reportCounts[smallDashboardItem.key.toLowerCase()]}
                      title={smallDashboardItem.title}
                      reportNumber={smallDashboardItem.reportNumber}
                      isLoading={isLoading === 'reportInfo' ? true : false}
                    />
                  </IonCol>
                );
              } else if (smallDashboardItem.key == 'Squat') {
                return (
                  <IonCol size='4' className='' key={smallDashboardItem.key}>
                    <DashboardCard_Small
                      key={smallDashboardItem.key}
                      id={smallDashboardItem.id}
                      icon={smallDashboardItem.icon}
                      metric={numberOfSquatsReports}
                      // metric={reportCounts[smallDashboardItem.key.toLowerCase()]}
                      title={smallDashboardItem.title}
                      reportNumber={smallDashboardItem.reportNumber}
                      isLoading={isLoading === 'reportInfo' ? true : false}
                    />
                  </IonCol>
                );
              } else if (smallDashboardItem.key == 'SLSquat') {
                return (
                  <IonCol size='4' className='' key={smallDashboardItem.key}>
                    <DashboardCard_Small
                      key={smallDashboardItem.key}
                      id={smallDashboardItem.id}
                      icon={smallDashboardItem.icon}
                      metric={numberOfSLSquatsReports}
                      // metric={reportCounts[smallDashboardItem.key.toLowerCase()]}
                      title={smallDashboardItem.title}
                      reportNumber={smallDashboardItem.reportNumber}
                      isLoading={isLoading === 'reportInfo' ? true : false}
                    />
                  </IonCol>
                );
              } else if (smallDashboardItem.key == 'Cycling') {
                return (
                  <IonCol size='4' className='' key={smallDashboardItem.key}>
                    <DashboardCard_Small
                      key={smallDashboardItem.key}
                      id={smallDashboardItem.id}
                      icon={smallDashboardItem.icon}
                      metric={0}
                      title={smallDashboardItem.title}
                      reportNumber={smallDashboardItem.reportNumber}
                      isLoading={isLoading === 'reportInfo' ? true : false}
                    />
                  </IonCol>
                );
              }
            })}
          </IonRow>

          <IonRow className='ion-align-items-center ion-justify-content-center'>
            <IonCol size='6'>
              <IonCard className='dashboard_Card'>
                <IonCardHeader mode='md' className='attractorCard_Header'>
                  <IonGrid fixed className='ion-no-padding'>
                    <IonRow className='ion-no-padding ion-align-items-center attractorCard_Header_Row'>
                      <IonCol size='10' className=''>
                        <IonCardTitle className='attractorCard_Title'>Quick Links</IonCardTitle>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardHeader>
                <IonCardContent>
                  <IonGrid>
                    <IonRow className='ion-align-items-center ion-justify-content-center'>
                      <IonCol>
                        <IonItem routerLink='/reports' routerDirection='forward' lines='none' detail={true}>
                          Reports
                        </IonItem>
                        <IonItem routerLink='/upload' routerDirection='forward' lines='none' detail={true}>
                          Upload new file
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </IonCard>
            </IonCol>
            {import.meta.env.MODE === 'development' && (
              <IonCol size='6'>
                <IonCard className='dashboard_Card'>
                  <IonCardHeader mode='md' className='attractorCard_Header'>
                    <IonGrid fixed className='ion-no-padding'>
                      <IonRow className='ion-no-padding ion-align-items-center attractorCard_Header_Row'>
                        <IonCol size='10' className=''>
                          <IonCardTitle className='attractorCard_Title'>Info</IonCardTitle>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonGrid>
                      <IonRow className='ion-align-items-center ion-justify-content-center'>
                        <IonCol>
                          <IonItem lines='none' detail={false}>
                            User: {user?.displayName}
                          </IonItem>
                          <IonItem lines='none' detail={false}>
                            Your Uid: {user?.uid}
                          </IonItem>
                          <IonItem lines='none' detail={false}>
                            Your permissions: {role}
                          </IonItem>
                          <IonItem lines='none' detail={false}>
                            NODE_ENV = {import.meta.env.MODE}
                          </IonItem>
                          <IonItem lines='none' detail={false}>
                            PROJECT ID = {import.meta.env.VITE_FIREBASE_PROJECT_ID}
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
