import { DataPoint } from '../../../model/reportV2.model';

/**
 * Function to flatten the data from the above function to create 1 dataseries (to show all left strides
 * in one color for example)
 */
export function flattenData(data: any[]): DataPoint[] {
  return data.reduce((acc: any, curr: any) => {
    return [...acc, ...curr];
  }, []);
}
