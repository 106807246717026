import {
  calculateMean,
  combinePointsOfInterest,
  combinePointsOfInterestToScatter,
  flattenData,
  toJointCouplingData,
  toScissorsData_V2,
} from '.';
import { DataPoint, JointCouplingSeriesData, RearPendulumGraphData } from '../../../model/reportV2.model';
import { colorCS, colorSS } from '../../../theme/colors';

export function calculateRearPendulumGraphData(data: any): RearPendulumGraphData {
  const rearPendulumDataX_Left = data.reportId.rearPendulumXPositions_Left;
  const rearPendulumDataZ_Left = data.reportId.rearPendulumZPositions_Left;
  const rearPendulumDataX_Right = data.reportId.rearPendulumXPositions_Right;
  const rearPendulumDataZ_Right = data.reportId.rearPendulumZPositions_Right;
  const pointsOfInterest_Left = data.reportId.rearPendulumPointsOfInterest_Left;
  const pointsOfInterest_Right = data.reportId.rearPendulumPointsOfInterest_Right;

  // Rear Pendulum Left?
  const rearPendulumData_Left = toJointCouplingData(rearPendulumDataZ_Left, rearPendulumDataX_Left);
  // For the label in the graph
  const rearPendulumData_ToeOff_Left = combinePointsOfInterest(pointsOfInterest_Left, 0, 1);
  const rearPendulum_ToeOff_Left: DataPoint = calculateMean(rearPendulumData_ToeOff_Left);
  const toeOffs_Left = combinePointsOfInterestToScatter(pointsOfInterest_Left, 0, 1);
  const midSwings_Left = combinePointsOfInterestToScatter(pointsOfInterest_Left, 2, 3);
  const leftToeOffsMoments = toScissorsData_V2(toeOffs_Left);
  const leftMidSwingsMoments = toScissorsData_V2(midSwings_Left);
  const seriesLeftToeOff = [{ type: 'scatter', name: 'TO', data: flattenData(leftToeOffsMoments), color: colorCS }];

  const seriesLeftMidSwing = [
    { type: 'scatter', name: 'MSw', data: flattenData(leftMidSwingsMoments), color: colorSS },
  ];
  const rearPendulumData_MidSwing_Left = combinePointsOfInterest(pointsOfInterest_Left, 2, 3);
  const rearPendulum_MidSwing_Left = calculateMean(rearPendulumData_MidSwing_Left);
  const rearPendulumVariance_ToeOff_Left = data.reportId.variance.col0[0].toFixed(5);
  const rearPendulumVariance_MidSwing_Left = data.reportId.variance.col0[1].toFixed(5);

  const rearPendulumGraphData_Left: JointCouplingSeriesData[] = [
    ...seriesLeftToeOff,
    ...seriesLeftMidSwing,
    ...rearPendulumData_Left,
  ];

  const rearPendulumData_Right = toJointCouplingData(rearPendulumDataZ_Right, rearPendulumDataX_Right);

  // For the label in the graph
  const rearPendulumData_ToeOff_Right = combinePointsOfInterest(pointsOfInterest_Right, 0, 1);

  const rearPendulum_ToeOff_Right = calculateMean(rearPendulumData_ToeOff_Right);

  const toeOffs_Right = combinePointsOfInterestToScatter(pointsOfInterest_Right, 0, 1);
  const midSwings_Right = combinePointsOfInterestToScatter(pointsOfInterest_Right, 2, 3);
  const rightToeOffsMoments = toScissorsData_V2(toeOffs_Right);
  const rightMidSwingsMoments = toScissorsData_V2(midSwings_Right);
  const seriesRightToeOff = [{ type: 'scatter', name: 'TO', data: flattenData(rightToeOffsMoments), color: colorCS }];

  const seriesRightMidSwing = [
    { type: 'scatter', name: 'MSw', data: flattenData(rightMidSwingsMoments), color: colorSS },
  ];

  // For the label in the graph
  const rearPendulumData_MidSwing_Right = combinePointsOfInterest(pointsOfInterest_Right, 2, 3);
  const rearPendulum_MidSwing_Right = calculateMean(rearPendulumData_MidSwing_Right);

  // Variance label
  const rearPendulumVariance_ToeOff_Right: number = data.reportId.variance.col1[0].toFixed(5);
  const rearPendulumVariance_MidSwing_Right: number = data.reportId.variance.col1[1].toFixed(5);

  const rearPendulumGraphData_Right = [...seriesRightToeOff, ...seriesRightMidSwing, ...rearPendulumData_Right];

  const rearPendulumStance_Left: JointCouplingSeriesData = {
    type: 'line',
    name: 'Stance',
    data: [
      {
        x: data.reportId.rearPendulumPIs.col0[1],
        y: 0.025,
      },
      {
        x: data.reportId.rearPendulumPIs.col0[2],
        y: 0.025,
      },
    ],
  };
  const rearPendulumStance_Right: JointCouplingSeriesData = {
    type: 'line',
    name: 'Stance',
    data: [
      {
        x: data?.reportId.rearPendulumPIs.col1[1],
        y: 0.025,
      },
      {
        x: data?.reportId.rearPendulumPIs.col1[2],
        y: 0.025,
      },
    ],
  };

  const rearPendulumZoomModalContent = {
    rearPendulumGraphData_Left,
    rearPendulum_ToeOff_Left,
    rearPendulum_MidSwing_Left,
    rearPendulumVariance_ToeOff_Left,
    rearPendulumVariance_MidSwing_Left,
    rearPendulumGraphData_Right,
    rearPendulum_ToeOff_Right,
    rearPendulum_MidSwing_Right,
    rearPendulumVariance_ToeOff_Right,
    rearPendulumVariance_MidSwing_Right,
    rearPendulumStance_Left,
    rearPendulumStance_Right,
  };

  return {
    rearPendulumGraphData_Left,
    rearPendulum_ToeOff_Left,
    rearPendulum_MidSwing_Left,
    rearPendulumStance_Left,
    rearPendulumVariance_ToeOff_Left,
    rearPendulumVariance_MidSwing_Left,
    rearPendulumGraphData_Right,
    rearPendulum_ToeOff_Right,
    rearPendulum_MidSwing_Right,
    rearPendulumStance_Right,
    rearPendulumVariance_ToeOff_Right,
    rearPendulumVariance_MidSwing_Right,
    rearPendulumZoomModalContent,
  };
}
