/** Transpose report data entry from a dict of colId->row to row of cols matrix
 * Note this relies on sorting the column Ids by name, which works fine
 * as long as these are col0, col1, etc.
 * @param data - Entry from the report data, e.g., report.data.kinematicsData
 */

// Disabling next line because the fix for it is breaking so far, and it probably has a reason to be of type 'any'
// eslint-disable-next-line
export function transpose(data: any): number[][] {
  const f = () => {
    return data.col0.map((_value: number, rowIdx: number) => {
      return Object.keys(data)
        .sort()
        .map((colId: string) => {
          return data[colId][rowIdx];
        });
    });
  };
  return f();
}
