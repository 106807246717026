/** The Run Kinematics report tab */
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonModal,
  IonPage,
  IonPopover,
  IonRow,
  IonText,
  IonToggle,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { VariabilityGraphLegenda, VariabilitySettingsPopoverContent } from '../components/CustomGraphComponents';

import '../components/Graphs/graphCard.css';
import { VariabilityOverviewGraph } from '../components/Graphs';
import { VariabilityModalContent } from '../components/Modals';
import ORYX_MagnifyingGlassIcon from '../../../assets/icons/ORYX_MagnifyingGlassIcon.svg';
import { walkVariabilityData } from '../../../model/reportV2.model';
import { useState } from 'react';

type AttractorsReportProps = RouteComponentProps & {
  tabData: walkVariabilityData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const WalkVariabilityReportTab = ({ tabData }: AttractorsReportProps) => {
  const [showAllStrides, setShowAllStrides] = useState<boolean>(false);

  const seriesHip = React.useMemo(() => {
    return showAllStrides ? tabData.allStrides.Pelvis : tabData.mean.Pelvis;
  }, [tabData, showAllStrides]);
  const seriesKnee = React.useMemo(() => {
    return showAllStrides ? tabData.allStrides.Knee : tabData.mean.Knee;
  }, [tabData, showAllStrides]);
  const seriesAnkle = React.useMemo(() => {
    return showAllStrides ? tabData.allStrides.Ankle : tabData.mean.Ankle;
  }, [tabData, showAllStrides]);

  const [showSettings, setShowSettings] = useState({
    show: false,
    event: undefined,
  });

  const [showMoreInfoModal, setShowMoreInfoModal] = useState({
    show: false,
    joint: '',
  });

  const toggleShowAllStrides = () => {
    setShowAllStrides(!showAllStrides);
    localStorage.setItem('showAllStridesVariability', !showAllStrides ? 'true' : 'false');
  };

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <>
        <IonPopover
          event={showSettings.event}
          isOpen={showSettings.show}
          show-backdrop={false}
          onDidDismiss={() => setShowSettings({ show: false, event: undefined })}
          mode='ios'
        >
          <VariabilitySettingsPopoverContent
            setShowAllStrides={setShowAllStrides}
            showAllStrides={showAllStrides}
            setShowSettings={setShowSettings}
          />
        </IonPopover>

        <IonModal
          isOpen={showMoreInfoModal.show}
          onDidDismiss={() => setShowMoreInfoModal({ show: false, joint: '' })}
          css-class='ORYX_Modal_Large'
        >
          {showMoreInfoModal.joint === 'Hip' && (
            <VariabilityModalContent
              crp_Left={tabData.modalData.Pelvis.Left.crpData}
              crp_Right={tabData.modalData.Pelvis.Right.crpData}
              coordinativeVariabilityData_Left={tabData.modalData.Pelvis.Left.coordinativeVariabilityData}
              coordinativeVariabilityData_Right={tabData.modalData.Pelvis.Right.coordinativeVariabilityData}
              setShowMoreInfoModal={setShowMoreInfoModal}
              graphAnnotations_Left={tabData.graphAnnotationsHipAnkle_Left}
              graphAnnotations_Right={tabData.graphAnnotationsHipAnkle_Right}
              annotations={true}
            />
          )}
          {showMoreInfoModal.joint === 'Knee' && (
            <VariabilityModalContent
              crp_Left={tabData.modalData.Knee.Left.crpData}
              crp_Right={tabData.modalData.Knee.Right.crpData}
              coordinativeVariabilityData_Left={tabData.modalData.Knee.Left.coordinativeVariabilityData}
              coordinativeVariabilityData_Right={tabData.modalData.Knee.Right.coordinativeVariabilityData}
              setShowMoreInfoModal={setShowMoreInfoModal}
              annotations={true}
              graphAnnotations_Left={tabData.graphAnnotationsKnee_Left}
              graphAnnotations_Right={tabData.graphAnnotationsKnee_Right}
            />
          )}
          {showMoreInfoModal.joint === 'Ankle' && (
            <VariabilityModalContent
              crp_Left={tabData.modalData.Ankle.Left.crpData}
              crp_Right={tabData.modalData.Ankle.Right.crpData}
              coordinativeVariabilityData_Left={tabData.modalData.Ankle.Left.coordinativeVariabilityData}
              coordinativeVariabilityData_Right={tabData.modalData.Ankle.Right.coordinativeVariabilityData}
              setShowMoreInfoModal={setShowMoreInfoModal}
              annotations={true}
              graphAnnotations_Left={tabData.graphAnnotationsHipAnkle_Left}
              graphAnnotations_Right={tabData.graphAnnotationsHipAnkle_Right}
            />
          )}
        </IonModal>

        <IonPage>
          <IonContent className='ion-padding' color='light'>
            <IonGrid fixed>
              <IonRow className='ion-align-items-center ion-justify-content-center third_height'>
                <IonCol size='12' className='ion-align-self-stretch'>
                  <IonCard className='ORYX_GraphCard'>
                    <IonRow className='ion-no-padding ion-align-items-center ion-justify-content-evenly fullHeight'>
                      <IonCol size='2' className='ion-align-self-stretch'>
                        <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                          <IonCol size='7' className='ion-no-padding '>
                            <p className='ORYX_GraphTitle_LeftAligned'>Hip</p>
                          </IonCol>

                          <IonCol size='5' className='ion-no-padding'>
                            <IonButtons className='ion-justify-content-end'>
                              <IonButton
                                slot='icon-only'
                                fill='clear'
                                className='variabilityCard_Header_Buttons'
                                onClick={() => setShowMoreInfoModal({ show: true, joint: 'Hip' })}
                              >
                                <IonIcon icon={ORYX_MagnifyingGlassIcon} />
                              </IonButton>
                            </IonButtons>
                          </IonCol>
                        </IonRow>
                        <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '75%' }}>
                          <VariabilityGraphLegenda legend={tabData.pelvisLegend} />
                        </IonRow>
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding ion-align-items-center ion-align-self-stretch'>
                        <VariabilityOverviewGraph
                          crpData={seriesHip.Left.crpData}
                          coordinativeVariabilityData={seriesHip.Left.coordinativeVariabilityData}
                          graphAnnotations={tabData.graphAnnotationsHipAnkle_Left}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding ion-align-self-stretch'>
                        <VariabilityOverviewGraph
                          crpData={seriesHip.Right.crpData}
                          coordinativeVariabilityData={seriesHip.Right.coordinativeVariabilityData}
                          graphAnnotations={tabData.graphAnnotationsHipAnkle_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
              </IonRow>

              <IonRow className='ion-align-items-center ion-justify-content-center third_height'>
                <IonCol size='12' className='ion-align-self-stretch'>
                  <IonCard className='ORYX_GraphCard'>
                    <IonRow className='ion-no-padding ion-align-items-center ion-justify-content-evenly fullHeight'>
                      <IonCol size='2' className='ion-align-self-stretch'>
                        <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                          <IonCol size='7' className='ion-no-padding'>
                            <p className='ORYX_GraphTitle_LeftAligned'>Knee</p>
                          </IonCol>
                          <IonCol size='5' className='ion-no-padding'>
                            <IonButtons className='ion-justify-content-end'>
                              <IonButton
                                slot='icon-only'
                                fill='clear'
                                className='variabilityCard_Header_Buttons'
                                onClick={() => setShowMoreInfoModal({ show: true, joint: 'Knee' })}
                              >
                                <IonIcon icon={ORYX_MagnifyingGlassIcon} />
                              </IonButton>
                            </IonButtons>
                          </IonCol>
                        </IonRow>
                        <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '75%' }}>
                          <VariabilityGraphLegenda legend={tabData.kneeLegend} />
                        </IonRow>
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding ion-align-items-center ion-align-self-stretch'>
                        <VariabilityOverviewGraph
                          crpData={seriesKnee.Left.crpData}
                          coordinativeVariabilityData={seriesKnee.Left.coordinativeVariabilityData}
                          graphAnnotations={tabData.graphAnnotationsKnee_Left}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding ion-align-self-stretch'>
                        <VariabilityOverviewGraph
                          crpData={seriesKnee.Right.crpData}
                          coordinativeVariabilityData={seriesKnee.Right.coordinativeVariabilityData}
                          graphAnnotations={tabData.graphAnnotationsKnee_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
              </IonRow>

              <IonRow className='ion-align-items-center ion-justify-content-center third_height'>
                <IonCol size='12' className='ion-align-self-stretch'>
                  <IonCard className='ORYX_GraphCard'>
                    <IonRow className='ion-no-padding ion-align-items-center ion-justify-content-evenly fullHeight'>
                      <IonCol size='2' className='ion-align-self-stretch'>
                        <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                          <IonCol size='7' className='ion-no-padding'>
                            <p className='ORYX_GraphTitle_LeftAligned'>Ankle</p>
                          </IonCol>
                          <IonCol size='5' className='ion-no-padding'>
                            <IonButtons className='ion-justify-content-end'>
                              <IonButton
                                slot='icon-only'
                                fill='clear'
                                className='variabilityCard_Header_Buttons'
                                onClick={() => setShowMoreInfoModal({ show: true, joint: 'Ankle' })}
                              >
                                <IonIcon icon={ORYX_MagnifyingGlassIcon} />
                              </IonButton>
                            </IonButtons>
                          </IonCol>
                        </IonRow>
                        <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '50%' }}>
                          <VariabilityGraphLegenda legend={tabData.ankleLegend} />
                        </IonRow>
                        <IonRow
                          className='ion-align-items-center'
                          style={{
                            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            marginLeft: '5px',
                            paddingLeft: '5px',
                          }}
                        >
                          <IonCol size='7' className='ion-no-padding' style={{}}>
                            <small>Show all strides</small>
                          </IonCol>
                          <IonCol className='' size='5'>
                            <IonToggle
                              mode='ios'
                              className='icon-custom'
                              color='primary'
                              checked={showAllStrides}
                              onIonChange={(e) => {
                                toggleShowAllStrides();
                              }}
                            ></IonToggle>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding ion-align-items-center ion-align-self-stretch'>
                        <VariabilityOverviewGraph
                          crpData={seriesAnkle.Left.crpData}
                          coordinativeVariabilityData={seriesAnkle.Left.coordinativeVariabilityData}
                          graphAnnotations={tabData.graphAnnotationsHipAnkle_Left}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding ion-align-self-stretch'>
                        <VariabilityOverviewGraph
                          crpData={seriesAnkle.Right.crpData}
                          coordinativeVariabilityData={seriesAnkle.Right.coordinativeVariabilityData}
                          graphAnnotations={tabData.graphAnnotationsHipAnkle_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonPage>
      </>
    );
  }
};
