/** Report detail page, holding tabs for analysis, kinematics, and overview */
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';

import * as React from 'react';

import { Redirect, Route } from 'react-router-dom';

import { useState } from 'react';

// Custom Icons
import ORYX_VariabilityIcon from '../../../../assets/icons/ORYX_VariabilityIcon.svg';
import ORYX_ReportStatusIcon from '../../../../assets/icons/ORYX_ReportStatusIcon.svg';
import ORYX_KinematicsIcon from '../../../../assets/icons/ORYX_KinematicsIcon.svg';
import ORYX_InformationIcon from '../../../../assets/icons/ORYX_InformationIcon.svg';
import ORYX_JointCouplingIcon from '../../../../assets/icons/ORYX_JointCouplingIcon.svg';
import ORYX_MobilityIcon from '../../../../assets/icons/ORYX_MobilityIcon.svg';

//   Custom css
import './index.css';

import {
  WalkAnalysisReportTab,
  WalkKinematicsReportTab,
  WalkVariabilityReportTab,
  WalkJointCouplingReportTab,
  WalkMobilityReportTab,
} from '../../WalkReportV2';
import { OverviewReportTab } from '../../OverviewReportTab';
import { WalkReportData } from '../../../../model/reportV2.model';

interface ReportTab {
  url: string;
  title: string;
  status: string;
  icon: string;
}

const walkTabs: ReportTab[] = [
  {
    title: 'Mobility status',
    url: `mobilitystatus`,
    status: 'active',
    icon: ORYX_MobilityIcon,
  },
  {
    title: 'Analysis',
    url: `analysis`,
    status: 'active',
    icon: ORYX_ReportStatusIcon,
  },
  {
    title: 'Kinematics',
    url: 'kinematics',
    status: 'active',
    icon: ORYX_KinematicsIcon,
  },
  {
    title: 'Variability',
    url: 'variability',
    status: 'active',
    icon: ORYX_VariabilityIcon,
  },
  {
    title: 'Joint coupling',
    url: 'jointcoupling',
    status: 'active',
    icon: ORYX_JointCouplingIcon,
  },
  {
    title: 'Overview',
    url: 'overview',
    status: 'active',
    icon: ORYX_InformationIcon,
  },
];

interface WalkTabsProps {
  id: string;
  reportData: WalkReportData;
}

export const WalkTabBar = ({ id, reportData }: WalkTabsProps) => {
  const [activeWalkTab, setActiveWalkTab] = useState('Mobility status');

  // Set custom actions on the tabs
  const handleTabWillChange = (e: any) => {
    setActiveWalkTab(e.detail.tab);
  };

  return (
    <IonTabs onIonTabsWillChange={handleTabWillChange}>
      <IonRouterOutlet id='main'>
        <Route
          path='/reportsV2/Walk/:id/mobilitystatus'
          render={(props) => {
            return <WalkMobilityReportTab {...props} tabData={reportData.mobilityStatusData} />;
          }}
          exact={true}
        />
        <Route
          path='/reportsV2/Walk/:id/analysis'
          render={(props) => {
            return <WalkAnalysisReportTab {...props} tabData={reportData.analysisData} />;
          }}
          exact={true}
        />
        <Route
          path='/reportsV2/Walk/:id/variability'
          render={(props) => {
            return <WalkVariabilityReportTab {...props} tabData={reportData.walkVariabilityData} />;
          }}
          exact={true}
        />
        <Route
          path='/reportsV2/Walk/:id/kinematics'
          render={(props) => {
            return <WalkKinematicsReportTab {...props} tabData={reportData.walkKinematicData} />;
          }}
          exact={true}
        />
        <Route
          path='/reportsV2/Walk/:id/jointcoupling'
          render={(props) => {
            return <WalkJointCouplingReportTab {...props} tabData={reportData.walkJointCouplingData} />;
          }}
          exact={true}
        />
        <Route
          path='/reportsV2/Walk/:id/overview'
          render={(props) => {
            return (
              <OverviewReportTab {...props} fileInfo={reportData.fileInfo} reportType={reportData.reportType} id={id} />
            );
          }}
          exact={true}
        />

        <Route
          path={`/reportsV2/Walk/${id}`}
          render={() => <Redirect to={`/reportsV2/Walk/${id}/mobilitystatus`} />}
          exact={true}
        />
      </IonRouterOutlet>

      <IonTabBar slot='bottom' mode='ios'>
        {walkTabs.map((tab, _) => {
          let tabClassNames = '';
          if (tab.status === 'disabled') {
            tabClassNames = 'disabled';
          } else if (location.pathname === tab.url) {
            tabClassNames = 'ion-tab-button_selected';
          }
          return (
            <IonTabButton
              mode='ios'
              tab={tab.title}
              href={`/reportsV2/Walk/${id}/${tab.url}`}
              className={activeWalkTab === tab.title ? 'ion-tab-button_selected' : ''}
              key={tab.title}
            >
              <IonIcon icon={tab.icon} />
              <IonLabel>{tab.title}</IonLabel>
            </IonTabButton>
          );
        })}
      </IonTabBar>
    </IonTabs>
  );
};
