import { createContext } from 'react';
import { Socket } from 'socket.io-client';
import { GatewayInstance, ORYXGOInstance, ORYXGOType, ORYXKSType } from '../../../../model';

export type MappableSegment =
  | 'right pelvis'
  | 'left pelvis'
  | 'right thigh'
  | 'left thigh'
  | 'right calf'
  | 'left calf'
  | 'right foot'
  | 'left foot';

export interface SensorObject {
  address: string;
  mappedSegment: MappableSegment;
}

// This is almost  carbon copy of the interface above, but the
// member name is 'segment' instead of 'mappedsegment' and changing it
// on the gateway was too much of a pain. i've tried.
export interface AssignObject {
  address: string;
  segment: MappableSegment;
}

export interface SensorProperties {
  mappedSegment: MappableSegment;
  orientation: { w: number; x: number; y: number; z: number };
  batteryLevel: number;
  charging: boolean;
  dataRate: number;
  connected?: boolean;
}

export interface OrientationMap {
  [address: string]: SensorProperties;
}

export interface OrientationData {
  sensorList: OrientationMap;
}

interface RecordingInfoObject {
  recordingName: string;
  state: 'Idle' | 'Preparing' | 'Recording';
  time: number;
}

export interface RecordingStoppedMessage {
  filename: string;
  recordingTime: number;
}

/**
 * Structure of a pageData object. Seeing as they are sent in regular
 * javascript, this interface will have to account for every
 * member combination possible, hence they are all optional.
 */
export interface PageDataObject {
  version: string;
  language: string;
  UID: string;
  updating?: boolean;
  currentState: string;
  discoveredSensors?: SensorObject[];
  connectedSensors?: SensorObject[];
  assignedSensors?: AssignObject[];
  sessionInfo?: { sessionName: string };
  calibrationInfo?: { state: boolean };
  recordingInfo?: RecordingInfoObject;
  numberOfSensorsAllowed: number;
}

export interface GuiEventObject {
  eventName: string;
  parameters: unknown | null;
}

// Download file interfaces
export interface RecordingCsvFile {
  name: string;
  csv: string;
  data: Uint8Array;
  received: number;
}

export interface ServerToClientEvents {
  stateChange: (changedState: string) => void;
  pageData: (pageData: PageDataObject) => void;
  guiEvent: (eventName: string, parameters: unknown | null) => void;
  fileList: (csvFiles: string[]) => void;
  downloadStart: (fileName: string, byteLength: number) => void;
  downloadDataBlock: (block: Buffer) => void;
  downloadReady: () => void;
}

export interface ClientToServerEvents {
  stateChange: (state: { state: string }) => void;
  guiEvent: (eventName: string, parameters: unknown | null) => void;
  setTime: (date: Date) => void;
  pageDataRequest: () => void;
  getFileList: () => void;
  deleteFiles: (files: string[]) => void;
  discardLastRecording: (filename: string) => void;
  saveLastRecording: (filename: string) => void;
  downloadFile: (filename: string) => void;
  shutdown: () => void;
  reboot: () => void;
  restart: () => void;
  setAllowedSensors: (allowedSensorData: string) => void; // newAttributeData = json string array of mac addresses.
  setSensorAssignments: (sensorAssignments: any[]) => void; // sensorAssignments = object containing the mac address and the segment it is assigned to.
}

// DevPi-4b-01: 10.170.0.179:8080
// DevPi-4b-02: 10.170.0.234:8080
// const socketURLs: string[] = ['10.170.0.234:8080'];

export type GatewaySocket = Socket<ServerToClientEvents, ClientToServerEvents>;

export type GatewayInfo = {
  gatewaySocket: GatewaySocket;
  gatewayInfo: ORYXKSType;
  gatewayUUID: string;
};

export type Gateways = GatewayInfo[];

export const SOCKET_OPTIONS = {
  autoConnect: false,
  reconnectionAttempts: 1,
  timeout: 4000,
  transports: ['websocket', 'polling'],
};

export const SocketContext = createContext<Socket<ServerToClientEvents, ClientToServerEvents> | undefined>(undefined);
