/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * ORYX Test page.
 */

import {
  IonPage,
  IonContent,
  IonGrid,
  IonTitle,
  IonCol,
  IonRow,
  IonSpinner,
  IonIcon,
  IonText,
  IonLoading,
} from '@ionic/react';
import { ORYXHeader } from '../../../components/menuItems/Header';
import { getCache, CACHE_KEYS } from '../../../utilities';
import { ORYXKSCollection, ORYXKSType } from '../../../model';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { GatewayInfo, GatewaySocket, SOCKET_OPTIONS } from './Context/socket';
import { io } from 'socket.io-client';
import { GatewayCard } from './Components/GatewayCard';
import { ORYXBreadCrumps } from '../../../components/BreadCrumps';

interface ORYXTestParams {
  hardwareId: string;
}

export const KneeStabilityTestPage = () => {
  const backTarget = history.state.state?.from;
  const params = useParams<ORYXTestParams>();
  const hardwareId = params.hardwareId;
  const [loading, setLoading] = useState(true);
  const [availableGateways, setAvailableGateways] = useState<GatewayInfo[] | undefined>(undefined);
  const [connectedGateway, setConnectedGateway] = useState('');
  const [retrievedCache, setRetrievedCache] = useState<ORYXKSType | null>(null);

  const fetchCacheAndCreateSocket = async () => {
    const cache = await getCache<ORYXKSType>(hardwareId);
    if (cache !== null) {
      const newSocket: GatewaySocket = io(`http://${cache.gateway.hostName}.local:8080`, SOCKET_OPTIONS);

      setAvailableGateways([
        {
          gatewaySocket: newSocket,
          gatewayInfo: cache,
          gatewayUUID: hardwareId,
        },
      ]);
    }
  };

  useEffect(() => {
    fetchCacheAndCreateSocket();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [hardwareId]);

  if (loading) {
    return <IonLoading cssClass='my-custom-class' isOpen={loading} message={'Retrieving information...'} />;
  } else {
    return (
      <IonPage id='main'>
        <ORYXHeader backTarget={backTarget} crumb={'Knee Stability Test'} />
        <IonContent className='ion-padding' id='main' color='light'>
          <IonGrid fixed>
            <IonRow>
              <ORYXBreadCrumps crumbs={['Measurements', 'Knee Stability Test']} />
            </IonRow>
            {availableGateways!.length < 1
              ? 'No gateways found.'
              : availableGateways!.map((thisSocket) => {
                  const className = () => {
                    if (connectedGateway !== '') {
                      return connectedGateway === thisSocket.gatewayInfo.gateway.hostName ? '' : 'ion-hide';
                    } else {
                      return '';
                    }
                  };

                  return (
                    <IonRow key={thisSocket.gatewayUUID} className={className()}>
                      <GatewayCard
                        socket={thisSocket.gatewaySocket}
                        gatewayUUID={thisSocket.gatewayUUID}
                        gatewayInfo={thisSocket.gatewayInfo}
                        connectedGateway={setConnectedGateway}
                      />
                    </IonRow>
                  );
                })}
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
};
