import * as React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import './graphCard.css';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { GraphSeriesData, SingleSideVariabilityGraphAnnotations } from '../../../../model/reportV2.model';

type VariabilityOverviewGraphProps = {
  crpData: GraphSeriesData[];
  coordinativeVariabilityData: GraphSeriesData[];
  graphAnnotations: SingleSideVariabilityGraphAnnotations[];
  strokeWidth: number;
};

export const VariabilityOverviewGraph = ({
  crpData,
  coordinativeVariabilityData,
  strokeWidth,
  graphAnnotations,
}: VariabilityOverviewGraphProps) => {
  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = useState('small');
  const fillOpacity = [];
  for (const value of Object.values(crpData)) {
    const Value = value as unknown as any;
    const name = Value.name;
    if (name === 'Calf (s) / Foot (s)' || name === 'Thigh (s) / Calf (s)' || name === 'Pelvis (s) / Thigh (s)') {
      fillOpacity.push(1);
    } else {
      fillOpacity.push(0.5);
    }
  }

  useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setWindowSize('large');
      } else {
        setWindowSize('small');
      }
    }
  }, [width]);

  const options: ApexOptions = {
    chart: {
      type: 'line',
      // background: '#F5D0FE',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    fill: {
      opacity: fillOpacity,
    },
    xaxis: {
      type: 'numeric',
      labels: {
        show: false,
        style: {
          fontSize: '8px',
        },
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: -200,
      max: 200,
      tickAmount: 5,
      labels: {
        formatter: function (value: number) {
          return value.toFixed(0);
        },
        style: {
          fontSize: '8px',
        },
      },
    },

    subtitle: {
      text: 'Continuous Relative Phase',
      align: 'center',
      floating: true,
      offsetY: 5,
      style: {
        fontSize: '8px',
        fontFamily: 'Montserrat',
      },
    },

    stroke: {
      width: strokeWidth,
      dashArray: [0],
    },
    legend: {
      show: false,
      position: 'right',
      horizontalAlign: 'left',
      fontSize: '9px',
      width: 110,
      markers: {
        width: 8,
        height: 8,
      },
      itemMargin: {
        horizontal: -10,
      },
      offsetY: -20,
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    annotations: {
      xaxis: graphAnnotations,
      yaxis: [
        {
          y: 0,

          strokeDashArray: 5,
        },
      ],
    },
    tooltip: {
      enabled: false,
    },
  };

  const optionsVariability: ApexOptions = {
    chart: {
      events: {
        legendClick: function (chartContext, seriesIndex, config) {
          if (import.meta.env.MODE !== 'production') console.log('legendClick', seriesIndex);
        },
      },
      type: 'line',
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },

    xaxis: {
      type: 'numeric',
      labels: {
        show: false,
        style: {
          fontSize: '8px',
        },
      },
      axisTicks: {
        show: false,
      },
    },
    fill: {
      opacity: 1,
    },
    yaxis: {
      min: 0,
      max: 360,
      tickAmount: 5,
      labels: {
        formatter: function (value: number) {
          const percentage = (value / 360) * 100;
          return percentage.toFixed(0) + ' %';
        },
        style: {
          fontSize: '8px',
        },
      },
    },
    subtitle: {
      text: 'Coordinative Variability',
      align: 'center',
      offsetY: 5,
      floating: true,
      style: {
        fontSize: '8px',
        fontFamily: 'Montserrat',
      },
    },

    stroke: {
      width: [1, 1],
      dashArray: [0, 4, 0],
    },
    legend: {
      show: false,
      position: 'right',
      horizontalAlign: 'left',
      fontSize: '9px',
      offsetY: -10,
      width: 110,
      markers: {
        width: 8,
        height: 8,
      },
      itemMargin: {
        horizontal: -10,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    annotations: {
      xaxis: graphAnnotations,
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <>
      <div className='' style={{ marginLeft: 'auto', marginRight: 'auto', padding: 0, margin: 0 }}>
        <Chart options={options} series={crpData} type='line' height={windowSize == 'small' ? 85 : 115} />
      </div>
      <div className='' style={{ marginLeft: 'auto', marginRight: 'auto', padding: 0, margin: 0 }}>
        <Chart
          options={optionsVariability}
          series={coordinativeVariabilityData}
          type='line'
          height={windowSize == 'small' ? 85 : 115}
        />
      </div>
    </>
  );
};
