/** The Run Kinematics report tab */
import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonText, IonCard, IonLoading, IonToggle } from '@ionic/react';
import * as React from 'react';

type VariabilitySettingsPopoverContentProps = {
  setShowAllStrides: (showAllStrides: boolean) => void;
  showAllStrides: boolean;
  setShowSettings: (showSettings: { show: boolean; event: any }) => void;
};

export const VariabilitySettingsPopoverContent = ({
  setShowAllStrides,
  showAllStrides,
  setShowSettings,
}: VariabilitySettingsPopoverContentProps) => {
  const toggleShowAllStrides = () => {
    setShowAllStrides(!showAllStrides);
    localStorage.setItem('showAllStridesVariability', !showAllStrides ? 'true' : 'false');
    setShowSettings({ show: false, event: undefined });
  };

  return (
    <IonGrid fixed className=''>
      <IonRow className='ion-no-padding'>
        <IonCol size='12' className='ion-no-padding'>
          <IonRow className='ion-no-padding ion-justify-content-center'>
            <small>Show all strides</small>
          </IonRow>
          <IonRow className='ion-no-padding ion-justify-content-center'>
            <IonToggle
              mode='ios'
              className='icon-custom'
              color='primary'
              checked={showAllStrides}
              onIonChange={(e) => {
                toggleShowAllStrides();
              }}
            ></IonToggle>
          </IonRow>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
