/** The Run Kinematics report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonCard,
  IonToggle,
  IonCardContent,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import '../components/Graphs/graphCard.css';
import { FunctionalMovementKinematicsGraph } from '../components/Graphs';
import { useMemo, useState } from 'react';
import { ORYXCardHeader, ORYXSmallCardHeader } from '../../../components/componentHeaders';
import { SquatKinematicData } from '../../../model/reportV2.model';

type KinematicsReportProps = RouteComponentProps & {
  tabData: SquatKinematicData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const SquatsKinematicsReportTab = ({ tabData }: KinematicsReportProps) => {
  const [showAllStrides, setShowAllStrides] = useState<boolean>(false);
  const toggleShowAllStrides = () => {
    setShowAllStrides(!showAllStrides);
    localStorage.setItem('showAllStridesKinematics', !showAllStrides ? 'true' : 'false');
  };

  const seriesPelvis = useMemo(() => {
    return showAllStrides ? tabData.pelvisKinematicData.allStrides : tabData.pelvisKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesHip = useMemo(() => {
    return showAllStrides ? tabData.hipKinematicData.allStrides : tabData.hipKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesKnee = useMemo(() => {
    return showAllStrides ? tabData.kneeKinematicData.allStrides : tabData.kneeKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesAnkle = useMemo(() => {
    return showAllStrides ? tabData.ankleKinematicData.allStrides : tabData.ankleKinematicData.mean;
  }, [showAllStrides, tabData]);

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          <IonGrid fixed>
            {/* Pelvis */}
            <IonRow className=''>
              <IonCol size='12' className=''>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXCardHeader title={'Pelvis'} zoom={false} info={true}>
                    <IonRow className='ion-no-padding ion-justify-content-center'>
                      <IonText className='attractorCard_Title'>
                        <small>Show all movements</small>
                      </IonText>
                    </IonRow>
                    <IonRow className='ion-no-padding ion-justify-content-center'>
                      <IonToggle
                        mode='ios'
                        className='icon-custom'
                        color='primary'
                        checked={showAllStrides}
                        onIonChange={(e) => {
                          toggleShowAllStrides();
                        }}
                      ></IonToggle>
                    </IonRow>
                  </ORYXCardHeader>

                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesPelvis.Sagittal.series}
                          title={'Sagittal'}
                          subtitle={'Anterior tilt (-) / Posterior tilt (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesPelvis.Sagittal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesPelvis.Coronal.series}
                          title={'Frontal'}
                          subtitle={'Drop (-) / Hike (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesPelvis.Coronal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesPelvis.Transversal.series}
                          title={'Transversal'}
                          subtitle={'Backward Rotation (-) / Forward Rotation (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesPelvis.Transversal.yAxis}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Hip */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXSmallCardHeader title={'Hip'} />

                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesHip.Sagittal.series}
                          title={'Sagittal'}
                          subtitle={'Extension (-) / Flexion (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesHip.Sagittal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesHip.Coronal.series}
                          title={'Frontal'}
                          subtitle={'Abduction (-) / Adduction (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesHip.Coronal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesHip.Transversal.series}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesHip.Transversal.yAxis}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Knee */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXSmallCardHeader title={'Knee'} />

                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesKnee.Sagittal.series}
                          title={'Sagittal'}
                          subtitle={'Extension (-) / Flexion (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesKnee.Sagittal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesKnee.Coronal.series}
                          title={'Frontal'}
                          subtitle={'Valgus (-) / Varus (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesKnee.Coronal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesKnee.Transversal.series}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesKnee.Transversal.yAxis}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Ankle */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXSmallCardHeader title={'Ankle'} />
                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesAnkle.Sagittal.series}
                          title={'Sagittal'}
                          subtitle={'Plantarflexion (-) / Dorsalflexion (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesAnkle.Sagittal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesAnkle.Coronal.series}
                          title={'Frontal'}
                          subtitle={'Eversion (-) / Inversion (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesAnkle.Coronal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesAnkle.Transversal.series}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesAnkle.Transversal.yAxis}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Tables */}
            <IonRow class='ion-no-padding ion-justify-content-center'>
              <IonCol size='8'>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXSmallCardHeader title={'Range of motion'} />

                  <IonCardContent>
                    <IonRow className='ion-padding-vertical full_height ion-align-items-start'>
                      <IonCol size='12' className='ion-no-padding'>
                        <table className='ORYX_ROMTable_Large'>
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>Left</th>
                              <th>Right</th>
                              <th>Symmetry</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className='ORYX_Table_Head'>
                              <td> Pelvis</td>
                              <td>
                                {tabData.romData[0][0].toFixed(1) + '°'}
                                <br /> ± {tabData.romData[0][1].toFixed(1) + '°'}
                              </td>
                              <td>
                                {tabData.romData[0][2].toFixed(1) + '°'}
                                <br /> ± {tabData.romData[0][3].toFixed(1) + '°'}
                              </td>
                              <td>{tabData.romData[0][4].toFixed(0) + '%'}</td>
                            </tr>
                            <tr className='ORYX_Table_Head'>
                              <td> Hip</td>
                              <td>
                                {tabData.romData[1][0].toFixed(1) + '°'}
                                <br /> ± {tabData.romData[1][1].toFixed(1) + '°'}
                              </td>
                              <td>
                                {tabData.romData[1][2].toFixed(1) + '°'}
                                <br /> ± {tabData.romData[1][3].toFixed(1) + '°'}
                              </td>
                              <td>{tabData.romData[1][4].toFixed(0) + '%'}</td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Large_Indent'>External rotation</td>
                              <td>{tabData.hipLabels.externalRotationLabel_Left}</td>
                              <td>{tabData.hipLabels.externalRotationLabel_Right}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Large_Indent'>Internal rotation</td>
                              <td className='ion-text-center'>{tabData.hipLabels.internalRotationLabel_Left}</td>
                              <td>{tabData.hipLabels.internalRotationLabel_Right}</td>
                              <td></td>
                            </tr>

                            <tr className='ORYX_Table_Head'>
                              <td>Knee</td>
                              <td>
                                {tabData.romData[2][0].toFixed(1) + '°'}
                                <br /> ± {tabData.romData[2][1].toFixed(1) + '°'}
                              </td>
                              <td>
                                {tabData.romData[2][2].toFixed(1) + '°'}
                                <br /> ± {tabData.romData[2][3].toFixed(1) + '°'}
                              </td>
                              <td>{tabData.romData[2][4].toFixed(0) + '%'}</td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Large_Indent No_Border_Bottom'>Varus / Valgus</td>
                              <td className='No_Border_Bottom'>{tabData.varusValgusLabel_Left}</td>
                              <td className='No_Border_Bottom'>{tabData.varusValgusLabel_Right}</td>
                              <td></td>
                            </tr>
                            <tr className='ORYX_Table_Head'>
                              <td>Ankle</td>
                              <td>
                                {tabData.romData[3][0].toFixed(1) + '°'}
                                <br /> ± {tabData.romData[3][1].toFixed(1) + '°'}
                              </td>
                              <td>
                                {tabData.romData[3][2].toFixed(1) + '°'}
                                <br /> ± {tabData.romData[3][3].toFixed(1) + '°'}
                              </td>
                              <td>{tabData.romData[3][4].toFixed(0) + '%'}</td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Large_Indent'>Early heel raise</td>
                              <td>{tabData.ankleLabels.earlyHeelRaiseLabel_Left}</td>
                              <td>{tabData.ankleLabels.earlyHeelRaiseLabel_Right}</td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
};
