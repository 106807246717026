/**
 * Calculates the symmetry percentage between two values.
 *
 * @param {number} Left - The left value.
 * @param {number} Right - The right value.
 * @param {number} accuracy - The number of decimal places to round the result to.
 * @returns {string} The symmetry percentage rounded to the specified accuracy.
 */
export function calculateSymmetry(Left: number, Right: number, accuracy: number): number {
  const result = Math.abs((Left - Right) / (0.5 * Left + Right)) * 100;
  return parseFloat(result.toFixed(accuracy));
}
