import { QScore } from '../../../model/reportV2.model';

/**
 * Calculates the Q Score based on the given number.
 * @param number - The number to calculate the Q Score for.
 * @returns The Q Score as a string: "Excellent", "Good", "Moderate", or "Poor".
 */
export function calculateQScoreSLS(number: number): QScore {
  if (number === 1) {
    return 'Excellent';
  } else if (number === 2) {
    return 'Good';
  } else if (number === 3) {
    return 'Moderate';
  } else {
    return 'Poor';
  }
}
