import { DataPoint } from '../../../model/reportV2.model';

/**
 * Calculates the mean of an array of data points.
 *
 * @param points - An array of data points with x and y coordinates.
 * @returns The mean data point with the average x and y values.
 */
export function calculateMean(points: DataPoint[] = []): DataPoint {
  const sum = points.reduce(
    (acc, point) => {
      return {
        x: acc.x + point.x,
        y: acc.y + point.y,
      };
    },
    { x: 0, y: 0 },
  );

  const mean = {
    x: sum.x / points.length,
    y: sum.y / points.length,
  };

  return mean;
}
