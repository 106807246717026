import {
  IonButton,
  IonButtons,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonRow,
} from '@ionic/react';
import React from 'react';
import IBHipExtensionTerminalSwing from './assets/IBHipExtensionTerminalSwing.png';
import './modalindex.css';

// Slides
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom } from 'swiper';

import '@ionic/react/css/ionic-swiper.css';
import { closeCircleOutline } from 'ionicons/icons';
import { ORYXModalHeader } from '../../../../components/componentHeaders';
interface ModalProps {
  buttons?: boolean;
  setShowModal: any;
}

export const ModalHipExtensionTerminalSwing = ({ buttons, setShowModal }: ModalProps) => {
  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  const cleanUp = () => {
    setShowModal({ show: false });
  };
  return (
    <>
      <ORYXModalHeader title='Hip Extension' subtitle='Terminal swing' setShowInfo={cleanUp} buttons={true} />

      <Swiper
        modules={[Pagination, Mousewheel, Keyboard]}
        pagination={pagination}
        mousewheel={true}
        keyboard={true}
        initialSlide={0}
        speed={400}
        direction='vertical'
        className='mySwiper'
      >
        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='6' size-xl='6' className='ion-no-padding'>
                <div>
                  <IonImg src={IBHipExtensionTerminalSwing} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol size='12' size-lg='6' size-xl='6'>
                <div>
                  After midswing the leg keeps extending by hip flexion and knee extension. At terminal swing the
                  direction of hip movement reverses (from flexion to extension) partly due to the eccentric acting
                  hamstrings.
                  <br></br>
                  <br></br>
                  This increases tension on muscles, preparing the hip and knee for processing the ground reacting
                  forces which occur after initial contact and the accomplishment of a good balance.
                  <br></br>
                  <br></br>
                  When maximum hip flexion is not reached until initial contact, there will be lack of protecting
                  pre-tension. The shock absorbing mechanism will not be able to function optimally, increasing the
                  impact on joints significantly.
                  <br></br>
                  <br></br>
                  The cause of a compromised timed hip flexion can be found in either leg:
                  <ol type='A'>
                    <li>The swing is too slow and or hip ROM to low due to a weak push off</li>
                    <li>
                      Lack of time and being forced to land early due to instability and or control in the opposite leg
                      (being in terminal stance)
                    </li>
                  </ol>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
