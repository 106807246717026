import { getfootplantCoordinates } from './getFootplantCoordinates';
import { DataPoint } from '../../../model/reportV2.model';

/**
 * Retrieves footplant data for both left and right foot based on the provided data and column index.
 * @param dataLeft The data for the left foot.
 * @param dataRight The data for the right foot.
 * @param columnIndex The index of the column to retrieve footplant coordinates.
 * @param name The name to be used in the series for identification.
 * @returns An array containing series data for the left and right foot.
 */
export function getPDFFootplantData(
  dataLeft: any,
  dataRight: any,
  columnIndex: number,
  name: string,
): {
  type: string;
  name: string;
  data: DataPoint[];
}[][] {
  const footplantData_Left = getfootplantCoordinates(dataLeft, columnIndex);

  const footplantData_InitialContact_Right = getfootplantCoordinates(dataRight, columnIndex);

  const Series_Left = [
    {
      type: 'scatter',
      name: `${name} - Left`,
      data: footplantData_Left,
    },
  ];

  const Series_Right = [
    {
      type: 'scatter',
      name: `${name} - Right`,
      data: footplantData_InitialContact_Right,
    },
  ];
  return [Series_Left, Series_Right];
}
