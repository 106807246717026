/** Squat analysis report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonIcon,
  IonButton,
  IonButtons,
  IonCard,
  IonModal,
  IonList,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { SquatReportData } from '../../../model';

import { useState } from 'react';
import { FunctionalMovementVariabilityGraph } from '../components/Graphs';
import { VariabilityGraphLegenda } from '../components/CustomGraphComponents';
import ORYX_MagnifyingGlassIcon from '../../../assets/icons/ORYX_MagnifyingGlassIcon.svg';
import { VariabilityModalContent } from '../components/Modals';
import { SingleLegSquatVariabilityData } from '../../../model/reportV2.model';

type SquatsVariabilityReportProps = RouteComponentProps & {
  tabData: SingleLegSquatVariabilityData;
};

/** The Walk analysis report tab
 * @param report - The full walk report info and data
 */
export const SingleLegSquatsVariabilityReportTab = ({ tabData }: SquatsVariabilityReportProps) => {
  const [showMoreInfoModal, setShowMoreInfoModal] = useState({
    show: false,
    joint: '',
  });

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <>
        <IonModal
          isOpen={showMoreInfoModal.show}
          onDidDismiss={() => setShowMoreInfoModal({ show: false, joint: '' })}
          css-class='ORYX_Modal_Large'
        >
          {showMoreInfoModal.joint === 'Hip' && (
            <VariabilityModalContent
              crp_Left={tabData.crpSeriesPelvisThigh_Left}
              crp_Right={tabData.crpSeriesPelvisThigh_Right}
              coordinativeVariabilityData_Left={tabData.coordinativeVariabilitySeriesHip_Left}
              coordinativeVariabilityData_Right={tabData.coordinativeVariabilitySeriesHip_Right}
              setShowMoreInfoModal={setShowMoreInfoModal}
            />
          )}
          {showMoreInfoModal.joint === 'Knee' && (
            <VariabilityModalContent
              crp_Left={tabData.crpSeriesThighCalf_Left}
              crp_Right={tabData.crpSeriesThighCalf_Right}
              coordinativeVariabilityData_Left={tabData.coordinativeVariabilitySeriesKnee_Left}
              coordinativeVariabilityData_Right={tabData.coordinativeVariabilitySeriesKnee_Right}
              setShowMoreInfoModal={setShowMoreInfoModal}
            />
          )}
          {showMoreInfoModal.joint === 'Ankle' && (
            <VariabilityModalContent
              crp_Left={tabData.crpSeriesCalfFoot_Left}
              crp_Right={tabData.crpSeriesCalfFoot_Right}
              coordinativeVariabilityData_Left={tabData.coordinativeVariabilitySeriesAnkle_Left}
              coordinativeVariabilityData_Right={tabData.coordinativeVariabilitySeriesAnkle_Right}
              setShowMoreInfoModal={setShowMoreInfoModal}
            />
          )}
        </IonModal>

        <IonPage>
          <IonContent className='ion-padding' color='light'>
            <IonGrid fixed>
              <IonRow className='ion-align-items-center ion-justify-content-center third_height'>
                <IonCol size='12' className='ion-align-self-stretch'>
                  <IonCard className='ORYX_GraphCard'>
                    <IonRow className='ion-no-padding ion-align-items-center ion-justify-content-evenly fullHeight'>
                      <IonCol
                        size='2'
                        className='ion-align-self-stretch'
                        onClick={() => setShowMoreInfoModal({ show: true, joint: 'Hip' })}
                      >
                        <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                          <IonCol size='9' className='ion-no-padding'>
                            <p className='ORYX_GraphTitle'>Hip</p>
                          </IonCol>
                          <IonCol size='3' className='ion-no-padding'>
                            <IonButtons className='ion-justify-content-center Vertical'>
                              <IonButton
                                slot='icon-only'
                                fill='clear'
                                className='variabilityCard_Header_Buttons'
                                onClick={() => setShowMoreInfoModal({ show: true, joint: 'Hip' })}
                              >
                                <IonIcon icon={ORYX_MagnifyingGlassIcon} />
                              </IonButton>
                            </IonButtons>
                          </IonCol>
                        </IonRow>
                        <IonRow className='ion-align-items-center ion-justify-content-center'>
                          <IonList>
                            <VariabilityGraphLegenda legend={tabData.pelvisLegend} />
                          </IonList>
                        </IonRow>
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding ion-align-items-center ion-align-self-stretch'>
                        <FunctionalMovementVariabilityGraph
                          crpData={tabData.crpSeriesPelvisThigh_Left}
                          coordinativeVariabilityData={tabData.coordinativeVariabilityOverviewHip_Left}
                        />
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding ion-align-self-stretch'>
                        <FunctionalMovementVariabilityGraph
                          crpData={tabData.crpSeriesPelvisThigh_Right}
                          coordinativeVariabilityData={tabData.coordinativeVariabilityOverviewHip_Right}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow className='ion-align-items-center ion-justify-content-center third_height'>
                <IonCol size='12' className='ion-align-self-stretch'>
                  <IonCard className='ORYX_GraphCard'>
                    <IonRow className='ion-no-padding ion-align-items-center ion-justify-content-evenly fullHeight'>
                      <IonCol
                        size='2'
                        className='ion-align-self-stretch'
                        onClick={() => setShowMoreInfoModal({ show: true, joint: 'Knee' })}
                      >
                        <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                          <IonCol size='9' className='ion-no-padding'>
                            <p className='ORYX_GraphTitle'>Knee</p>
                          </IonCol>
                          <IonCol size='3' className='ion-no-padding'>
                            <IonButtons className='ion-justify-content-center Vertical'>
                              <IonButton
                                slot='icon-only'
                                fill='clear'
                                className='variabilityCard_Header_Buttons'
                                onClick={() => setShowMoreInfoModal({ show: true, joint: 'Knee' })}
                              >
                                <IonIcon icon={ORYX_MagnifyingGlassIcon} />
                              </IonButton>
                            </IonButtons>
                          </IonCol>
                        </IonRow>
                        <IonRow className='ion-align-items-center ion-justify-content-center'>
                          <IonList>
                            <VariabilityGraphLegenda legend={tabData.kneeLegend} />
                          </IonList>
                        </IonRow>
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding'>
                        <FunctionalMovementVariabilityGraph
                          crpData={tabData.crpSeriesThighCalf_Left}
                          coordinativeVariabilityData={tabData.coordinativeVariabilityOverviewKnee_Left}
                        />
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding'>
                        <FunctionalMovementVariabilityGraph
                          crpData={tabData.crpSeriesThighCalf_Right}
                          coordinativeVariabilityData={tabData.coordinativeVariabilityOverviewKnee_Right}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow className='ion-align-items-center ion-justify-content-center third_height'>
                <IonCol size='12' className='ion-align-self-stretch'>
                  <IonCard className='ORYX_GraphCard'>
                    <IonRow className='ion-no-padding ion-align-items-center ion-justify-content-evenly fullHeight'>
                      <IonCol
                        size='2'
                        className='ion-align-self-stretch ion-align-items-stretch'
                        onClick={() => setShowMoreInfoModal({ show: true, joint: 'Ankle' })}
                      >
                        <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                          <IonCol size='9' className='ion-no-padding'>
                            <p className='ORYX_GraphTitle'>Ankle</p>
                          </IonCol>
                          <IonCol size='3' className='ion-no-padding'>
                            <IonButtons className='ion-justify-content-center Vertical'>
                              <IonButton
                                slot='icon-only'
                                fill='clear'
                                className='variabilityCard_Header_Buttons'
                                onClick={() => setShowMoreInfoModal({ show: true, joint: 'Ankle' })}
                              >
                                <IonIcon icon={ORYX_MagnifyingGlassIcon} />
                              </IonButton>
                            </IonButtons>
                          </IonCol>
                        </IonRow>
                        <IonRow className='ion-align-items-center ion-justify-content-center'>
                          <IonList>
                            <VariabilityGraphLegenda legend={tabData.ankleLegend} />
                          </IonList>
                        </IonRow>
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding'>
                        <FunctionalMovementVariabilityGraph
                          crpData={tabData.crpSeriesCalfFoot_Left}
                          coordinativeVariabilityData={tabData.coordinativeVariabilityOverviewAnkle_Left}
                        />
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding'>
                        <FunctionalMovementVariabilityGraph
                          crpData={tabData.crpSeriesCalfFoot_Right}
                          coordinativeVariabilityData={tabData.coordinativeVariabilityOverviewAnkle_Right}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonPage>
      </>
    );
  }
};
