/** Report detail page, holding tabs for analysis, kinematics, and overview */
import { IonContent, IonLoading, IonPage } from '@ionic/react';

import * as React from 'react';
import { useParams } from 'react-router';

import { ORYXHeader } from '../../../components/menuItems/Header';
import { useReportContext } from '../../../components/reportContext';
import { ReportInfoId } from '../../../model';

import { useEffect, useState } from 'react';
import { RunTabBar } from '../components/Tabs';
import { RunReportData } from '../../../model/reportV2.model';
import { createRunReportData } from '../../../hooks/useReports';

interface ParamTypes {
  id: ReportInfoId;
}
/** Report detail page
 * Loading the report data for the given userId and reportInfoId in the url.
 * Switch the tabs from Walk to Run depending on reportType in the loaded report data.
 * @param reportInfoId - the reportInfoId from the url
 */
export const RunReportDetailPageV2 = () => {
  const { id } = useParams<ParamTypes>();
  const { getReportData, dataReady, data, setDataReady, resetData } = useReportContext();
  const [loading, setLoading] = useState(true);
  const [reportName, setReportName] = useState('');
  const [runReportData, setRunReportData] = useState<RunReportData | undefined>();
  // Fetch the report data

  useEffect(() => {
    getReportData(id);
    // Cleanup the data when the component unmounts
    return () => {
      setLoading(true);
      setReportName('');
      setRunReportData(undefined);
      setDataReady(false); // Reset dataReady in the context
      resetData();
    };
  }, [id]);

  useEffect(() => {
    if (data) {
      setRunReportData(createRunReportData(data));
      setReportName(data.fileId.reportName);
    } else {
      setRunReportData(undefined);
    }
  }, [dataReady]);

  useEffect(() => {
    if (runReportData !== undefined && data) setLoading(false);
  }, [runReportData]);

  useEffect(() => {
    if (import.meta.env.MODE !== 'production') console.log('reportData', runReportData);
  }, [runReportData]);

  return (
    <IonPage>
      <ORYXHeader backTarget='/reports' loading={loading} crumb={data && !loading ? reportName : ''} />
      <IonContent className='ion-padding' id='main' color='light'>
        {loading ? (
          <IonLoading cssClass='my-custom-class' isOpen={loading} message={'Fetching data please wait...'} />
        ) : (
          <RunTabBar id={id} reportData={runReportData!} />
        )}
      </IonContent>
    </IonPage>
  );
};
