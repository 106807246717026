/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * Login form Component, including the logo.
 */

import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonInputPasswordToggle,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonText,
  IonToast,
  isPlatform,
} from '@ionic/react';
import { lockClosedOutline, mailOutline, eyeOffOutline, eyeOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { analytics } from '../../config/firebaseConfig';

import logo from '../../assets/oryx-logo.png';
import { useAuthContext } from '../authContext';
import './loginForm.css';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

export const LoginForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showLoginError, setShowLoginError] = useState<boolean>(false);
  const history = useHistory();
  const windowDimensions = useWindowDimensions();

  const { signInWithEmailAndPassword, state, clearError } = useAuthContext();

  useEffect(() => {
    if (state.error) {
      if (import.meta.env.MODE !== 'production') console.log(state.error);
      setShowLoginError(true);
    }
  }, [state]);

  function login(e: React.FormEvent) {
    analytics.logEvent('login');
    e.preventDefault();
    setFormSubmitted(true);
    if (!!email && !!password) {
      signInWithEmailAndPassword(email, password);
    }
  }

  const handleEmail = (e: any) => {
    setEmail(e.detail.value);
  };

  const handlePassword = (e: any) => {
    setPassword(e.detail.value);
  };

  const handleFocus = () => {
    const target = document.getElementById('focus-target');
    const hideTarget = document.getElementById('hide-on-focus');
    if (target && hideTarget && windowDimensions!.width! < 1200) {
      setTimeout(() => {
        target.classList.add('keyboard-open');
        hideTarget.classList.add('keyboard-open-hide');
      }, 300);
    }
  };

  const removeFocus = () => {
    const target = document.getElementById('focus-target');
    const hideTarget = document.getElementById('hide-on-focus');
    if (target && hideTarget) {
      setTimeout(() => {
        target.classList.remove('keyboard-open');
        hideTarget.classList.remove('keyboard-open-hide');
      }, 300);
    }
  };

  return (
    <>
      <IonToast
        isOpen={showLoginError}
        message='Incorrect username / password. Please try again.'
        position='bottom'
        color='danger'
        onDidDismiss={() => {
          setShowLoginError(false);
          clearError();
        }}
        duration={2500}
      />
      <form noValidate onSubmit={login}>
        <IonGrid fixed className='login_form' id='login-form'>
          <div id='hide-on-focus'>
            {import.meta.env.MODE !== 'production' && (
              <>
                <IonRow className='warning'>
                  <IonCol>
                    <IonText style={{ color: '#ffffff' }}>{import.meta.env.MODE} environment!</IonText>
                  </IonCol>
                </IonRow>
              </>
            )}

            <IonRow>
              <IonCol size='12' className='ion-padding'>
                <IonImg src={logo} className='login_logo'></IonImg>
              </IonCol>
            </IonRow>
          </div>
          <div id='focus-target' className=''>
            <IonRow>
              <IonCol size='12' className='ion-padding ion-text-center'>
                <IonText>Welcome back! Please sign in to your account.</IonText>
              </IonCol>
            </IonRow>

            <IonRow className=''>
              <IonCol size='12' className='ion-padding'>
                <IonList mode='md'>
                  <IonInput
                    fill='outline'
                    name='email'
                    type='email'
                    label='Email'
                    label-placement='stacked'
                    onIonChange={handleEmail}
                    onIonFocus={() => {
                      handleFocus();
                    }}
                    onIonBlur={removeFocus}
                  >
                    <IonIcon icon={mailOutline} slot='start' aria-hidden='true' />
                  </IonInput>
                  <br />
                  <IonInput
                    name='password'
                    type='password'
                    label='Password'
                    label-placement='stacked'
                    fill='outline'
                    onIonChange={handlePassword}
                    onIonFocus={(event) => {
                      handleFocus();
                    }}
                    onIonBlur={removeFocus}
                  >
                    <IonIcon icon={lockClosedOutline} slot='start' />
                    <IonInputPasswordToggle slot='end' />
                  </IonInput>

                  {formSubmitted && (!email || !password) && (
                    <IonText color='warning'>
                      <p className='ion-padding-start'>Email and password are required.</p>
                    </IonText>
                  )}
                </IonList>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='6' className='ion-padding'>
                <IonButton type='submit' expand='block' className='primary'>
                  Sign in
                </IonButton>
              </IonCol>
            </IonRow>
          </div>
          {/* <IonRow>
            <IonCol size='6' className='ion-padding'></IonCol>
            <IonCol size='6' className='ion-align-self-end'>
              <IonItem lines='none'>
                <IonLabel
                  onClick={() => {
                    history.push(`/resetPassword`);
                  }}>
                  <div className='resetPassword_Button'>Forgot password?</div>
                </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow> */}
        </IonGrid>
      </form>
    </>
  );
};
