/** Login form for sign-in with email & password */
import { IonButton, IonCol, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonList, IonRow, IonText } from '@ionic/react';
import React, { useState } from 'react';
import { useAuthContext } from '../../components/authContext';
import logo from '../../assets/oryx-logo.png';
import './loginForm.css';
import { mailOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';

export const ResetPasswordForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const history = useHistory();
  const [email, setEmail] = useState<string>('');

  const { resetPassword } = useAuthContext();

  async function submitResetPassword(e: React.FormEvent) {
    e.preventDefault();
    setFormSubmitted(true);
    if (email) {
      await resetPassword(email);
      document.getElementById('Linkrequest')!.style.display = 'none';
      document.getElementById('requested')!.classList.remove('ion-hide');
      document.getElementById('email')!.style.visibility = 'hidden';
      document.getElementById('submitButton')!.style.display = 'none';
      document.getElementById('okButton')!.classList.remove('ion-hide');
    }
  }

  const handleEmail = (e: any) => {
    setEmail(e.detail.value);
  };

  return (
    <form noValidate onSubmit={submitResetPassword}>
      <IonGrid fixed className='login_form'>
        <IonRow>
          <IonCol size='12' className='ion-padding'>
            <IonImg src={logo} className='login_logo'></IonImg>
          </IonCol>
        </IonRow>

        <IonRow id='Linkrequest'>
          <IonCol size='12' className='ion-padding'>
            <div>Enter your email address and we will sent you a password reset link.</div>
          </IonCol>
        </IonRow>
        <IonRow id='requested' className='ion-hide'>
          <IonCol size='12' className='ion-padding'>
            <div>
              If this email address was used to create an account, instructions to reset your password will be sent to
              you. Please check your email.
            </div>
          </IonCol>
        </IonRow>

        <IonRow id='email'>
          <IonCol size='12' className='ion-padding'>
            <IonList>
              <IonItem lines='full' className='ion-padding-top'>
                <IonIcon icon={mailOutline} slot='start'></IonIcon>
                <IonInput name='email' type='email' placeholder='Email' onIonChange={handleEmail}></IonInput>
              </IonItem>

              {formSubmitted && !email && (
                <IonText color='danger'>
                  <p className='ion-padding-start'>Email is required.</p>
                </IonText>
              )}
            </IonList>
          </IonCol>
        </IonRow>

        <IonRow id='submitButton'>
          <IonCol size='6' className='ion-padding'>
            <IonButton type='submit' expand='block' className='primary'>
              Request password
            </IonButton>
          </IonCol>
        </IonRow>

        <IonRow id='okButton' className='ion-hide'>
          <IonCol size='6' className='ion-padding'>
            <IonButton
              expand='block'
              className='primary'
              onClick={() => {
                history.push(`/login`);
              }}
            >
              OK
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </form>
  );
};
