/**
 * Transposes the given data to a 2D array where each element represents the value of the corresponding cell in the original data minus 1.
 *
 * @param data - The data to be transposed.
 * @returns A 2D array representing the transposed data.
 */
export function transposeToStatusDot(data: any): number[][] {
  const f = () => {
    return data.col0.map((_value: number, rowIdx: number) => {
      return Object.keys(data)
        .sort()
        .map((colId: string) => {
          return data[colId][rowIdx] - 1;
        });
    });
  };
  return f();
}
